import { CompanyAPI } from '@api';

function compare(elementA, elementB) {
  if (elementA.lido_por_mim === elementB.lido_por_mim) {
    return 0;
  } if (!elementA.lido_por_mim) {
    return -1;
  }
  return 1;
}

const getCompanyNotifications = () => CompanyAPI.getCompanyNotifications()
  .then(({ data }) => data?.sort(compare))
  .catch((error) => Promise.reject(error.response));

export { getCompanyNotifications };
