<template>
  <div class="app-notifier-card">
    <AppNotifierCardDesktop
      v-if="$screen.isSizeUp('mediumDesktop')"
      v-bind="$props"
    />

    <AppNotifierCardMobile
      v-else
      v-bind="$props"
    />
  </div>
</template>

<script setup>
import { useScreen } from '@stores';
import AppNotifierCardDesktop from './app-notifier-card-desktop.vue';
import AppNotifierCardMobile from './app-notifier-card-mobile.vue';

const $screen = useScreen();

const $props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  hasReturnButton: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.app-notifier-card {
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: screen;
  background: linear-gradient(to bottom,
    var(--color-white) 0%,
    var(--color-white) 50%,
    var(--color-pink-scale-500) 50%,
    var(--color-pink-scale-500) 85%,
    var(--color-pink-scale-400) 85%,
    var(--color-pink-scale-400) 91%,
    var(--color-pink-scale-300) 91%,
    var(--color-pink-scale-300) 97%,
    var(--color-pink-scale-200) 97%,
    var(--color-pink-scale-200) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
