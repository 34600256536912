<template>
  <div class="app-notifier-card-desktop">
    <img
      :src="BusinhoSad"
      class="app-notifier-card-desktop__section app-notifier-card-desktop__section--left b-margin-right--extra-large"
    >

    <div
      class="app-notifier-card-desktop__section
        app-notifier-card-desktop__section--right
        b-margin-left--extra-large
        b-layout b-layout--column"
    >
      <b-text
        variant="h1"
        weight="semi-bold"
        class="app-notifier-card-desktop__title b-margin-bottom--medium"
      >
        {{ $props.title }}
      </b-text>

      <b-text
        size="extra-colossal"
        weight="semi-bold"
        color="inverse"
        class="b-margin-bottom--small"
      >
        {{ $props.description }}
      </b-text>

      <b-button
        v-if="$props.hasReturnButton"
        variant="text"
        color="inverse"
        icon-left="arrow_back"
        class="app-notifier-card-desktop__button"
        @click="$router.back"
      >
        Voltar à página anterior
      </b-button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import BusinhoSad from '@assets/images/businho-sad.svg';

const $router = useRouter();

const $props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  hasReturnButton: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss">
.app-notifier-card-desktop {
  position: relative;
  display: flex;
}

.app-notifier-card-desktop__section {
  display: flex;
  &--left {
    transform: translateY(-15%);
    height: 275px;
    width: 276px;
  }
  &--right {
    height: 275px;
    transform: translateY(10%);
  }
}

.app-notifier-card-desktop__title {
  --b-text--color: var(--color-pink-scale-500);
}

.app-notifier-card-desktop__button {
  --mdc-text-button-label-text-weight: 600;
}
</style>
