/* eslint-disable no-param-reassign, camelcase */
import { getDateIsoFormat } from '@utils/date/get-date-iso-format';
import { getUniformity } from '@utils/dictionary/bus-uniformity';
import { busLicenses } from '@utils/dictionary/bus-licenses';

export function busParsePayload(requestBus) {
  const {
    id,
    infos,
    jsondata,
    status,
    name,
    placa,
    uf,
    tipo,
    modelo_venda,
    classes,
    foto_externa,
    numero_eixos,
    tem_banheiro,
    available,
    motor,
    marca,
    modelo,
    ano_modelo,
    ano_fabricacao,
  } = requestBus;

  this.bus.id = id;
  this.bus.status = status;
  this.bus.name = name;
  this.bus.licensePlate = placa;
  this.bus.renavam = infos?.dut?.numeracao;
  this.bus.state = uf;
  this.bus.chassi = marca ? { value: marca, text: marca } : undefined;
  this.bus.type = tipo ? { value: tipo, text: tipo } : undefined;
  this.bus.businessType = modelo_venda ? { value: modelo_venda, text: modelo_venda } : undefined;
  this.bus.hasBathRoom = tem_banheiro;
  this.bus.engine = motor;
  this.bus.modelYear = ano_modelo;
  this.bus.yearOfManufacture = ano_fabricacao;
  this.bus.bodyWork = modelo ? { value: modelo, text: modelo } : undefined;
  this.bus.newImage = foto_externa;
  this.bus.originalImage = foto_externa;
  this.bus.pivotNumber = numero_eixos;
  this.bus.available = available;
  this.bus.features = jsondata?.features?.length >= 1
    ? getUniformity().filter((u) => jsondata.features.includes(u.value)) : undefined;

  this.bus.licenses = jsondata?.licences?.length >= 1
    ? busLicenses.filter((l) => jsondata?.licences?.includes(l.value)) : undefined;

  this.bus.documents.crlv = {
    image: infos?.dut?.foto_url,
    expirationDate: getDateIsoFormat(infos?.dut?.data_vencimento),
  };

  this.bus.documents.insurance = {
    image: infos?.seguro?.foto_url,
    expirationDate: getDateIsoFormat(infos?.seguro?.data_vencimento),
  };

  this.bus.documents.cvs = {
    image: infos?.cvs.foto_url,
    expirationDate: getDateIsoFormat(infos?.cvs?.data_vencimento),
  };

  this.bus.isBusProfileFormInvalid = true;

  classes.forEach((classe) => {
    const keyToUse = classe.tipo.includes('individual') ? 'seatTypesIndividual' : 'seatTypesCommon';

    this.bus[keyToUse][classe.tipo].capacidade = classe.capacidade;
    this.bus[keyToUse][classe.tipo].selected = true;
  });
}
