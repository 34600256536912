import { AuthAPI } from '@api';

const findUserByEmailOrPhone = async (emailOrPhone, recaptchaToken = null) => {
  const params = { username: emailOrPhone, grtoken: recaptchaToken };

  return AuthAPI.findUserByEmailOrPhone(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { findUserByEmailOrPhone };
