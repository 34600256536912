import { phoneNumberMask } from './phone-number';
import { cnpjMask } from './cnpj';
import { cepMask } from './cep';
import { cpfMask } from './cpf';
import { moneyFormatConfig } from './money-format-config';
import { percentFormatConfig } from './percent-format-config';

const maskDictionary = {
  phone: {
    mask: phoneNumberMask,
    maskLength: 16,
  },
  cnpj: {
    mask: cnpjMask,
    maskLength: 18,
  },
  cpf: {
    mask: cpfMask,
    maskLength: 14,
  },
  cep: {
    mask: cepMask,
    length: 9,
  },
  money: {
    name: 'money',
    config: moneyFormatConfig,
  },
  percent: {
    name: 'percent',
    config: percentFormatConfig,
  },
};

export { maskDictionary };
