import {
  AppUserContact,
  AppPageSection,
  AppPageBody,
  AppToast,
  AppSimpleCard,
  AppEditCard,
  AppEditCardLine,
  AppFeedbackBar,
  AppDocuments,
  AppTeleport,
  AppNotifierCard,
} from '@components/app';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { clickOutsideDirective } from './directives/click-outside-directive';

const AppPlugin = {
  install(Vue) {
    Vue.component('AppUserContact', AppUserContact);
    Vue.component('AppPageBody', AppPageBody);
    Vue.component('AppPageSection', AppPageSection);
    Vue.component('AppToast', AppToast);
    Vue.component('AppSimpleCard', AppSimpleCard);
    Vue.component('AppEditCard', AppEditCard);
    Vue.component('AppEditCardLine', AppEditCardLine);
    Vue.component('AppFeedbackBar', AppFeedbackBar);
    Vue.component('AppDocuments', AppDocuments);
    Vue.component('AppTeleport', AppTeleport);
    Vue.component('AppNotifierCard', AppNotifierCard);
    Vue.component(VueQrcode.name, VueQrcode);
    Vue.directive('click-outside', clickOutsideDirective);
  },
};

export { AppPlugin };
