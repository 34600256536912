import { defineStore } from 'pinia';
import { createCopy } from '@utils/object/create-copy';

export const useTravelResumeModal = defineStore('travelResumeModal', {
  state: () => ({
    group: null,
    isModalOpen: false,
  }),
  actions: {
    open(group) {
      this.group = createCopy(group);
      this.isModalOpen = true;
    },
    close() {
      this.group = null;
      this.isModalOpen = false;
    },
  },
});
