import { createApp } from 'vue';
import { router } from '@router';
import {
  BuserPartnersUIPlugin,
  LayoutPlugin,
  SentryPlugin,
  AppPlugin,
} from '@plugins';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { Settings } from '@settings';
import { MaskPlugin } from '@plugins/mask-plugin';
import VWave from 'v-wave';
import './dayjs-config';
import money from 'v-money3';
import VueHotjar from 'vue-hotjar-next';
import App from './app.vue';
import { piniaConfig } from './pinia-config';
import { vuetifyConfig } from './vuetify-config';

const app = createApp(App);

app.use(piniaConfig);
app.use(vuetifyConfig);
app.use(SentryPlugin, { router });
app.use(router);
app.use(BuserPartnersUIPlugin);
app.use(LayoutPlugin);
app.use(MaskPlugin);
app.use(VWave);
app.use(VueReCaptcha, Settings.getRecaptchaConfig());
app.use(AppPlugin);
app.use(money);
app.use(VueHotjar, {
  id: 3071542,
  isProduction: Settings.isProduction,
  snippetVersion: 6,
});
app.mount('#root');

export { app };
