import dayjs from 'dayjs';

/* eslint-disable no-param-reassign */
const isDataRepasse = (someDate) => {
  if (!someDate) {
    // Se data estiver vazia, ignora validação
    return true
  }
  if (!(someDate instanceof Date)) {
    someDate = dayjs(someDate);
  }
  return someDate.format('d') === '4';
};

export { isDataRepasse };
