const screenSizes = {
  mobile: 570,
  tablet: 700,
  largeTablet: 800,
  desktop: 992,
  mediumDesktop: 1240,
  largeDesktop: 1440,
  bigDesktop: 1800,
};

export { screenSizes };
