import { CompanyAPI } from '@api/buser-http-client';

const uploadInvoice = (groupId, invoiceFile, invoice) => {
  const formData = new FormData();

  formData.append('invoiceFile', invoiceFile, invoiceFile.name);
  formData.append('groupId', groupId);
  formData.append('invoice', JSON.stringify(invoice));

  return CompanyAPI.uploadInvoice(formData)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { uploadInvoice };
