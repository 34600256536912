<template>
  <div
    v-if="!TravelAnttModal.error"
    class="antt-preview-step__body b-margin-top--medium"
  >
    <div
      id="antt-preview-step__element"
      class="antt-preview__html"
      v-html="TravelAnttModal.anttPreview"
    />
  </div>

  <div class="antt-preview-step__footer b-margin-top--medium">
    <b-button
      variant="outlined"
      @click="TravelAnttModal.close"
    >
      Fechar
    </b-button>

    <b-button
      variant="outlined"
      class="b-margin-left--small"
      @click="TravelAnttModal.backToBeginning"
    >
      Gerar Novamente
    </b-button>

    <b-button
      v-if="!TravelAnttModal.group.autorizacao_url"
      variant="outlined"
      class="b-margin-left--small"
      @click="TravelAnttModal.editOnANTT"
    >
      Editar na ANTT
    </b-button>

    <b-button
      :is-loading="TravelAnttModal.isPrintAnttLoading"
      class="b-margin-left--small"
      @click="TravelAnttModal.printAntt"
    >
      Imprimir definitiva
    </b-button>
  </div>
</template>

<script setup>
import { useTravelAnttModal } from '@stores';

const TravelAnttModal = useTravelAnttModal();
</script>

<style lang="scss">
.antt-preview-step__footer {
  display: flex;
  justify-content: flex-end;
}

.antt-preview__html {
  max-height: 600px;
  overflow-y: auto;
}
</style>
