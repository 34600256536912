<template>
  <main class="white-empty-layout">
    <slot />
  </main>
</template>

<style>
.white-empty-layout {
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
