/* eslint-disable prefer-destructuring */
import { defineStore } from 'pinia';
import { CompanyServices } from '@services/company-services';
import { useInterface, useTravelsPage, useSession } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { EventLogger } from '@utils/event-metrics';
import { TravelsPageName } from '@assets/constants/route-names';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelDermgModal = defineStore('TravelDermgModal', {
  state: () => ({
    group: null,
    groupsTravelBack: null,
    selectedGroupTravelBack: null,
    isModalOpen: false,
    currentStep: null,
    dermgUsername: null,
    dermgPassword: null,
    isInvalidForm: false,
    isLoading: false,
    dermgInvoiceNumber: null,
    dermgInvoiceSeries: null,
    company: null,
  }),
  actions: {
    open(group) {
      const $session = useSession();
      this.company = $session.getUserCompany();

      this.group = group;
      this.currentStep = 'InsertCredentialsStep';
      this.isModalOpen = true;

      this.dermgInvoiceNumber = this.group?.notafiscal?.numero;
      this.dermgInvoiceSeries = this.group?.notafiscal?.serie;

      EventLogger.logEvent(events.openDerMgModal, {
        groupId: this.group?.id,
        companyId: this.company.id,
      });
    },
    close() {
      this.$reset();
    },
    printDermg() {
      window.open(this.authorizationUrl, '_blank');
    },
    generateDermg() {
      this.isLoading = true;
      const $interfaceState = useInterface();

      EventLogger.logEvent(events.requestGenerateDerMg, {
        groupId: this.group?.id,
        companyId: this.company.id,
      });

      CompanyServices.dermgGenerate({
        username: this.dermgUsername,
        password: this.dermgPassword,
        groupGoingId: this.group?.id,
        groupBackId: this.selectedGroupTravelBack?.id,
        invoiceNumber: this.dermgInvoiceNumber,
        invoiceSerie: this.dermgInvoiceSeries,
      })
        .then((result) => {
          this.authorizationNumber = result.authorization_number;
          this.authorizationUrl = result.authorization_url;
          this.currentStep = 'FeedbackStep';

          EventLogger.logEvent(events.derMgGeneratedSuccessfully, {
            groupId: this.group?.id,
            companyId: this.company.id,
          });

          const TravelsPageStore = useTravelsPage();
          TravelsPageStore.requestGroups();
        })
        .catch(({ error }) => {
          $interfaceState.toast.add({ label: error, color: 'danger' });

          EventLogger.logEvent(events.errorGenerateDerMg, {
            groupId: this.group?.id,
            companyId: this.company.id,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    requestLoginDermg() {
      this.isLoading = true;
      const $interfaceState = useInterface();

      CompanyServices.dermgLogin(this.dermgUsername, this.dermgPassword)
        .then(() => {
          this.currentStep = 'InsertTravelDetailsStep';

          EventLogger.logEvent(events.derMgLoginSuccessfully, {
            groupId: this.group?.id,
            companyId: this.company.id,
          });
        })
        .catch(({ error }) => {
          $interfaceState.toast.add({ label: error, color: 'danger' });

          EventLogger.logEvent(events.errorLoginDerMg, {
            groupId: this.group?.id,
            companyId: this.company.id,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    requestGetGroupsInverseRoute() {
      this.isLoading = true;

      CompanyServices.getGroupsInverseRoute(this.group.id)
        .then((groups) => {
          this.groupsTravelBack = groups;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
