import { isUndefined } from '@utils/validations/is-undefined';

function evaluateGuards(guards, to, from, next) {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const currentGuard = guardsLeft.shift();

  if (isUndefined(currentGuard)) {
    next();
    return;
  }

  const nextToPass = (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  };

  currentGuard(to, from, nextToPass);
}

export const MultiGuards = (guards) => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards');
  }

  return (to, from, next) => evaluateGuards(guards, to, from, next);
};
