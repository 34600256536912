const filterOnlyVisible = (element) => {
  if (Object.prototype.hasOwnProperty.call(element, 'items')) {
    // eslint-disable-next-line no-param-reassign
    element.items = element.items.filter(filterOnlyVisible);
  }
  if (Object.prototype.hasOwnProperty.call(element, 'visible')) {
    return element.visible;
  }
  return true;
};

export { filterOnlyVisible };
