import { CompanyAPI } from '@api';

const updateCompanyInfo = (companyInfoDict) => {
  const params = {};

  params.company = companyInfoDict;

  return CompanyAPI.updateCompanyInfo(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { updateCompanyInfo };
