<template>
  <UserCpfModal
    v-model="$interfaceState.$modal.userCpfUpdate.isOpen"
  />
</template>

<script setup>
import { useInterface } from '@stores';
import { UserCpfModal } from '@modules/user';

const $interfaceState = useInterface();
</script>
