<template>
  <hr
    class="b-divider"
  >
</template>

<script>
export default {
  name: 'BDivider',
};
</script>

<style lang="scss">
.b-divider {
  align-self: stretch;
  height: auto;
  margin: 0;
  width: auto;
  border-width: var(--size-scalable-nano);
  border-style: solid;
  border-color: var(--color-blue-grey-scale-100);
}
</style>
