import { CompanyAPI } from '@api';

const getCompanyInvoicesDownloadLinkByMonth = async (date) => {
  const params = { data: date };
  return CompanyAPI.getCompanyInvoicesDownloadLinkByMonth(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getCompanyInvoicesDownloadLinkByMonth };
