import { CompanyAPI } from '@api';

const updateGroupClasses = (groupId, fields) => {
  const params = {
    groupId,
    fields,
  };

  return CompanyAPI.updateGroupClasses(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { updateGroupClasses };
