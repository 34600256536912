<script>
/* eslint-disable import/first, no-param-reassign */
export default {
  name: 'BStep',
};
</script>

<template>
  <li
    class="b-step"
    :class="{
      [`b-step--behavior-complete`]: isComplete && !isSelected,
      [`b-step--behavior-selected`]: isSelected,
      [`b-step--behavior-clickable`]: $config.clickable || $props.clickable,
      [`b-step--direction-${$config.direction}`]: $config.direction,
      [`b-step--text-direction-${$config.textDirection}`]: $config.textDirection,
    }"
  >
    <div
      class="b-step__content"
    >
      <div
        class="b-step__badge"
        @click="click"
      >
        <b-icon
          v-if="$props.icon"
          :icon="$props.icon"
          :color="$props.iconColor"
          :size="$props.iconSize"
        />

        <template v-else>
          {{ $props.value }}
        </template>
      </div>

      <div class="b-step__text">
        <b-text
          class="b-step__text-title"
          @click="click"
        >
          <slot />{{ $props.title }}<slot name="title" />
        </b-text>

        <transition
          name="b-step__transition"
        >
          <b-text
            v-if="showDescription"
            class="b-step__text-description"
            dynamic
          >
            <slot
              name="description"
              :on="{click}"
            />{{ $props.description }}
          </b-text>
        </transition>
      </div>
    </div>

    <div class="b-step__line" />
  </li>
</template>

<script setup>
import {
  inject,
  computed,
  useSlots,
} from 'vue';
import { BStepKey } from './b-step-key';

const $props = defineProps({
  clickable: {
    type: Boolean,
    default: undefined,
  },
  value: {
    type: [Number, String],
    required: true,
  },
  title: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  iconColor: {
    type: String,
    default: 'inverse',
  },
  iconSize: {
    type: String,
    default: 'inherit',
  },
  isSelected: {
    type: Boolean,
    default: undefined,
  },
  isComplete: {
    type: Boolean,
    default: undefined,
  },
  swipeActive: {
    type: Boolean,
    default: false,
  },
});

const $slots = useSlots();

const { $config, updatePosition } = inject(BStepKey);

const isSelected = computed(() => {
  if ($config.mode === 'progress') {
    return $config.modelValue === $props.value;
  }
  if ($config.mode === 'custom') {
    return $props.isSelected;
  }
  if ($config.mode === 'complete') {
    return true;
  }
  return false;
});

const isComplete = computed(() => {
  if ($config.mode === 'progress') {
    return $config.modelValue >= $props.value;
  }
  if ($config.mode === 'custom') {
    return $props.isComplete;
  }
  if ($config.mode === 'complete') {
    return true;
  }
  return false;
});

const showDescription = computed(() => {
  if (!$slots?.description) return false;
  return ($config.showDescriptionOnlyIfSelected ? isSelected.value : true);
});

const $emit = defineEmits(['update:modelValue', 'left-active', 'right-active']);

const click = () => {
  if ($config.clickable || $props.clickable) {
    $emit('update:modelValue', $props.value);
    updatePosition($props.value);
  }
};
</script>

<style lang="scss">
:root {
  --b-step--margin: 0;
  --b-step--flex: 1;
  --b-step--flex-direction: column;
  --b-step--align-items: stretch;
  --b-step__content--flex-direction: column;
  --b-step__badge-size: 28px;
  --b-step__line--spacing: 0rem;
  --b-step__line--thickness: 1px;
  --b-step__line: var(--color-blue-grey-scale-300);
  --b-step__line--width: 0;
  --b-step__line--height: 0;
  --b-step__line--space: var(--b-step__badge-size);
  --b-step__line--top: auto;
  --b-step__line--left: auto;
  --b-step__line--position: absolute;
  --b-step__line--flex: 0 1 auto;
  --b-step__line--margin: 0;
  --b-step__line--color: var(--color-grey-scale-500);
  --b-step__line--color--selected: var(--color-grey-scale-500);
  --b-step__line--color--complete: var(--color-grey-scale-500);
  --b-step__badge--background-color: var(--color-grey-scale-500);
  --b-step__badge--background-color--selected: var(--color-secondary);
  --b-step__badge--background-color--complete: var(--color-secondary);
  --b-step__badge-color: var(--color-white);
  --b-step__text--margin: 0;
  --b-step__text--color: var(--color-grey-scale-500);
  --b-step__text--color--complete: inherit;
  --b-step__text--color--selected: inherit;
  --b-step__text-title--font-weight: 500;
  --b-step__content--align-items: stretch;
  --b-step__line--display: block;
}

.b-step {
  position: relative;
  display: flex;
  flex: var(--b-step--flex);
  align-items: var(--b-step--align-items);
  flex-direction: var(--b-step--flex-direction);
  margin: var(--b-step--margin);

  &:last-of-type {
    --b-step--margin: 0 !important;
    --b-step__line--display: none;
  }
}

.b-step__content {
  display: flex;
  position: relative;
  flex-direction: var(--b-step__content--flex-direction);
  align-items: var(--b-step__content--align-items);
}

.b-step--behavior {
  &-selected {
    --b-step__badge--background-color: var(--b-step__badge--background-color--selected);
    --b-step__line--color: var(--b-step__line--color--selected);
    --b-step__text--color: var(--b-step__text--color--selected);
  }
  &-complete {
    --b-step__text--color: var(--b-step__text--color--complete);
    --b-step__line--color: var(--b-step__line--color--complete);
    --b-step__badge--background-color: var(--b-step__badge--background-color--complete);
  }
  &-clickable {
    & .b-step__badge,
    & .b-step__text-title {
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    }
  }
}

.b-step--animation {
  &-closed,
  &-removing {
    --b-step--margin: 0 !important;
  }
}

.b-step--direction-horizontal.b-step--text-direction-horizontal {
  --b-step--flex-direction: row;
  --b-step__content--flex-direction: row;
  --b-step--flex: 1 1 fit-content;
  --b-step__text--margin: 0 0 0 8px;
  --b-step__line--position: relative;
  --b-step__line--width: 0;
  --b-step__line--flex: 1;
  --b-step__line--margin: 0 8px;
  --b-step__line--height: var(--b-step__line--thickness);
  --b-step--align-items: center;

  &:last-child {
    --b-step--flex: 0 1 auto;
  }
}

.b-step--direction-horizontal.b-step--text-direction-vertical {
  --b-step--flex-direction: column;
  --b-step__content--flex-direction: column;
  --b-step__content--align-items: center;
  --b-step__text--margin: 8px 0 0 0;
  --b-step__line--width: calc(100% - var(--b-step__line--space) * 2);
  --b-step__line--top: calc(var(--b-step__line--space) / 2);
  --b-step__line--left: calc(50% + var(--b-step__line--space));
  --b-step__line--position: absolute;
  --b-step__line--height: var(--b-step__line--thickness);
  --b-step--align-items: center;
}

.b-step--direction-vertical.b-step--text-direction-horizontal {
  --b-step--flex-direction: row;
  --b-step__content--flex-direction: row;
  --b-step__text--margin: 0 0 0 8px;
  --b-step__line--height: calc(100% - (var(--b-step__line--space) / 4) * 2);
  --b-step__line--width: var(--b-step__line--thickness);
  --b-step__line--position: absolute;
  --b-step__line--top: calc(var(--b-step__line--space) + var(--b-step__line--space) / 4);
  --b-step__line--left: calc(var(--b-step__line--space) / 2);
  --b-step--margin: 0 0 var(--b-step__line--space) 0;
}

.b-step--direction-vertical.b-step--text-direction-vertical {
  --b-step__content--flex-direction: column;
  --b-step--align-items: center;
  --b-step__line--position: relative;
  --b-step__content--align-items: center;
  --b-step__line--height:
    calc(var(--b-step__line--space) - (var(--b-step__line--space) / 4) * 2);
  --b-step__line--width: var(--b-step__line--thickness);
  --b-step__line--margin: 8px 0;
  --b-step__text--margin: 8px 0 0 0;
}

.b-step__line {
  position: var(--b-step__line--position);
  border-radius: var(--border-radius-normal);
  width: var(--b-step__line--width);
  top: var(--b-step__line--top);
  left: var(--b-step__line--left);
  flex: var(--b-step__line--flex);
  margin: var(--b-step__line--margin);
  height: var(--b-step__line--height);
  background-color: var(--b-step__line--color);
  z-index: 1000;
  display: var(--b-step__line--display);
}

.b-step__badge {
  min-width: var(--b-step__badge-size);
  min-height: var(--b-step__badge-size);
  width: var(--b-step__badge-size);
  height: var(--b-step__badge-size);
  background-color: var(--b-step__badge--background-color);
  border-radius: var(--border-radius-circle);
  color: var(--b-step__badge-color);
  transition: background-color 0.125s ease-in;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
};

.b-step__text {
  margin: var(--b-step__text--margin);
  color: var(--b-step__text--color);
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.b-step__text-title {
  font-weight: var(--b-step__text-title--font-weight);
}

.b-step__text-description {
  max-height: 500px;
  overflow: visible;
}

.b-step__transition-enter-active,
.b-step__transition-leave-active {
  transition:
    opacity 0.25s ease-in-out,
    max-height 0.25s ease-in-out;
}

.b-step__transition-enter-from,
.b-step__transition-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
