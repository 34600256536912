import { CompanyAPI } from '@api';

const getGroupRouteLegClasses = async (groupsIds) => {
  const params = {
    groupsIds,
  };

  return CompanyAPI.getGroupRouteLegClasses(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getGroupRouteLegClasses };
