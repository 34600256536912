<template>
  <b-card
    v-if="$state.canShowDocuments"
    class="app-documents"
    :class="{
      'app-documents--is-open': $state.isOpen,
    }"
    :tag="$state.isOpen ? 'aside' : 'button'"
    @click="handleOpenPageDoc"
  >
    <div
      v-if="$state.isOpen"
      class="app-documents__body"
    >
      <b-text
        weight="bold"
        size="medium"
        class="b-margin-bottom--small"
      >
        Documentação
      </b-text>

      <b-text
        tag="section"
        v-html="pageDoc"
      />
    </div>

    <div>
      <b-icon
        variant="outlined"
        size="large"
        :icon="$state.isOpen ? 'close' : 'info'"
        :tag="$state.isOpen ? 'button' : 'span'"
        @click="handleClosePageDoc"
      />
    </div>
  </b-card>
</template>

<script setup>
import { Settings } from '@settings';
import { useDocumentationContext } from '@composables/providers';
import { reactive, computed } from 'vue';
import { useSession } from '@stores';

const { pageDoc } = useDocumentationContext();

const $session = useSession();

const $state = reactive({
  isOpen: false,
  canShowDocuments: computed(() => ($session.isUserFromBuser || !Settings.isProduction) && pageDoc),
});

const handleOpenPageDoc = () => {
  if (!$state.isOpen) $state.isOpen = true;
};

const handleClosePageDoc = ($event) => {
  if ($state.isOpen) {
    $event.stopPropagation();
    $state.isOpen = false;
  }
};
</script>

<style lang="scss">
@import '@assets/styles/utils/breakpoints';

.app-documents {
  --b-card--background-color: var(--color-blue-scale-050);
  --b-card--padding: var(--size-scalable-small);

  position: fixed;
  top: 10vh;
  z-index: 10000;
  display: flex;
  transition: left 3s ease;
  right: var(--size-scalable-large);

  @include for-tablet-up {
    left: auto;
  }

  &--is-open {
    left: var(--size-scalable-large);
    --b-card--padding: var(--size-scalable-medium);
    @include for-tablet-up {
      left: auto;
    }
  }
}

.app-documents__body {
  flex: 1;
}
</style>
