import { AuthAPI } from '@api';

const city = { id: null, name: null, uf: null };

const getUserCompany = async () => AuthAPI.currentUser()
  .then(({ data }) => {
    const { company } = data;
    if (!company.cidade) company.cidade = city;
    if (!company.corresp_cidade) company.corresp_cidade = city;
    return company;
  })
  .catch((_error) => null);

export { getUserCompany };
