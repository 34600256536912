import { CompanyAPI } from '@api/buser-http-client';

const askSetGroupBus = (busId, grupoId, reasonPlateExchance, explanationPlateExchance) => {
  const params = {
    bus_id: busId,
    group_id: grupoId,
    explanation_plate_exchance: explanationPlateExchance,
    reason_plate_exchance: reasonPlateExchance,
  };

  return CompanyAPI.askSetGroupBus(params);
};

export { askSetGroupBus };
