<template>
  <div
    ref="tab"
    class="b-tabs"
    :class="{
      [`b-tabs--size-${$props.size}`]: $props.size,
      [`b-tabs--color-${$props.color}`]: $props.color,
    }"
  >
    <slot />

    <div class="b-tabs__line" />
  </div>
</template>

<script setup>
import {
  provide,
  computed,
  reactive,
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineExpose
} from 'vue';
import { BTabsKey } from './b-tabs-key';

const $props = defineProps({
  modelValue: {
    type: [Number, String],
    default: undefined,
  },
  size: {
    type: String,
    default: 'medium',
  },
  color: {
    type: String,
    default: 'primary',
  },
});

const $emits = defineEmits(['update:modelValue']);

const tab = ref();

const $state = reactive({
  lineWidth: undefined,
});

const updatePositionLine = () => {
  const tabActiveRect = tab.value.getElementsByClassName('b-tab--is-active')?.[0]?.getBoundingClientRect();
  const tabsWrapperRect = tab.value?.getBoundingClientRect();
  if (!tabActiveRect || !tabsWrapperRect) return;
  $state.lineWidth = `${tabActiveRect.width}px`;
  $state.lineTranslateX = `translateX(${tabActiveRect.left - tabsWrapperRect.left}px)`;
};

const handleTabsChange = (newValue) => {
  $emits('update:modelValue', newValue);
};

watch(() => $props.modelValue, () => {
  updatePositionLine();
}, { flush: 'post' });

provide(BTabsKey, {
  handleTabsChange,
  currentTab: computed(() => $props.modelValue),
  color: computed(() => $props.color),
});

onMounted(() => {
  updatePositionLine();
  window.addEventListener('resize', updatePositionLine, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener('resize', updatePositionLine);
});

defineExpose({ updatePositionLine});
</script>

<style lang="scss">
:root {
  --b-tabs--min-height: var(--size-scalable-extra-jumbo);
  --b-tabs--box-shadow: var(--shadow-e-002);
  --b-tabs__line--background-color: var(--color-white);
}

.b-tabs {
  box-shadow: var(--b-tabs--box-shadow);
  height: var(--b-tabs--min-height);
  display: flex;
  position: relative;

  &--size {
    &-medium {
      --b-tabs--min-height: var(--size-scalable-extra-jumbo);
    }
    &-small {
      --b-tabs--min-height: var(--size-scalable-jumbo);
    }
  }

  &--color {
    &-primary {
      --b-tabs__line--background-color: var(--color-white);
    }
    &-grey {
      --b-tabs__line--background-color: var(--color-primary);
    }
  }
}

.b-tabs__line {
  position: absolute;
  bottom: 0;
  height: var(--size-scalable-micro);
  background-color: var(--b-tabs__line--background-color);
  width: v-bind('$state.lineWidth');
  transform: v-bind('$state.lineTranslateX');
  transition: transform 0.25s ease-in;
}
</style>
