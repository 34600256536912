<template>
  <component :is="$route?.meta?.layoutName">
    <router-view />
  </component>

  <app-toast />
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { setupAmplitude } from '@utils/event-metrics';
import { useRoute } from 'vue-router';

const $route = useRoute();

onBeforeMount(() => {
  setupAmplitude();
});
</script>

<style lang="scss">
@import '@assets/styles';
</style>
