<template>
  <div class="wallet-sacar-modal__header b-margin-bottom--large">
    <b-text
      size="large"
      weight="medium"
    >
      Sacar
    </b-text>
  </div>

  <b-form
    v-slot="formState"
    name="SacarStepForm"
  >
    <div class="wallet-sacar-modal__body">
      <div class="modal-body__saque_disponivel b-margin-bottom--large">
        <b-text
          weight="semi-bold"
          size="small"
        >
          Saldo disponível para saque
        </b-text>

        <b-text
          weight="semi-bold"
          color="success"
          size="extra-large"
        >
          {{ getNumberInCurrency($WalletPageStore.currentWallet?.saldo) }}
        </b-text>
      </div>

      <div class="modal-body__valor-sacar">
        <b-input
          v-model="$WalletSacarModalStore.saqueData.value"
          label="Valor"
          variant="outlined"
          block
          mask="money"
          :rules="{
            required: true,
            size: {
              min: 10
            }
          }"
        />
      </div>

      <div class="modal-body__account-info">
        <div class="account-info__account-type b-margin-bottom--small">
          <b-text
            weight="semi-bold"
            size="extra-small"
          >
            Conta corrente
          </b-text>

          <b-text
            v-if="$WalletSacarModalStore.transferToAnotherAccount"
            size="extra-small"
            class="b-margin-top--small b-margin-bottom--small"
          >
            Saque permitido apenas para contas com CNPJ da empresa
          </b-text>
        </div>

        <template v-if="!$state.showTransferToAnotherAccountForm">
          <b-text
            size="extra-small"
            class="b-margin-bottom--small"
          >
            {{ $WalletSacarModalStore.saqueData.bankAccount?.bankName }}
          </b-text>

          <div class="account-info__agency-account">
            <div class="account-info__agency">
              <b-text size="extra-small">
                Agência
              </b-text>

              <b-text
                size="extra-small"
                class="b-margin-top--extra-small"
              >
                {{ $WalletSacarModalStore.saqueData.bankAccount?.agencia }}
                -
                {{ $WalletSacarModalStore.saqueData.bankAccount?.agenciaDV }}
              </b-text>
            </div>

            <div class="account-info__account">
              <b-text size="extra-small">
                Conta
              </b-text>

              <b-text
                size="extra-small"
                class="b-margin-top--extra-small"
              >
                {{ $WalletSacarModalStore.saqueData.bankAccount?.conta }}
                -
                {{ $WalletSacarModalStore.saqueData.bankAccount?.contaDV }}
              </b-text>
            </div>
          </div>
        </template>

        <template v-else>
          <WalletBankInfoForm />
        </template>
      </div>

      <b-button
        v-if="!$state.showTransferToAnotherAccountForm"
        variant="outlined"
        class="
            wallet-sacar-modal__to-another-account
            b-margin-top--medium
            b-margin-bottom--medium
          "
        @click="$WalletSacarModalStore.handleTransferToAnotherAccount"
      >
        Transferir para outra conta
      </b-button>

      <b-text
        weight="semi-bold"
        size="micro"
        class="b-margin-bottom--large"
      >
        O prazo para o crédito do saque é de até 2 dias úteis.
      </b-text>
    </div>

    <div class="wallet-sacar-modal__footer b-margin-top--medium">
      <b-button
        variant="text"
        @click="$WalletSacarModalStore.close"
      >
        Cancelar
      </b-button>
      <b-button
        class="b-margin-left--small"
        :is-disabled="formState.isInvalid || $state.isSacarDisabled"
        @click="$WalletSacarModalStore.openSacarConfirmationModal"
      >
        Sacar
      </b-button>
    </div>
  </b-form>
</template>

<script setup>
import { getNumberInCurrency } from '@utils/money/get-number-in-currency';
import { notEmpty } from '@utils/validations/not-empty';
import { reactive, computed } from 'vue';
import { WalletBankInfoForm } from '@modules/wallet';
import { useWalletSacarModalStore, useWalletPageStore } from '@stores/finances';

const $WalletPageStore = useWalletPageStore();
const $WalletSacarModalStore = useWalletSacarModalStore();

const $state = reactive({
  companyHasBankAccountInfo: computed(() => notEmpty($WalletSacarModalStore.saqueData.bankAccount.id)),
  showTransferToAnotherAccountForm: computed(() => Boolean(
    $WalletSacarModalStore.transferToAnotherAccount || !$state.companyHasBankAccountInfo,
  )),
  isSacarDisabled: computed(() => {
    const saque = $WalletSacarModalStore.saqueData.value;
    const saldoDisponivel = notEmpty($WalletPageStore.currentWallet?.saldo)
      ? $WalletPageStore.currentWallet?.saldo
      : 0;

    return saque < 10 || saque > saldoDisponivel;
  }),
});
</script>
