<template>
  <transition
    name="b-toast__transition"
    appear
  >
    <aside
      v-if="isActive && $props.item"
      :key="key"
      class="b-toast mdc-snackbar mdc-snackbar--open"
      :class="{
        'mdc-snackbar--stacked': $props?.item?.stacked,
        [`b-toast--color-${$props?.item?.color}`]: $props?.item?.color,
      }"
    >
      <div
        class="mdc-snackbar__surface"
        role="status"
        aria-relevant="additions"
      >
        <div
          class="mdc-snackbar__label"
          aria-atomic="false"
        >
          {{ $props?.item?.label }}
        </div>

        <div
          v-if="$props?.item?.actionButton || $props.item.isClosable"
          class="mdc-snackbar__actions"
          aria-atomic="true"
        >
          <button
            v-if="$props.item.actionButton"
            type="button"
            class="mdc-button mdc-snackbar__action"
            @click="$emit('click', $props.item.actionButton)"
          >
            <div class="mdc-button__ripple" />

            <span class="mdc-button__label">{{ $props.item.actionButton }}</span>
          </button>

          <button
            v-if="$props.item.isClosable"
            class="mdc-icon-button mdc-snackbar__dismiss material-icons"
            title="Dismiss"
            @click="$emit('close')"
          >
            close
          </button>
        </div>
      </div>
    </aside>
  </transition>
</template>

<script setup>
import { computed } from 'vue';

const $props = defineProps({
  item: {
    type: Object,
    default: null,
  },
  isActive: {
    type: Boolean,
    default: true,
  },
});

const key = computed(() => $props.item?.label && new Date());

defineEmits(['click', 'close']);

</script>

<style lang="scss">
:root {
  --b-toast--background-color: #260505;
  --b-toast--color: var(--color-white);
  --b-toast__action-button--color: #bb86fc;
  --b-toast__close-button--color: var(--color-white);
}

.b-toast {
  --mdc-protected-button-label-text-color: var(--b-toast--color);
  z-index: 1000;
  &--color {
    &-success {
      --b-toast--background-color: var(--color-green-scale-400);
      --b-toast__action-button--color: var(--color-white);
    }
    &-warn {
      --b-toast--background-color: var(--color-amber-scale-400);
      --b-toast__action-button--color: var(--color-blue-grey-scale-900);
      --b-toast--color: var(--color-blue-grey-scale-900);
      --b-toast__close-button--color: var(--color-blue-grey-scale-900);
    }
    &-info {
      --b-toast--background-color: var(--color-blue-scale-400);
      --b-toast__action-button--color: var(--color-white);
    }
    &-danger {
      --b-toast--background-color: var(--color-red-scale-800);
      --b-toast__action-button--color: var(--color-white);
    }
  }
}

.mdc-snackbar{
  z-index: 1000 !important;

  &__label {
    color: var(--b-toast--color) !important;
  }
  &__surface {
    background-color: var(--b-toast--background-color) !important;
  }
  &__action:not(:disabled) {
    color: var(--b-toast__action-button--color) !important;
  }
  &__dismiss {
    color: var(--b-toast__close-button--color) !important;
  }
}

.b-toast__transition-enter-active,
.b-toast__transition-leave-active {
  transition: opacity 0.25s ease, transform 0.25s;
}

.b-toast__transition-enter-from,
.b-toast__transition-leave-to {
  opacity: 0;
  transform: scale(0.7);
}
</style>
