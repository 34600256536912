import { CompanyAPI } from '@api';

const getGroupPricesLogs = async (filters) => {
  const params = {
    filters,
  };

  return CompanyAPI.getGroupPricesLogs(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getGroupPricesLogs };
