<template>
  <div class="application-layout">
    <app-header />

    <app-main :width-full-width="$route?.meta?.layout?.widthFullWidth">
      <slot v-if="$session.isAuthenticated" />
    </app-main>

    <app-sidebar />

    <app-modal />
  </div>
</template>

<script setup>
import {
  AppHeader,
  AppMain,
  AppSidebar,
  AppModal,
} from '@components/app';
import { useSession } from '@stores';
import { useRoute } from 'vue-router';

const $route = useRoute();
const $session = useSession();
</script>

<style lang="scss">
.application-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;

  @media print {
    height: auto;
  }
}
</style>
