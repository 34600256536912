import dayjs from 'dayjs';

const viagensFretamento = {
  busSelected: null,
  driverSelected: null,
  businessTypeSelected: { value: ['buser', 'hibrido'], text: undefined },
  statusSelected: null,
  groupId: null,
  rotinaId: null,
  departureAfter: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
  departureBefore: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  initialized: false,
};

const viagensFretamentoPendencias = {
  busSelected: null,
  driverSelected: null,
  businessTypeSelected: { value: ['buser', 'hibrido'], text: undefined },
  statusSelected: null,
  groupId: null,
  departureAfter: null,
  departureBefore: null,
  initialized: false,
};

const viagensRodoviaria = {
  businessTypeSelected: { value: ['marketplace'], text: undefined },
  statusSelected: null,
  groupId: null,
  departureAfter: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
  departureBefore: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  initialized: false,
  originSelected: null,
  destinySelected: null,
  citiesSelected: null,
};

export const TRAVEL_PAGE_FILTERS = {
  viagensFretamento,
  viagensFretamentoPendencias,
  viagensRodoviaria,
};
