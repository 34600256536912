<template>
  <div
    class="b-checkbox"
    :class="{
      'b-checkbox--behavior-is-disabled': $props.isDisabled,
    }"
  >
    <div
      class="b-checkbox__wrapper"
      @click="handleChange"
    >
      <input
        :id="id"
        class="b-checkbox__input"
        :checked="$internalState.internalChecked"
        :value="$props.value"
        :disabled="$props.isDisabled"
        type="checkbox"
        :name="$props.name"
      >
      <b-icon
        size="medium"
        class="b-checkbox__checkmark"
        icon="check"
      />
    </div>
    <label
      class="b-checkbox__label"
      :for="id"
    >
      {{ $props.label }}<slot />
    </label>
  </div>
</template>
<script setup>
import { computed, reactive } from 'vue';
import { idGenerator } from '@composables/utilities';
import { notEmpty } from '@utils/validations/not-empty';

const $props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  modelValue: {
    type: [String, Number, Boolean],
    default: undefined,
  },
  value: {
    type: [String, Number, Boolean],
    default: undefined,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: undefined,
  },
  indeterminate: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(['update:modelValue', 'change']);

const $internalState = reactive({
  isBooleanVModel: computed(() => (typeof $props.modelValue === 'boolean')),
  isArrayVModel: computed(() => Array.isArray($props.modelValue)),
  internalChecked: computed(() => {
    if ($internalState.isBooleanVModel) {
      return $props.modelValue;
    }

    if (notEmpty($props.value) && notEmpty($props.modelValue)) {
      return $props.modelValue === $props.value;
    }

    return $props.checked;
  }),
});

const id = idGenerator();

const handleChange = () => {
  if ($props.isDisabled) return;
  const checkboxValue = !$internalState.internalChecked;
  $emit('update:modelValue', checkboxValue);
  $emit('change', checkboxValue);
};

</script>
<style lang="scss">
:root {
  --b-checkbox__checkmark--border-color: var(--color-blue-grey-scale-500);
  --b-checkbox__checkmark--border-color--checked: var(--color-primary);
  --b-checkbox__checkmark--background-color: none;
  --b-checkbox__checkmark--background-color--checked: var(--color-primary);
  --b-checkbox__checkmark--border-radius: var(--size-scalable-extra-small);
  --b-checkbox__label--color: var(--color-blue-grey-scale-900);
  --b-checkbox__label--cursor: pointer;
  --b-checkbox__wrapper--cursor: pointer;
  --b-checkbox__wrapper--background-color: none;
  --b-checkbox__wrapper--background-color--hover: var(--color-grey-scale-100);
}

.b-checkbox {
  display: inline-flex;
  align-items: center;

  &--behavior {
    &-is-disabled {
      --b-checkbox__checkmark--border-color: var(--color-grey-scale-400);
      --b-checkbox__checkmark--border-color--checked: var(--color-grey-scale-400);
      --b-checkbox__checkmark--background-color--checked: var(--color-grey-scale-400);
      --b-checkbox__label--color: var(--color-grey-scale-400);
      --b-checkbox__label--cursor: not-allowed;
      --b-checkbox__wrapper--cursor: not-allowed;
      --b-checkbox__wrapper--background-color--hover: none;
    }
  }
}

.b-checkbox__input {
  display: none;
}

.b-checkbox__label {
  color: var(--b-checkbox__label--color);
  cursor: var(--b-checkbox__label--cursor);
}

.b-checkbox__wrapper {
  cursor: var(--b-checkbox__wrapper--cursor);
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--size-progressive-micro);
  border-radius: 50%;
  transition: background-color 0.125s ease-in;
  background-color: var(--b-checkbox__wrapper--background-color);

  &:hover {
    --b-checkbox__wrapper--background-color: var(--b-checkbox__wrapper--background-color--hover);
  }
}

.b-checkbox__checkmark {
  width: var(--size-progressive-medium);
  height: var(--size-progressive-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: var(--size-scalable-micro);
  border-style: solid;
  border-color: var(--b-checkbox__checkmark--border-color);
  border-radius: var(--b-checkbox__checkmark--border-radius);
  background-color: var(--b-checkbox__checkmark--background-color);
  color: var(--color-white);
  font-weight: bold;
  transition: all 0.25s;
  user-select: none;

  & > .material-icons {
    opacity: 0;
  }
}

input:checked.b-checkbox__input ~ .b-checkbox__checkmark {
  --b-checkbox__checkmark--background-color: var(--b-checkbox__checkmark--background-color--checked);
  --b-checkbox__checkmark--border-color: var(--b-checkbox__checkmark--border-color--checked);

  & > .material-icons {
    opacity: 1;
  }
}
</style>
