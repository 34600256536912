import { buserHttpClient } from './buser-http-client';

const currentUser = () => buserHttpClient.get('/v1/auth/current_user');

const findUserByEmailOrPhone = (params) => buserHttpClient.post('/v1/auth/find_user', params);

const forgotPassword = (params) => buserHttpClient.post('/v1/auth/forgot_password', params);

const signIn = (params) => buserHttpClient.post('/v1/auth/sign_in', params);

const signOut = () => buserHttpClient.post('/v1/auth/sign_out');

const signUp = (params) => buserHttpClient.post('/v1/auth/signup', params);

const AuthAPI = {
  currentUser,
  findUserByEmailOrPhone,
  forgotPassword,
  signIn,
  signOut,
  signUp,
};

export { AuthAPI };
