/* eslint-disable no-param-reassign */
const removeAccents = (str) => {
  if (!str) return str;

  const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

  str = str.split('');

  str.forEach((letter, index) => {
    const i = accents.indexOf(letter);
    if (i !== -1) str[index] = accentsOut[i];
  });

  return str.join('');
};

export { removeAccents };
