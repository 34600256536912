import { defineStore } from 'pinia';
import { createCopy } from '@utils/object/create-copy';
import { isEqual } from '@utils/object/is-equal';
import { chechIfLastRouteLegIsAlreadyStarted } from '@utils/groups/chech-if-last-route-leg-is-already-started';
import { CompanyServices } from '@services/company-services';
import { useTravelsPage } from '@stores';
import { formatLogs } from '@modules/travels';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelEditModal = defineStore('travelEditModal', {
  state: () => ({
    isModalOpen: false,
    group: null,
    groupToEdit: null,
    routeLegs: null,
    routeLegsToEdit: null,
    cancelDialogIsOpen: false,
    confirmDialogIsOpen: false,
    changeWithoutSaveDialogIsOpen: false,
    classeStepFormIsInvalid: null,
    routeLegStepFormIsInvalid: null,
    pricesStepFormIsInvalid: null,
    groupPricesLogs: null,
    groupPrices: null,
    groupPricesToEdit: null,
    groupPriceUpdateInBatch: false,
    currentStep: null,
    isLoading: false,
  }),
  getters: {
    groupIsDisabled(state) {
      return state?.group.status in ['canceled', 'done'];
    },
    hasChanged(state) {
      if (state.currentStep === 'classesStep') return !isEqual(state.group, state.groupToEdit);

      if (state.currentStep === 'routeLegStep') return !isEqual(state.routeLegs, state.routeLegsToEdit);

      if (state.currentStep === 'pricesStep') return !isEqual(state.groupPrices, state.groupPricesToEdit);

      return false;
    },
    lastRouteLegIsAlreadyStarted(state) {
      return chechIfLastRouteLegIsAlreadyStarted(state.group);
    },
    groupIsDisabledToEdit() {
      return this.groupIsDisabled || this.lastRouteLegIsAlreadyStarted;
    },
    canSave() {
      return this.hasChanged && !this.groupIsDisabled && !this.lastRouteLegIsAlreadyStarted;
    },
  },
  actions: {
    open(group, currentStep = null) {
      this.currentStep = currentStep;
      this.group = createCopy(group);
      this.groupToEdit = createCopy(group);
      this.cancelDialogIsOpen = false;
      this.isModalOpen = true;
    },
    close() {
      this.isModalOpen = false;
      this.cancelDialogIsOpen = false;
      this.confirmDialogIsOpen = false;
      this.changeWithoutSaveDialogIsOpen = false;
      this.group = null;
      this.groupToEdit = null;
      this.routeLegs = null;
      this.routeLegsToEdit = null;
      this.groupPrices = null;
      this.groupPricesToEdit = null;
      this.groupPricesLogs = null;
      this.currentStep = null;
      this.groupPriceUpdateInBatch = false;
    },
    shouldChangeStep(nextStep) {
      this.nextStep = nextStep;
      if (this.canSave || this.changeWithoutSaveDialogIsOpen) {
        this.changeWithoutSaveDialogIsOpen = true;
      } else {
        this.changeStep();
      }
    },
    changeStep() {
      EventLogger.logEvent(events.changeStepTravelEditModal);
      this.currentStep = this.nextStep;
      this.nextStep = null;
      this.routeLegsToEdit = createCopy(this.routeLegs);
      this.groupToEdit = createCopy(this.group);
      this.groupPricesToEdit = createCopy(this.groupPrices);
    },
    cancelEditOperation() {
      if (this.canSave) {
        this.cancelDialogIsOpen = true;
      } else {
        this.close();
      }
    },
    tryEditOperation() {
      if (this.canSave) {
        this.confirmDialogIsOpen = true;
      }
    },
    updateGroup() {
      if (!this.canSave) return undefined;

      if (this.currentStep === 'classesStep') {
        return this.requestUpdateClasses();
      }

      if (this.currentStep === 'routeLegStep') {
        return this.requestUpdateRouteLegClasses();
      }

      if (this.currentStep === 'pricesStep') {
        return this.requestUpdateGroupPrices();
      }

      return undefined;
    },
    requestUpdateClasses() {
      this.isLoading = true;
      const classes = this.getChangedClasses();
      const TravelsPage = useTravelsPage();

      return CompanyServices.updateGroupClasses(this.group?.id, { classes })
        .then(() => {
          TravelsPage.requestGroups();
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    requestUpdateRouteLegClasses() {
      EventLogger.logEvent(events.requestUpdateGroupRouteLegClasses, { description: 'starting' });
      this.isLoading = true;
      const routeLegClasses = this.getChangedRouteLegs();

      const TravelsPage = useTravelsPage();

      return CompanyServices.updateGroupClasses(this.group?.id, { trechosClasses: routeLegClasses })
        .then(() => {
          EventLogger.logEvent(events.requestUpdateGroupRouteLegClasses, {
            description: 'finishing',
            status: 'successfully',
          });
          TravelsPage.requestGroups();
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    requestUpdateGroupPrices() {
      EventLogger.logEvent(events.requestUpdateGroupPrices, { description: 'starting' });
      this.isLoading = true;

      const payload = this.getChangedPrices();
      const TravelsPage = useTravelsPage();

      return CompanyServices.updateGroupPrices([this.group?.id], payload, this.groupPriceUpdateInBatch)
        .then(() => {
          EventLogger.logEvent(events.requestUpdateGroupPrices, {
            description: 'finishing',
            status: 'successfully',
            updateInBatch: this.groupPriceUpdateInBatch,
          });
          TravelsPage.requestGroups();
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    requestGetGroupPricesLogs() {
      const filter = {
        groupId: this.group.id,
      };

      CompanyServices.getGroupPricesLogs(filter)
        .then((result) => {
          if (result?.length >= 0) {
            const formatedLogs = formatLogs(result)
              ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

            this.groupPricesLogs = formatedLogs;
          } else {
            this.groupPricesLogs = null;
          }
        });
    },
    requestGetGroupPrices() {
      const groupIds = [this.group.id];
      CompanyServices.getGroupPrices(groupIds)
        .then((result) => {
          if (result?.length >= 0) {
            this.groupPrices = createCopy(result[0].trechos_classes);
            this.groupPricesToEdit = createCopy(result[0].trechos_classes);
          } else {
            this.groupPrices = null;
            this.groupPricesToEdit = null;
          }
        });
    },
    requestGetGroupRouteLegClasses() {
      const groupIds = [this.group.id];
      CompanyServices.getGroupRouteLegClasses(groupIds)
        .then((result) => {
          if (result?.length >= 0) {
            this.routeLegs = createCopy(result[0].trechos_classes);
            this.routeLegsToEdit = createCopy(result[0].trechos_classes);
          } else {
            this.routeLegs = null;
            this.routeLegsToEdit = null;
          }
        });
    },
    getChangedClasses() {
      const changedValue = [];

      this.groupToEdit?.classes.forEach((classe, classeIndex) => {
        if (!isEqual(this.group?.classes[classeIndex], classe)) {
          const closedReason = classe.closed ? 'Fechamento pelo parceiro' : classe.closed_reason;
          changedValue.push({ ...classe, closed_reason: closedReason });
        }
      });
      return changedValue;
    },
    getChangedRouteLegs() {
      Object.entries(this.routeLegsToEdit).forEach(([routeLegClassesId, routeLegClasses]) => {
        Object.entries(routeLegClasses).forEach(([classeId, classe]) => {
          if (!isEqual(this.routeLegs[routeLegClassesId][classeId], classe)) {
            this.routeLegsToEdit[routeLegClassesId][classeId].closed_reason = classe.closed
              ? 'Fechamento pelo parceiro'
              : classe.closed_reason;
          }
        });
      });

      return this.routeLegsToEdit;
    },
    getChangedPrices() {
      const changedValue = [];

      Object.entries(this.groupPricesToEdit).forEach(([routeLegClassesId, routeLegClasses]) => {
        Object.entries(routeLegClasses).forEach(([classeId, classe]) => {
          if (!isEqual(this.groupPrices[routeLegClassesId][classeId], classe)) {
            changedValue.push({ trecho_classe_id: classe.id, valor_novo: classe.max_split_value });
          }
        });
      });

      return changedValue;
    },
  },
});
