<template>
  <b-card
    class="b-accordion b-padding--medium"
    :elevation="$props.elevation"
  >
    <div
      class="b-accordion__title"
      @click="handleChange"
    >
      <slot
        name="title"
      >
        <b-text>
          <b
            class="b-margin-right--medium"
          >
            {{ $props.title }}
          </b>

          {{ $props.subTitle }}
        </b-text>

        <BIcon
          :icon="
            isExpanded ? 'expand_less' : 'expand_more'
          "
          class="b-margin-left--medium"
        />
      </slot>
    </div>

    <slot
      name="subtitle"
    />

    <transition
      name="b-accordion__transition"
    >
      <div
        v-show="isExpanded"
        class="b-accordion__content b-margin-top--medium"
      >
        <slot
          name="content"
        />
      </div>
    </transition>
  </b-card>
</template>

<script setup>
import { computed } from 'vue';
import { shouldBeOneOf } from 'vue-prop-validation-helper';

const $props = defineProps({
  accordionHeight: {
    // todo: resolver essa bizarrice horrenda horrorosa
    type: String,
    default: '500px',
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  cantChangeExpanded: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  elevation: {
    type: String,
    default: '002',
    validator: shouldBeOneOf([
      '000',
      '001',
      '002',
      '003',
      '004',
      '005',
      '006',
      '007',
      '008',
      '009',
      '010',
      '011',
      '012',
      '013',
      '014',
      '015',
      '016',
      '017',
      '018',
      '019',
      '020',
      '021',
      '022',
      '023',
      '024',
    ]),
  },
  title: {
    type: String,
    default: undefined,
  },
  subTitle: {
    type: String,
    default: undefined,
  },
});

const $emit = defineEmits(['change', 'update:modelValue']);

const isExpanded = computed(() => $props.expanded || $props.modelValue);

const handleChange = () => {
  if ($props.cantChangeExpanded) {
    return;
  }
  $emit('change', !$props.expanded);
  $emit('update:modelValue', !$props.expanded);
};

</script>

<style lang="scss">
.b-accordion{
  position: relative;
  overflow-x: visible !important;
}

.b-accordion__title{
  display: flex;
  justify-content: space-between;
}

.b-accordion__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  max-height: v-bind('$props.accordionHeight');
}

.b-accordion__transition-enter-active,
.b-accordion__transition-leave-active {
  transition: opacity 250ms ease-in-out, max-height 250ms ease-in-out;
}

.b-accordion__transition-enter-from,
.b-accordion__transition-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
