import { removeAccents } from './remove-accents';

const comparePhrases = (phraseOne, phraseTwo) => {
  if (!phraseOne || !phraseTwo) return 0;

  const phraseOneWords = (removeAccents(phraseOne).toLowerCase().match(/[a-z\s]/g) || []).join('').split(/\s+/);
  const phraseTwoWords = (removeAccents(phraseTwo).toLowerCase().match(/[a-z\s]/g) || []).join('').split(/\s+/);
  const total = phraseOneWords.length > phraseTwoWords.length ? phraseOneWords.length : phraseTwoWords.length;

  if (!total) return 0;

  let countEquals = 0.0;
  phraseOneWords.forEach((wordOne) => {
    phraseTwoWords.forEach((wordTwo) => {
      if (wordOne === wordTwo) countEquals += 1;
    });
  });

  return countEquals / total;
};

export { comparePhrases };
