<template>
  <b-form
    v-if="TravelArtespModal.group"
    v-model="TravelArtespModal.isInvalidForm"
    name="TravelArtespModal"
  >
    <div class="travel-artesp-form__header">
      <b-text weight="semi-bold">
        Dados de acesso na ARTESP
      </b-text>

      <b-text>
        Não se preocupe, não armazenaremos seus dados.
      </b-text>
    </div>

    <div class="travel-artesp-form__body b-margin-top--large">
      <b-input
        v-model="TravelArtespModal.group.onibus.placa"
        class="b-margin-bottom--small"
        label="Placa do veículo"
        block
        :is-disabled="true"
        :rules="{
          required: true,
        }"
      />

      <b-input
        v-model="TravelArtespModal.artespCPF"
        class="b-margin-bottom--small"
        label="CPF"
        mask="cpf"
        :rules="{
          required: true,
          cpf: true
        }"
        block
      />

      <b-input
        v-model="TravelArtespModal.artespPassword"
        variant="outlined"
        label="Senha ARTESP"
        :rules="{
          required: true,
        }"
        :icon-right="$state.showPassword ? 'visibility_off' : 'visibility'"
        :type="$state.showPassword ? 'text' : 'password'"
        block
        @click-icon-right="$state.showPassword = !$state.showPassword"
      />
    </div>

    <div class="b-layout b-layout--horizontal-end b-margin-top--medium">
      <b-button
        variant="text"
        @click="TravelArtespModal.close"
      >
        Cancelar
      </b-button>

      <b-button
        :is-loading="TravelArtespModal.isLoading"
        :is-disabled="TravelArtespModal.isInvalidForm"
        class="b-margin-left--small"
        @click="TravelArtespModal.generateArtesp"
      >
        Gerar Licença
      </b-button>
    </div>
  </b-form>
</template>

<script setup>
import { reactive } from 'vue';
import { useTravelArtespModal } from '@stores';

const TravelArtespModal = useTravelArtespModal();

const $state = reactive({
  showPassword: false,
});
</script>
