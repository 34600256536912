/* eslint-disable no-console */
import amplitude from 'amplitude-js';
import { Settings } from '@settings';

export const setupAmplitude = () => {
  if (Settings.isDevelopment || !Settings.envs.AMPLITUDE_API_KEY) {
    console.log('Amplitude not initialized');
    return;
  }
  const params = {
    includeReferrer: true,
    includeUtm: true,
    saveParamsReferrerOncePerSession: false,
  };

  amplitude.getInstance()
    .init(Settings.envs.AMPLITUDE_API_KEY, params);
  console.log('Amplitude initialized');
};

export const configUser = (user) => {
  if (Settings.isDevelopment || !Settings.envs.AMPLITUDE_API_KEY || !user) return;

  const userDict = new amplitude.Identify()
    .set('email', user.email)
    .set('phone', user.cell_phone)
    .set('company_id', user?.company?.id)
    .set('company_name', user?.company?.name);
  amplitude.getInstance().identify(userDict);
};

export const setAmplitudeUser = (user) => {
  if (Settings.isDevelopment || !Settings.envs.AMPLITUDE_API_KEY || !user) return;

  const userId = user ? `user_${user.id}` : null;
  amplitude.getInstance().setUserId(userId);
  configUser(user);
  console.log('Amplitude configured');
};

export const amplitudeLogger = (event, config) => {
  if (Settings.isDevelopment || !Settings.envs.AMPLITUDE_API_KEY) return console.log(event, config);

  return amplitude.getInstance().logEvent(event, config);
};
