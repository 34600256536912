/* eslint-disable no-underscore-dangle, consistent-return, no-param-reassign */
import { AuthServices } from '@services/auth-services';
import {
  SessionsPageName,
  ErrorPageName,
  TravelsPageName,
} from '@assets/constants/route-names';
import { setAmplitudeUser } from '@utils/event-metrics';
import { useSession } from '@stores';
import { APPLICATION_LAYOUT, DEFAULT_LAYOUT } from '@assets/constants/layout-names';
import { setSentryUser } from '@utils/sentry/sentry';

const authGuard = async (to, _from, next) => {
  const currentUser = await AuthServices.currentUser();
  const $session = useSession();
  setAmplitudeUser(currentUser);
  setSentryUser(currentUser);

  $session.currentUser = currentUser;

  if (!to.meta) to.meta = {};

  to.meta.layoutName = to.meta?.layout?.name
    ?? (
      $session.isAuthenticated
        ? APPLICATION_LAYOUT
        : DEFAULT_LAYOUT
    );

  if (to.name === ErrorPageName) return next();

  if (to.name !== SessionsPageName && !$session.isAuthenticated) return next({ name: SessionsPageName });

  if (to.name === SessionsPageName && $session.isAuthenticated) return next({ name: TravelsPageName });

  next();
};

export { authGuard };
