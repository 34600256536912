<template>
  <b-menu-container
    :is-open="$props.menuIsOpen"
    position="bottom-right"
    class="app-header-section-session-menu"
    @click="$emit('close')"
  >
    <AppHeaderSectionSessionProfile />

    <AppHeaderSectionSessionMenuButton
      v-for="(element, index) in $state.dropdownSections"
      :key="index"
      :text="element.text"
      :items="element.items"
    />
  </b-menu-container>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { getDropdownSections } from './dropdown-sections';
import AppHeaderSectionSessionProfile from './app-header-section-session-profile.vue';
import AppHeaderSectionSessionMenuButton from './app-header-section-session-menu-button.vue';

const $router = useRouter();

const $state = reactive({
  dropdownSections: getDropdownSections($router),
});

defineEmits(['close']);

const $props = defineProps({
  menuIsOpen: {
    type: Boolean,
    required: true,
  },
});
</script>

<style lang="scss">
.app-header-section-session-menu {
  color: var(--color-grey);
}
</style>
