import { CompanyAPI } from '@api';

const setGroupDrivers = (groupId, driverOneId, driverTwoId) => {
  const params = {
    group_id: groupId,
    driver_one: driverOneId,
    driver_two: driverTwoId,
  };

  return CompanyAPI.setGroupDrivers(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { setGroupDrivers };
