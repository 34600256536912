import { CompanyAPI } from '@api';

const dermgGenerate = async ({
  username, password, groupGoingId, groupBackId, invoiceNumber, invoiceSerie,
}) => CompanyAPI.dermgGenerate({
  username,
  password,
  group_going_id: groupGoingId,
  group_back_id: groupBackId,
  invoice_data: {
    invoice_number: invoiceNumber,
    invoice_serie: invoiceSerie,
  },
})
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response.data));

export { dermgGenerate };
