/* eslint-disable no-param-reassign */

import Schema from 'validate';
import { toRaw } from 'vue';
import { isDataRepasse } from '@utils/date/is-data-repasse';
import { phoneRegex } from './phone';
import { emailRegex } from './email';
import { cnpjRegex, isValidCnpj } from './cnpj';
import { isValidCpf } from './cpf';
import { placaRegex } from './placa';
import { onlyNumbers } from './only-numbers';
import { onlyNumbersAndLetters } from './only-numbers-and-letters';
import { isDateInTheFuture } from './is-date-in-the-future';
import { isDateInThePast } from './is-date-in-the-past';

const defaultMessages = {
  required: () => 'Preenchimento obrigatório',
  match: function match(prop, _ctx, _regexp) {
    return `${prop} não é válido.`;
  },
  type(prop, _ctx, type) {
    const typeDictionary = {
      Number: 'número',
      String: 'texto',
      Array: 'lista',
      Object: 'objeto',
      Function: 'function',
    };
    if (typeof type === 'function') type = type.name;

    return `${prop} precisa ser do tipo: ${typeDictionary[type] || type}.`;
  },
  length(prop, _ctx, len) {
    if (typeof len === 'number') return `O ${prop} precisa ter o tamanho de ${len}.`;

    const { min } = len;
    const { max } = len;

    if (min && max) return `O ${prop} precisa ter o tamanho entre ${min} e ${max}.`;

    if (max) return `O ${prop} precisa ter o tamanho máximo de ${max}.`;

    if (min) return `O ${prop} precisa ter o tamanho mínimo de ${min}.`;

    return `${prop} não tem o tamanho certo.`;
  },
  size(prop, ctx, size) {
    if (typeof size === 'number') {
      return `${prop} precisa ter o tamanho de ${size}.`;
    }

    const { min } = size;
    const { max } = size;

    if (min !== undefined && max !== undefined) return `${prop} precisa ser entre ${min} e ${max}.`;

    if (max !== undefined) return `${prop} precisa ser menor ou igual a ${max}.`;

    if (min !== undefined) return `${prop} precisa ser maior ou igual a ${min}.`;

    return `${prop} não tem o valor correto.`;
  },
  onlyNumbers() {
    return 'Somente aceita números.';
  },
  onlyNumbersAndLetters() {
    return 'Somente aceita números e letras.';
  },
  isDateInTheFuture(prop) {
    return `${prop} não pode ser no passado`;
  },
  isDateInThePast(prop) {
    return `${prop} não pode ser no futuro`;
  },
  isValidCnpj() {
    return 'CNPJ inválido';
  },
  isValidCpf() {
    return 'CPF inválido';
  },
  isDataRepasse() {
    return 'A data de repasse deve ser numa quinta-feira';
  },
};

const validationsDictionary = {
  phone: phoneRegex,
  email: emailRegex,
  cnpj: cnpjRegex,
  placa: placaRegex,
};

const createRules = (rules) => ({
  ...rules,
  message: {
    ...defaultMessages,
    ...rules.message,
  },
});

const Validate = (value, rules, name = 'campo') => {
  const internalRules = rules;
  const internalValue = (Array.isArray(value) && value.length) ? toRaw(value)?.map(toRaw) : toRaw(value);
  Object.freeze(internalValue);

  if (!Array.isArray(internalRules)) {
    if (internalRules.startValidating) {
      delete internalRules.startValidating;
    }
    if (internalRules.match) {
      internalRules.match = (validationsDictionary[internalRules.match] || internalRules.match);
    }
    if (internalRules.onlyNumbers) {
      internalRules.use = { ...internalRules?.use, onlyNumbers };
      delete internalRules.onlyNumbers;
    }
    if (internalRules.onlyNumbersAndLetters) {
      internalRules.use = { ...internalRules?.use, onlyNumbersAndLetters };
      delete internalRules.onlyNumbersAndLetters;
    }
    if (internalRules.onlyFutureDates) {
      internalRules.use = { ...internalRules?.use, isDateInTheFuture };
      delete internalRules.onlyFutureDates;
    }
    if (internalRules.onlyPastDates) {
      internalRules.use = { ...internalRules?.use, isDateInThePast };
      delete internalRules.onlyPastDates;
    }
    if (internalRules.cnpj) {
      internalRules.use = { ...internalRules?.use, isValidCnpj };
      delete internalRules.cnpj;
    }
    if (internalRules.cpf) {
      internalRules.use = { ...internalRules?.use, isValidCpf };
      delete internalRules.cpf;
    }
    if (internalRules.isDataRepasse) {
      internalRules.use = { ...internalRules?.use, isDataRepasse };
      delete internalRules.isDataRepasse;
    }
  }

  const Validator = new Schema({
    [name]: createRules(internalRules),
  });
  const errors = Validator.validate({ [name]: internalValue });

  return errors;
};

export { Validate };
