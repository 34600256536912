<template>
  <div class="b-tooltip">
    <div
      :id="$state.id"
      ref="$element"
      class="b-tooltip__surface"
    >
      <slot />
    </div>

    <teleport to="body">
      <transition name="b-tooltip__transition--fade">
        <b-tooltip-content
          v-if="$state.isActive"
          :style="style"
        >
          <slot name="content" />
        </b-tooltip-content>
      </transition>
    </teleport>
  </div>
</template>

<script setup>
import {
  reactive,
  computed,
  ref,
} from 'vue';
import { idGenerator, useElementPosition } from '@composables/utilities';
import { shouldBeOneOf } from 'vue-prop-validation-helper';
import { useElementHover } from '@vueuse/core';
import { POSITION_OPTIONS } from '@assets/constants/positions';
import BTooltipContent from './b-tooltip-content.vue';

const $props = defineProps({
  id: {
    type: String,
    default: undefined,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: 'bottom',
    validator: shouldBeOneOf(POSITION_OPTIONS),
  },
});

const $element = ref();

const isHovered = useElementHover($element);

const $state = reactive({
  isActive: computed(() => !$props.isDisabled && isHovered.value),
  id: computed(() => $props.id || idGenerator('tooltip')),
  position: computed(() => $props.align),
});

const { style } = useElementPosition($element, $state);

</script>

<style lang="scss">
.b-tooltip {
  position: relative;
  width: fit-content;
}

.b-tooltip__transition--fade-enter-active {
  animation: scale-in 0.25s ease;
}
.b-tooltip__transition--fade-leave-active {
  animation: scale-in 0.25s reverse ease;
}

@keyframes scale-in {
  0% {
    opacity: 0.5;
    transform: scale(0.5), translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1), translateX(-50%);
  }
}
</style>
