import { CompanyServices } from '@services/company-services';
import { defineStore } from 'pinia';
import { TRAVEL_PAGE_FILTERS } from '@modules/travels';
import { debounce } from 'debounce';
import { CompanyAPI } from '@api/buser-http-client';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useInterface } from '@stores/app';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelsPage = defineStore('useTravelsPage', {
  state: () => ({
    drivers: [],
    groups: [],
    buses: [],
    cities: [],
    hasLastMonthTollDoc: false,
    isLoading: false,
    pagination: {
      limitOptions: [25, 50, 80],
      page: 1,
      limit: 25,
      totalPages: undefined,
      totalItems: undefined,
    },
    isOpenBatchOperationsModal: false,
    groupsSelected: [],
    allGroupsIsSelected: false,
    currentTab: 'viagensFretamento',
    fields: { ...TRAVEL_PAGE_FILTERS.viagensFretamento },
  }),
  getters: {
    filters(state) {
      const withPendencies = state.currentTab === 'viagensFretamentoPendencias';
      return {
        pendencies: withPendencies,
        initialized: state.fields?.initialized,
        busId: state.fields?.busSelected?.id || null,
        driverId: state.fields?.driverSelected?.id || null,
        status: state.fields?.statusSelected?.value || null,
        modeloVenda: state.fields?.businessTypeSelected?.value,
        groupId: state.fields?.groupId || null,
        rotinaId: state.fields?.rotinaId || null,
        cities: state.fields?.citiesSelected?.map?.((element) => element?.id) || null,
        origin: state.fields?.originSelected?.id || null,
        destiny: state.fields?.destinySelected?.id || null,
        departureAfter: !withPendencies ? state.fields?.departureAfter || null : undefined,
        departureBefore: !withPendencies ? state.fields?.departureBefore || null : undefined,
      };
    },
    canOpenBatchOperationModal(state) {
      return state.groupsSelected?.length > 0;
    },
    showTabFretamento(state) {
      return ['viagensFretamento', 'viagensFretamentoPendencias']
        .includes(state.currentTab);
    },
  },
  actions: {
    async requestDrivers() {
      const $interfaceState = useInterface();
      return CompanyAPI.getCompanyDrivers()
        .then((result = []) => {
          result.unshift({});
          this.drivers = result;
        })
        .catch(({ error = 'Error ao listar motoristas' }) => {
          $interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        });
    },
    async requestGroups() {
      this.isLoading = true;

      const params = {
        filters: this.filters,
        pagination: this.pagination,
      };

      return CompanyServices.getGroupsList(params)
        .then((result) => {
          if (result.content) {
            this.pagination.totalPages = result.total_pages;
            this.pagination.totalItems = result.count;
            this.groups = result.content;
          } else {
            this.groups = result;
          }
          return this.groups;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    requestGroupsWithDebounce: debounce(function debounceGroup() {
      this.requestGroups();
    }, 1500),

    async requestBuses() {
      return CompanyServices.getCompanyBuses({ onlyAvailableBuses: true })
        .then((result = []) => {
          result.unshift({});
          this.buses = result;
        });
    },

    async requestCities() {
      return CompanyServices.getCities()
        .then((result) => {
          this.cities = result;
        });
    },
    setPageTab(value) {
      this.groups = [];
      this.groupsSelected = [];
      this.allGroupsIsSelected = false;
      this.fields = TRAVEL_PAGE_FILTERS[value];
    },
    handleDownloadPassengerList(groupId, fileType) {
      const fileTypes = {
        xls: `/api/company/groups/${groupId}/passengers_xls`,
        csv: `/api/company/groups/${groupId}/passengers_csv`,
      };

      window.open(fileTypes[fileType], '_blank');
    },
  },
});
