<template>
  <aside
    class="app-sidebar"
    :aria-hidden="!$interfaceState.$sidebar.isOpen"
    tabindex="-1"
  >
    <nav
      class="app-sidebar__nav"
      :class="{
        [`app-sidebar__nav--is-open`]: $interfaceState.$sidebar.isOpen
      }"
    >
      <div class="app-sidebar__item">
        <b-text
          size="extra-small"
          weight="light"
        >
          {{ $session?.currentUser?.company?.name }}
        </b-text>
      </div>

      <AppSidebarButtom
        v-for="(element, index) in $state.menuItems"
        :key="index"
        :text="element.text"
        :icon="element.icon"
        :items="element.items"
        :submenu-is-open="element.submenuIsOpen"
        :url="element.url"
        @change-submenu="element.submenuIsOpen = $event"
        @click="$interfaceState.$sidebar.isOpen = false"
      />
    </nav>

    <div
      v-if="$interfaceState.$sidebar.isOpen"
      class="app-sidebar__overlayer"
      @click="$interfaceState.$sidebar.isOpen = false"
    />
  </aside>
</template>

<script setup>
import { reactive } from 'vue';
import { useSession, useInterface } from '@stores';
import { getMenuItem } from '@components/app/section-menu-items';
import AppSidebarButtom from './app-sidebar-button.vue';

const $interfaceState = useInterface();
const $session = useSession();

const $state = reactive({
  menuItems: getMenuItem(),
});
</script>

<style lang="scss">
.app-sidebar {
  z-index: 1000;
}

.app-sidebar__nav {
  position: absolute;
  height: 100%;
  width: 300px;
  z-index: 2000;
  background-color: var(--color-white);
  left: -300px;
  transition: left 0.25s, box-shadow 0.25s;
  top: 0;
  color: var(--color-grey);
  padding: var(--size-scalable-small) 0;
  overflow: auto;

  &--is-open {
    left: 0px;
    box-shadow: var(--shadow-e-012);
  }
}

.app-sidebar__item {
  padding: var(--size-scalable-medium);
  display: flex;
  align-items: center;
  width: 100%;
}

.app-sidebar__overlayer {
  background-color: rgba(0, 0, 0, .32);
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
}
</style>
