<template>
  <b-modal
    size="small"
    class="user-cpf-modal b-padding--extra-large"
    :is-open="$props.modelValue"
    @close="close"
  >
    <div class="user-cpf-modal__header">
      <b-text
        size="large"
        weight="semi-bold"
      >
        Por favor, informe seu CPF para ativarmos sua conta do curso! 😉
      </b-text>
    </div>

    <b-form
      v-slot="{isInvalid}"
      name="UserUpdate"
      @submit="handleSaveButton"
    >
      <b-input
        v-model="$state.cpf"
        class="user-cpf-modal__body"
        mask="cpf"
        label="CPF"
        behavior="block"
        :maxlength="14"
        :rules="cpfRules"
      />

      <div class="user-cpf-modal__footer">
        <b-button
          class="user-cpf-modal__footer-save"
          :is-loading="isLoading"
          :is-disabled="isInvalid || isLoading"
          @click="handleSaveButton"
        >
          Salvar
        </b-button>

        <b-button
          class="user-cpf-modal__footer-cancel"
          variant="text"
          @click="close"
        >
          Cancelar
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script setup>
import { useState } from '@composables/hooks';
import { useInterface } from '@stores';
import { CompanyServices } from '@services/company-services';
import { EventLogger } from '@utils/event-metrics';
import { reactive } from 'vue';
import { CompanyAPI } from '@api/buser-http-client';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';

const events = EVENTS_DICTIONARY.Application;

const $props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const $interfaceState = useInterface();

const [isLoading, setIsLoading] = useState(false);
const $state = reactive({ cpf: '' });

const cpfRules = {
  type: String,
  required: true,
  length: 11,
};

const $emit = defineEmits(['update:modelValue']);
const close = () => $emit('update:modelValue', false);

const handleSaveButton = (event) => {
  if (event?.isInvalid) return;
  setIsLoading(true);

  const updateParams = { cpf: $state.cpf };

  CompanyServices.updateUser(updateParams)
    .then(() => {
      close();
      EventLogger.logPageEvent('Application', events.updateUserCPFSuccess);
      return CompanyAPI.getCompanyCourseLink()
        .then(({ link }) => {
          window.open(link, '_blank');
        });
    })
    .catch((error) => {
      EventLogger.logPageEvent('Application', events.updateUserCpfUnsuccessfully);
      $interfaceState.toast.add({ label: error.data.error, color: 'danger' });
    })
    .finally(() => {
      setIsLoading(false);
    });
};
</script>

<style lang="scss">
@import '@assets/styles/utils/breakpoints';

.user-cpf-modal__header {
  margin-bottom: var(--size-scalable-jumbo);
}

.user-cpf-modal__body {
  margin-bottom: var(--size-scalable-jumbo);
}

.user-cpf-modal__footer-save {
  margin-right: var(--size-scalable-small);
}
</style>
