/* eslint-disable camelcase, no-unused-vars, no-console */
import { Settings } from '@settings';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const RELEASE_NAME = 'buser-parceiro-front';

const setupSentry = (app, router) => {
  if (!app || !router || Settings.isDevelopment) console.log('Sentry not initialized');
  else {
    const release = `${RELEASE_NAME}@${Settings.envs.SENTRY_VERSION}`;
    Sentry.init({
      app,
      debug: false,
      dsn: Settings.envs.SENTRY_DSN,
      release,
      environment: Settings.envs.SENTRY_ENVIRONMENT,
      ignoreErrors: ['UnhandledRejection'],
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'https://parceiro.buser.com.br/', /^\//],
        }),
      ],
      tracesSampleRate: 0.01,
    });

    console.log('Sentry initialized', `release: ${release}`);
  }
};

const setSentryUser = (user) => {
  if (Settings.isDevelopment) return;
  Sentry.configureScope((scope) => {
    if (user) {
      const {
        id, name, email, cell_phone,
      } = user;
      scope.setUser({
        id, name, email, cell_phone,
      });
    }
    console.log('Sentry configured');
  });
};

const setSentryFrontEndType = () => {
  if (Settings.isDevelopment) return;
  Sentry.configureScope((scope) => {
    scope.setTag('runtime.name', 'newFront');
  });
};

export { setupSentry, setSentryUser, setSentryFrontEndType };
