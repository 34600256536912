<template>
  <div
    class="b-tooltip__content"
    tabindex="-1"
  >
    <slot />
  </div>
</template>

<style lang="scss">
.b-tooltip__content {
  background-color: #232F34;
  color: var(--color-white);
  font-size: var(--size-progressive-micro);
  border-radius: var(--border-radius-semi-high);
  padding: var(--size-scalable-small);
  min-height: var(--size-scalable-large);
  z-index: 3000;
}
</style>
