import { AuthAPI } from '@api';

const forgotPassword = async (userId) => {
  const params = { id: userId };

  return AuthAPI.forgotPassword(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { forgotPassword };
