<template>
  <teleport
    v-if="$state.showFooter"
    :to="$props.to"
  >
    <slot />
  </teleport>
</template>

<script setup>
import { reactive, onMounted } from 'vue';

const $props = defineProps({
  to: {
    type: String,
    required: true,
  },
});

const $state = reactive({
  showFooter: Boolean(document.querySelector($props.to)),
});

onMounted(() => {
  $state.showFooter = true;
});
</script>

<style lang="scss" scoped>

</style>
