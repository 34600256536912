<template>
  <span
    class="b-chip mdc-evolution-chip"
    :class="{
      'mdc-evolution-chip--disabled': isDisabled,
      'mdc-evolution-chip--selectable': isSelectable,
      'mdc-evolution-chip--selected': isSelected,
      [`b-chip--color-${color}`]: color,
      [`b-chip--size-${size}`]: size,
    }"
  >
    <span
      class="mdc-evolution-chip__cell mdc-evolution-chip__cell--secondary"
    >
      <component
        :is="tag"
        class="mdc-evolution-chip__action mdc-evolution-chip__action--primary"
        :type="buttonType"
        tabindex="-1"
        :disabled="isDisabled"
      >
        <b-icon
          v-if="iconLeft"
          :size="iconLeftSize"
          :color="iconLeftColor"
          :icon="iconLeft"
          class="b-margin-right--extra-small"
          @click="$emit('click-icon-left')"
        />

        <span class="mdc-evolution-chip__ripple mdc-evolution-chip__ripple--primary" />
        <span class="mdc-evolution-chip__text-label">{{ label }}<slot /></span>

        <b-icon
          v-if="iconRight"
          :size="iconRightSize"
          :color="iconRightColor"
          :icon="iconRight"
          class="b-margin-left--extra-small"
          @click="$emit('click-icon-right')"
        />
      </component>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    buttonType: {
      type: String,
      default: 'button',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconRightSize: {
      type: String,
      default: 'medium',
    },
    iconRightColor: {
      type: String,
      default: 'inherit',
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconLeftSize: {
      type: String,
      default: 'medium',
    },
    iconLeftColor: {
      type: String,
      default: 'inherit',
    },
  },
  emits: ['click-icon-left', 'click-icon-right'],
};
</script>

<style lang="scss">
@use "@material/chips/styles";

:root {
  --b-chip--color: var(--color-blue-grey-scale-900);
  --b-chip--background-color: var(--color-grey-scale-300);
  --b-chip--border-width: var(--size-scalable-nano);
  --b-chip--border-style: solid;
  --b-chip--border-color: transparent;
  --b-chip--font-weight: 500;
  --b-chip--min-height: var(--size-progressive-colossal);
  --b-chip--font-size: inherit;
}

.b-chip {
  background-color: var(--b-chip--background-color);
  border: var(--b-chip--border-width) var(--b-chip--border-style) var(--b-chip--border-color);
  --mdc-typography-body2-font-size: var(--b-chip--font-size);
  min-height: var(--b-chip--min-height);
  height: auto;

  &--size {
    &-small {
      --b-chip--min-height: var(--size-progressive-extra-jumbo);
      --b-chip--font-size: var(--size-progressive-micro);
    }
  }

  &--color {
    &-success {
      --b-chip--background-color: var(--color-green-scale-050);
      --b-chip--border-color: var(--color-green-scale-100);
    }
    &-warn {
      --b-chip--background-color: var(--color-amber-scale-050);
      --b-chip--border-color: var(--color-amber-scale-100);
    }
    &-info {
      --b-chip--background-color: var(--color-blue-scale-050);
      --b-chip--border-color: var(--color-blue-scale-100);
    }
    &-danger {
      --b-chip--background-color: var(--color-red-scale-050);
      --b-chip--border-color: var(--color-red-scale-100);
    }
    &-purple {
      --b-chip--color: var(--color-white);
      --b-chip--background-color: var(--color-purple-scale-600);
      --b-chip--border-color: var(--color-purple-scale-600);
    }
  }
}

.mdc-evolution-chip .mdc-evolution-chip__text-label {
  color: var(--b-chip--color);
  font-weight: var(--b-chip--font-weight);
}

.mdc-evolution-chip__cell {
  position: relative;
  width: 100%;
}
</style>
