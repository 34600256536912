const uniformity = [
  { value: 'sem_extras', text: 'Sem opcionais', disabled: false },
  { value: 'cinto_seguranca', text: 'Cinto de Segurança', disabled: false },
  { value: 'tomadas_individuais', text: 'Tomadas Individuais', disabled: false },
  { value: 'tomadas_coletivas', text: 'Tomadas Coletivas', disabled: false },
  { value: 'usb_individual', text: 'USB Individual', disabled: false },
  { value: 'wifi', text: 'Wi-Fi', disabled: false },
  { value: 'som', text: 'Som', disabled: false },
  { value: 'tv', text: 'TV', disabled: false },
  { value: 'video_boas_vindas', text: 'Vídeo de Boas Vindas', disabled: false },
  { value: 'audio_boas_vindas', text: 'Áudio de Boas Vindas', disabled: false },
  {
    value: 'assento_extra_motorista',
    text: 'Assento Extra 2º Motorista (caso ônibus não tenha cama de descanso)',
    disabled: false,
  },
  { value: 'termometro', text: 'Termômetro', disabled: false },
  { value: 'cvs', text: 'CVS', disabled: false },
  { value: 'telemetria', text: '(Buser) Telemetria', disabled: true },
  { value: 'camera_fadiga', text: '(Buser) Camera de Fadiga', disabled: true },
  { value: 'camera_monitoramento', text: '(Buser) Camera de Monitoramento', disabled: true },
  { value: 'acessibilidade_deficientes', text: '(Buser) Acessibilidade para deficientes', disabled: true },
  { value: 'plotagem_buser', text: '(Buser) Plotagem da Buser', disabled: true },
  { value: 'plotagem_artesp', text: '(Buser) Plotagem ARTESP', disabled: true },
  { value: 'seguro_buser', text: '(Buser) Seguro da Buser', disabled: true },
  { value: 'seguro_antt', text: '(Buser) Seguro da ANTT', disabled: true },
  { value: 'encosto_cabeca', text: '(Buser) Encosto de Cabeça', disabled: true },
  { value: 'cartao_pre_pago', text: '(Buser) Cartão Pré Pago', disabled: true },
  { value: 'check_clonagem', text: '(Buser) Check DETRAN', disabled: true },
  { value: 'impressora', text: '(Buser) Impressora', disabled: true },
  { value: 'adaptado_contra_covid', text: '(Buser) Adaptado contra COVID-19', disabled: true },
];

const getUniformity = () => JSON.parse(JSON.stringify(uniformity));

export { getUniformity };
