import { createComponentWithLoader } from '@utils/promises/create-component-with-loader';
import ArtespForm from './steps/travel-artesp-form.vue';
import ArtespPreview from './steps/travel-artesp-preview.vue';
import LoadingStep from './steps/loading-step.vue';

const loadingComponent = LoadingStep;

const stepsDictionary = {
  ArtespForm: createComponentWithLoader({ component: ArtespForm, loadingComponent }),
  ArtespPreview: createComponentWithLoader({ component: ArtespPreview, loadingComponent }),
};

export { stepsDictionary };
