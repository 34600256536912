<template>
  <div class="wallet-sucess-saque-modal__body">
    <b-icon
      class="b-margin-bottom--medium"
      size="extra-colossal"
      color="success"
      :icon="'check_circle'"
    />

    <b-text
      size="large"
      color="success"
      weight="medium"
      class="b-margin-bottom--small"
    >
      Saque realizado com sucesso!
    </b-text>

    <b-text size="extra-small">
      Comprovante enviado por e-mail
    </b-text>

    <div v-if="$WalletSacarModalStore?.transaction?.bank_account">
      <b-text
        weight="semi-bold"
        class="b-margin-bottom--medium b-margin-top--medium"
      >
        Dados da transação:
      </b-text>

      <b-text>Data: {{ getDate($WalletSacarModalStore.transaction.date) }}</b-text>

      <b-text>Banco: {{ $WalletSacarModalStore.transaction.bank_account.bank_code }}</b-text>

      <b-text>
        Agência:
        {{ $WalletSacarModalStore.transaction.bank_account.agencia }}
        -
        {{ $WalletSacarModalStore.transaction.bank_account.agencia_dv }}
      </b-text>

      <b-text>
        Conta:
        {{ $WalletSacarModalStore.transaction.bank_account.conta }}
        -
        {{ $WalletSacarModalStore.transaction.bank_account.conta_dv }}
      </b-text>

      <b-text>CPF/CNPJ: {{ $WalletSacarModalStore.transaction.bank_account.document_number }}</b-text>

      <b-text>Favorecido: {{ $WalletSacarModalStore.transaction.bank_account.legal_name }}</b-text>

      <b-text>ID da transação: {{ $WalletSacarModalStore.transaction.id }}</b-text>
    </div>
  </div>

  <div class="wallet-sucess-saque-modal__footer b-margin-top--large">
    <b-button
      @click="$WalletSacarModalStore.close"
    >
      Entendi
    </b-button>
  </div>
</template>

<script setup>
import { useWalletSacarModalStore } from '@stores/finances';
import { getDate } from '@utils/date/get-date';

const $WalletSacarModalStore = useWalletSacarModalStore();
</script>

<style lang="scss">
.wallet-sucess-saque-modal__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-sucess-saque-modal__footer {
  display: flex;
  justify-content: flex-end;
}
</style>
