import { ApplicationLayout, PinkEmptyLayout, WhiteEmptyLayout } from '@layouts';

const LayoutPlugin = {
  install(Vue) {
    Vue.component('ApplicationLayout', ApplicationLayout);
    Vue.component('PinkEmptyLayout', PinkEmptyLayout);
    Vue.component('WhiteEmptyLayout', WhiteEmptyLayout);
  },
};

export { LayoutPlugin };
