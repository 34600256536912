<template>
  <div
    class="b-table-pagination mdc-data-table__pagination"
    colspan="100%"
  >
    <div class="mdc-data-table__pagination-trailing">
      <div class="mdc-data-table__pagination-rows-per-page">
        <div
          v-show="canChangeItemPerPage"
          class="mdc-data-table__pagination-rows-per-page-label"
        >
          {{ $props.label }}
        </div>

        <div
          v-show="canChangeItemPerPage"
          ref="paginationSelect"
          class="mdc-select mdc-select--outlined mdc-select--no-label mdc-data-table__pagination-rows-per-page-select"
        >
          <div
            class="mdc-select__anchor"
            role="button"
            aria-haspopup="listbox"
            tabindex="0"
          >
            <span class="mdc-select__selected-text-container">
              <span
                class="mdc-select__selected-text"
              >{{ $props.itemsPerPage }}</span>
            </span>

            <span class="mdc-select__dropdown-icon">
              <svg
                class="mdc-select__dropdown-icon-graphic"
                viewBox="7 10 10 5"
              >
                <polygon
                  class="mdc-select__dropdown-icon-inactive"
                  stroke="none"
                  fill-rule="evenodd"
                  points="7 10 12 15 17 10"
                />
                <polygon
                  class="mdc-select__dropdown-icon-active"
                  stroke="none"
                  fill-rule="evenodd"
                  points="7 15 12 10 17 15"
                />
              </svg>
            </span>
            <span class="mdc-notched-outline mdc-notched-outline--notched">
              <span class="mdc-notched-outline__leading" />
              <span class="mdc-notched-outline__trailing" />
            </span>
          </div>

          <div
            class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth"
            role="listbox"
          >
            <ul class="mdc-list">
              <li
                v-for="(number, index) in $props.numberOfItemsPerPageAvailable"
                :key="index"
                class="mdc-list-item"
                :class="{
                  'mdc-list-item--selected': number === $props.itemsPerPage,
                }"
                :aria-selected="number === $props.itemsPerPage"
                role="option"
                :data-value="number"
              >
                <span class="mdc-list-item__text">{{ number }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mdc-data-table__pagination-navigation">
        <div class="mdc-data-table__pagination-total">
          {{
            $state.isEmpty ? '0' : $state.currentIndex
          }}‑{{
            $state.isEmpty ? '0' : $state.pageMax
          }} de {{ totalItems }}
        </div>

        <button
          class="mdc-icon-button material-icons mdc-data-table__pagination-button"
          data-first-page="true"
          :disabled="$state.isFirstPage"
          @click="handleEmit('go-to-first-page')"
        >
          <div class="mdc-button__icon">
            first_page
          </div>
        </button>

        <button
          class="mdc-icon-button material-icons mdc-data-table__pagination-button"
          data-prev-page="true"
          :disabled="$state.isFirstPage"
          @click="handleEmit('go-to-previous-page')"
        >
          <div class="mdc-button__icon">
            chevron_left
          </div>
        </button>

        <button
          class="mdc-icon-button material-icons mdc-data-table__pagination-button"
          data-next-page="true"
          :disabled="$state.isLastPage"
          @click="handleEmit('go-to-next-page')"
        >
          <div class="mdc-button__icon">
            chevron_right
          </div>
        </button>

        <button
          class="mdc-icon-button material-icons mdc-data-table__pagination-button"
          data-last-page="true"
          :disabled="$state.isLastPage"
          @click="handleEmit('go-to-last-page')"
        >
          <div class="mdc-button__icon">
            last_page
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  computed,
  reactive,
  unref,
} from 'vue';
import { MDCSelect } from '@material/select';

const paginationSelect = ref();

const $props = defineProps({
  label: {
    type: String,
    default: 'Items por página',
  },
  numberOfItemsPerPageAvailable: {
    type: Array,
    default: () => [10, 25, 100],
  },
  itemsPerPage: {
    type: Number,
    default: 10,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
  totalItems: {
    type: Number,
    default: 0,
  },
  canChangeItemPerPage: {
    type: Boolean,
    default: false,
  },
});

const $state = reactive({
  totalPages: computed(() => (Math.ceil($props.totalItems / $props.itemsPerPage))),
  currentIndex: computed(() => (($props.currentPage * $props.itemsPerPage) - $props.itemsPerPage + 1)),
  isEmpty: computed(() => $props.totalItems === 0),
  isFirstPage: computed(() => ($props.currentPage === 1)),
  isLastPage: computed(() => ($props.currentPage === $state.totalPages || $state.totalPages === 0)),
  pageMaxPreview: computed(() => ($state.currentIndex + $props.itemsPerPage) - 1),
  pageMax: computed(() => (
    $state.isLastPage
      ? $props.totalItems
      : $state.pageMaxPreview
  )),
});

const $emit = defineEmits([
  'update:page',
  'update:currentPage',
  'update:itemsPerPage',
  'go-to-first-page',
  'go-to-previous-page',
  'go-to-next-page',
  'go-to-last-page',
]);

const handleEmit = ($event) => {
  let newPage;
  switch ($event) {
    case 'go-to-first-page':
      newPage = 1;
      break;
    case 'go-to-previous-page':
      newPage = $props.currentPage - 1;
      break;
    case 'go-to-next-page':
      newPage = $props.currentPage + 1;
      break;
    case 'go-to-last-page':
      newPage = $state.totalPages;
      break;
    default:
      newPage = 1;
      break;
  }

  $emit($event);
  $emit('update:currentPage', newPage);
  $emit('update:page');
};

onMounted(() => {
  const select = new MDCSelect(unref(paginationSelect));

  select.listen('MDCSelect:change', () => {
    const newPageLimit = Number(select.value);
    const newPage = Math.ceil($state.currentIndex / newPageLimit);

    $emit('update:itemsPerPage', newPageLimit);
    $emit('update:currentPage', newPage);
    $emit('update:page');
  });
});

</script>

<style lang="scss">
.b-table-pagination {
  width: 100%;
}
</style>
