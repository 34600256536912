import { amplitudeLogger } from './amplitude';

export const EventLogger = {
  init(route) {
    this.$route = route;
  },

  log(eventType, eventData = {}) {
    amplitudeLogger(eventType, eventData);
  },

  logPageEvent(eventPage, eventType, eventData = {}) {
    const eventName = `${eventPage}${eventType ? ': ' : ''}${eventType}`;
    amplitudeLogger(eventName, eventData);
  },

  logEvent(eventType, eventData = {}) {
    this.logPageEvent(this.$route.name, eventType, eventData);
  },
};
