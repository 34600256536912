<template>
  <li class="app-edit-card-line">
    <b-text
      class="app-edit-card-line__title"
      weight="semi-bold"
      tag="label"
      :for="id"
    >
      {{ $props.title }}
    </b-text>

    <b-text
      v-if="$props.content"
      :id="id"
    >
      {{ $props.content }}
    </b-text>
  </li>
</template>

<script setup>
import { idGenerator } from '@composables/utilities';

const $props = defineProps({
  title: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    default: '-',
  },
  mask: {
    type: String,
    default: null,
  },
});

const id = idGenerator();
</script>

<style lang="scss">
.app-edit-card-line {
  list-style-type: none;

  &:not(:last-of-type) {
    margin-bottom: var(--size-scalable-medium);
  }
}
</style>
