import { CompanyServices } from '@services/company-services';
import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { useSession, useInterface } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { createCopy } from '@utils/object/create-copy';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelInvoiceManualModal = defineStore('TravelInvoiceManualModal', {
  state: () => ({
    group: null,
    company: null,
    errorResult: null,
    currentStep: null,
    fixingInvoice: false,
    invoiceCancelSimulation: null,
    isModalOpen: false,
    isLoading: false,
    invoiceFileToUpload: null,
    invoiceFileData: null,
    invoice: {
      icms: null,
      value: '0.00',
      bcReduction: 0,
    },
  }),
  actions: {
    async openAndFix(group) {
      EventLogger.logEvent(events.openUploadInvoiceManuallyModal);
      this.fixingInvoice = true;
      await this.requestSimulateInvoiceCancelation(group);
      this.open(group);
    },
    async open(group) {
      const $session = useSession();
      this.group = createCopy(group);
      this.company = $session.getUserCompany();
      this.currentStep = 'UploadInvoiceFormStep';
      this.isModalOpen = true;
    },
    close() {
      this.isModalOpen = false;
      this.group = null;
      this.company = null;
      this.currentStep = null;
      this.fixingInvoice = false;
      this.invoiceCancelSimulation = null;
      this.isLoading = false;
      this.invoiceFileToUpload = null;
      this.invoiceFileData = null;
      this.invoice = {
        icms: null,
        value: '0.00',
        bcReduction: 0,
      };
      this.errorResult = null;
    },
    async requestSimulateInvoiceCancelation(group) {
      return CompanyServices.simulateInvoiceCancelation(group.id)
        .then(({ message }) => {
          this.invoiceCancelSimulation = message;
        });
    },
    fileSelected(file) {
      if (!file) return;
      this.isLoading = true;
      this.invoiceFileData = null;
      const $interfaceState = useInterface();
      CompanyServices.validateInvoice(this.group.id, file)
        .then((data) => {
          this.invoiceFileData = {
            numero: data.numero,
            valor: data.valor,
            cteos_serie: data.serie,
            is_pdf: file.type === 'application/pdf',
          };
          $interfaceState.toast.add({
            label: 'Nota Fiscal está nos padrões!',
            color: 'success',
          });
        })
        .catch(({ error }) => {
          $interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    requestUploadInvoice() {
      this.isLoading = true;
      const TravelsPage = useTravelsPage();

      CompanyServices.uploadInvoice(this.group.id, this.invoiceFileToUpload, this.invoiceFileData)
        .then(() => {
          TravelsPage.requestGroups();
        })
        .catch((error) => {
          this.errorResult = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
