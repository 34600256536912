<template>
  <b-form
    v-if="TravelAnttModal.group"
    v-model="TravelAnttModal.isInvalidTravelInfoForm"
    name="TravelAnttModal"
  >
    <div class="antt-travel-info-step__body b-margin-top--medium">
      <b-row
        :responsive="false"
        class="antt-travel-info__form"
      >
        <b-column :column="12">
          <b-select
            v-model="TravelAnttModal.selectedGroupTravelBack"
            label="Grupo de volta"
            keyed-by="id"
            labelled-by="name"
            block
            :rules="{
              required: true,
            }"
            :items="TravelAnttModal.groupsTravelBack"
          />
        </b-column>

        <b-column :column="12">
          <b-input
            v-model="TravelAnttModal.anttInvoiceValue"
            label="Valor Nota Fiscal"
            mask="money"
            block
            :rules="{
              required: true,
            }"
          />
        </b-column>

        <b-column :column="6">
          <b-input
            v-model="TravelAnttModal.anttInvoiceNumber"
            label="Número nota fiscal"
            block
            :rules="{
              required: true,
              onlyNumbers: true,
            }"
          />
        </b-column>

        <b-column :column="6">
          <b-input
            v-model="TravelAnttModal.anttInvoiceSeries"
            label="Série da nota fiscal"
            block
            :rules="{
              required: true,
              onlyNumbers: true,
            }"
          />
        </b-column>

        <b-column :column="12">
          <b-select
            v-model="TravelAnttModal.selectedDriverOne"
            :items="TravelAnttModal.driversAntt"
            keyed-by="codigo"
            labelled-by="nome"
            label="Motorista 1"
            block
            :rules="{
              required: true,
            }"
          />
        </b-column>
        <b-column :column="12">
          <b-select
            v-model="TravelAnttModal.selectedDriverTwo"
            :items="TravelAnttModal.driversAntt"
            keyed-by="codigo"
            labelled-by="nome"
            label="Motorista 2 (Opcional)"
            block
          />
        </b-column>
      </b-row>
    </div>

    <div class="antt-travel-info-step__footer b-margin-top--medium">
      <b-button
        variant="text"
        @click="TravelAnttModal.close"
      >
        Cancelar
      </b-button>
      <b-button
        class="b-margin-left--small"
        :is-disabled="TravelAnttModal.isInvalidTravelInfoForm"
        :is-loading="TravelAnttModal.isGenerateAnttLoading"
        @click="TravelAnttModal.generateAntt"
      >
        Gerar Autorização
      </b-button>
    </div>
  </b-form>
</template>

<script setup>
import { useTravelAnttModal } from '@stores';

const TravelAnttModal = useTravelAnttModal();
</script>

<style lang="scss">
.antt-travel-info-step__footer {
  display: flex;
  justify-content: flex-end;
}

.antt-travel-info__form {
  --grid-row-gap: var(--size-scalable-small);
}
</style>
