import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useInterface } from '@stores';

const events = EVENTS_DICTIONARY[TravelsPageName];
const MAX_FILE_SIZE_BYTES = 75 * 1024 * 1024;

export const useTravelTollMonthlyStatement = defineStore('TravelTollMonthlyStatement', {
  state: () => ({
    tolls: [],
    isLoading: false,
    isModalOpen: false,
  }),
  actions: {
    addFile(file) {
      if (file.type !== 'application/pdf') {
        useInterface().toast.add({
          label: 'Arquivos somente em PDF',
          color: 'danger',
        });
        return;
      }

      if (this.tolls.find((item) => item.file.name === file.name)) {
        useInterface().toast.add({
          label: 'Esse arquivo já foi anexado!',
          color: 'warn',
        });
        return;
      }

      if (file.size > MAX_FILE_SIZE_BYTES) {
        useInterface().toast.add({
          label: 'Somente é possível adicionar arquivos até 75 MB',
          color: 'warn',
        });
        return;
      }

      this.tolls.push({ file, tollCompany: null });
    },
    close() {
      this.tolls = [];
      this.isLoading = null;
      this.isModalOpen = false;
    },
    open() {
      this.isModalOpen = true;
    },
    removeFile(file) {
      const index = this.tolls.findIndex((t) => t.file?.name === file.name);
      this.tolls.splice(index, 1);
    },
  },
});
