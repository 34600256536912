<template>
  <div
    v-if="TravelArtespModal.error"
    class="text-error fw-bold"
  >
    <template v-if="TravelArtespModal.errorStatusIsTimeout">
      <p>Nosso sistema está tendo problemas com o sistema da ARTESP.</p>
      <p>Enquanto estamos resolvendo, emita a autorização direto no portal da ARTESP:</p>
      <a :href="artespUrl">{{ artespUrl }}</a>
    </template>

    <template v-else>
      {{ TravelArtespModal.error.toString() }}
    </template>
  </div>

  <div class="b-layout b-layout--horizontal-end b-margin-top--medium">
    <b-button
      variant="text"
      @click="TravelArtespModal.close"
    >
      Cancelar
    </b-button>

    <b-button
      class="b-margin-left--small"
      @click="TravelArtespModal.printArtesp"
    >
      Imprimir
    </b-button>
  </div>
</template>

<script setup>
import { useTravelArtespModal } from '@stores';

const artespUrl = 'http://www.artesp.sp.gov.br/';

const TravelArtespModal = useTravelArtespModal();
</script>
