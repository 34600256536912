import { defineStore } from 'pinia';
import { CompanyServices } from '@services/company-services';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { createCopy } from '@utils/object/create-copy';
import { WalletPageName } from '@assets/constants/route-names';
import {
  useInterface,
  useGeneralSettings,
  useSession,
  useWalletTermsAndConditionsModalStore,
  useWalletSacarModalStore,
} from '@stores';
import { notEmpty } from '@utils/validations/not-empty';
import { OpsServices } from '@services/ops-services';
import dayjs from 'dayjs';
import { state } from './state';

const events = EVENTS_DICTIONARY[WalletPageName];

export const useWalletPageStore = defineStore('WalletPageStore', {
  state,
  getters: {
    currentWallet($state) {
      return $state.walletsData[$state.currentActiveWalletName] || undefined;
    },
    currentWalletIsMarketplace($state) {
      return $state.currentActiveWalletName === 'marketplace';
    },
    financialPenaltiesValueFromLastWeek($state) {
      return $state.financialPenalties
        ?.reduce((accumulator, currentValue) => (accumulator + currentValue.valor_multa), 0);
    },
  },
  actions: {
    async handleWantToSacarClick(walletName) {
      const $GeneralStore = useGeneralSettings();
      const $session = useSession();
      const hasAcceptedContract = $session?.currentUser.company.has_accepted_contract;
      const enableContrato = $GeneralStore.settings.enable_contrato_parceiros;
      EventLogger.logEvent(events.checkContractAccepted);

      const walletNameIsMarketplace = walletName === 'marketplace';
      const walletNameIsPedagio = walletName === 'pedagio';

      const companyHasAcceptedContract = !hasAcceptedContract
        && enableContrato
        && walletNameIsMarketplace;

      if (companyHasAcceptedContract) {
        const $WalletTermsAndConditionsModalStore = useWalletTermsAndConditionsModalStore();
        $WalletTermsAndConditionsModalStore.open();
        return null;
      }

      if (walletNameIsMarketplace) return null;

      if (walletNameIsPedagio) {
        const result = await this.requestCheckIfHasSentMonthlyTollStatement();
        if (!result) return null;
      }

      return this.openSacarModal();
    },
    handleToggleMoneyVisibility(walletName) {
      this.walletsData[walletName].showMeTheMoney = !this.walletsData[walletName].showMeTheMoney;
    },
    handleSelectWallet(walletName) {
      if (walletName === 'marketplace' && !notEmpty(this.walletsData.marketplace.data)) {
        this.requestMarketplaceSaldoFuturo();
      }

      this.currentActiveWalletName = walletName;
    },
    openSacarModal() {
      const $WalletSacarModalStore = useWalletSacarModalStore();
      $WalletSacarModalStore.open();
    },
    async requestCheckIfHasSentMonthlyTollStatement() {
      if (dayjs().get('date') <= 10) {
        return true;
      }

      const $session = useSession();

      return OpsServices.verifyMonthlyTollStatement($session.currentUser.company.id)
        .then(() => true)
        .catch(({ error }) => {
          const $interfaceState = useInterface();
          $interfaceState.toast.add({
            label: error,
            color: 'warn',
          });
          return false;
        });
    },
    getInitialWalletExtract() {
      return this.requestWalletExtract(this.howManyDaysFilter);
    },
    requestMarketplaceSaldoFuturo() {
      this.isLoading = true;
      this.walletsData.marketplace.loadingProxRepasse = true;
      const marketplaceWallet = this.walletsData.marketplace;
      return CompanyServices
        .getSaldoFuturoMarketplace(marketplaceWallet.initialDate, marketplaceWallet.endDate)
        .then((extrato) => {
          marketplaceWallet.data = extrato;
        })
        .finally(() => {
          this.walletsData.marketplace.loadingProxRepasse = false;
          this.isLoading = false;
        });
    },
    getProximoRepasseMarketplace() {
      const now = dayjs();
      const ultimaQuinta = now.format('%d') >= now.weekday(4).format('%d')
        ? now.weekday(4) // Quinta da semana atual
        : now.weekday(-3); // Quinta da semana passada
      const ultimaQuarta = ultimaQuinta.subtract(1, 'day');
      const proximaTerca = ultimaQuinta.add(5, 'day');
      this.walletsData.marketplace.data.proximaQuinta = ultimaQuinta.add(7, 'day');

      this.walletsData.marketplace.loadingProxRepasse = true;
      CompanyServices.getSaldoFuturoMarketplace(
        ultimaQuarta.format('YYYY-MM-DD'),
        proximaTerca.format('YYYY-MM-DD'),
      ).then((extrato) => {
        // Essa subtracao de 1/7 (1 dia da semana) eh uma aproximacao, pois nao da pra prever quando um grupo
        // sera concluido.
        // Por isso excluimos 1/2 das viagens que comecam na terca e 1/2 das viagens que comecam na quarta.
        // No total isso seria ~excluir 1 dia dos 7
        this.walletsData.marketplace.data.gmvProxRepasse = extrato.total_gmv * (1 - 0.14);
      }).finally(() => { this.walletsData.marketplace.loadingProxRepasse = false; });
    },
    requestWalletExtract(days) {
      this.isLoading = true;
      return CompanyServices.extratoCarteira(days)
        .then((result) => {
          this.parseWalletExtractsPayload(result);
          this.howManyDaysFilter = days;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    parseWalletExtractsPayload(result) {
      const saldoSacavel = this.sacavel(result);
      const {
        frete,
        dias_parados: diasParados,
        bonus,
        pedagio,
        emprestimo,
        saldo,
      } = result;

      this.walletsData.frete.saldo = saldo?.saldo_frete;
      this.walletsData.frete.data = frete;
      this.walletsData.frete.saqueBloqueado = saldo.bloqueado;
      this.walletsData.frete.motivoSaqueBloqueado = saldo.motivo_bloqueio;
      this.walletsData.frete.saldoSacavel = saldoSacavel.saldo_frete;

      this.walletsData.dias_parados.saldo = saldo?.saldo_dias_parados;
      this.walletsData.dias_parados.data = diasParados;
      this.walletsData.dias_parados.saqueBloqueado = saldo.bloqueado;
      this.walletsData.dias_parados.motivoSaqueBloqueado = saldo.motivo_bloqueio;
      this.walletsData.dias_parados.saldoSacavel = saldoSacavel.saldo_dias_parados;

      this.walletsData.bonus.saldo = saldo?.saldo_bonus;
      this.walletsData.bonus.data = bonus;
      this.walletsData.bonus.saqueBloqueado = saldo.bloqueado;
      this.walletsData.bonus.motivoSaqueBloqueado = saldo.motivo_bloqueio;
      this.walletsData.bonus.saldoSacavel = saldoSacavel.saldo_bonus;

      this.walletsData.pedagio.saldo = saldo?.saldo_pedagio;
      this.walletsData.pedagio.data = pedagio;
      this.walletsData.pedagio.saqueBloqueado = saldo.bloqueado;
      this.walletsData.pedagio.motivoSaqueBloqueado = saldo.motivo_bloqueio;
      this.walletsData.pedagio.saldoSacavel = saldoSacavel.saldo_pedagio;

      this.walletsData.emprestimo.saldo = saldo?.saldo_emprestimo;
      this.walletsData.emprestimo.data = emprestimo;
      this.walletsData.emprestimo.saqueBloqueado = saldo.bloqueado;
      this.walletsData.emprestimo.motivoSaqueBloqueado = saldo.motivo_bloqueio;
      this.walletsData.emprestimo.saldoSacavel = saldo?.saldo_emprestimo;
    },
    async requestGetFinancialPenaltiesByMechanicalFailureFromLastWeek() {
      this.isLoadingFinancialPenalties = true;
      return CompanyServices.getFinancialPenaltiesByMechanicalFailureFromLastWeek()
        .then((data) => {
          this.financialPenalties = data.multas;
        })
        .finally(() => {
          this.isLoadingFinancialPenalties = false;
        });
    },
    sacavel (result) {
      const { saldo } = createCopy(result);
      let { saldo_locadora, saldo_bonus, saldo_dias_parados, saldo_pedagio, saldo_frete } = saldo
      while (saldo_locadora < 0) {
        if (saldo_bonus > 0) {
          saldo_bonus = Math.max(saldo_bonus + saldo_locadora, 0)
          saldo_locadora += result.saldo.saldo_bonus
        } else if (saldo_dias_parados > 0) {
          saldo_dias_parados = Math.max(saldo_dias_parados + saldo_locadora, 0)
          saldo_locadora += result.saldo.saldo_dias_parados
        } else if (saldo_pedagio > 0) {
          saldo_pedagio = Math.max(saldo_pedagio + saldo_locadora, 0)
          saldo_locadora += result.saldo.saldo_pedagio
        } else {
          saldo_frete += saldo_locadora
          saldo_locadora = 0
        }
      }
      return { saldo_bonus, saldo_dias_parados, saldo_frete, saldo_pedagio }
    }
  },
});
