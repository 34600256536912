<template>
  <ul>
    <AppHeaderSectionActionButton
      v-for="(element, index) in menuItems"
      :key="index"
      :href="element.url"
      :items="element.items"
      :tag="element.url ? 'router-link' : 'button'"
    >
      {{ element.text }}
    </AppHeaderSectionActionButton>
  </ul>
</template>

<script setup>
import { getMenuItem } from '@components/app/section-menu-items';
import AppHeaderSectionActionButton from './app-header-section-action-button.vue';

const menuItems = getMenuItem();
</script>
