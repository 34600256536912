import { CompanyAPI } from '@api/buser-http-client';

const validateInvoice = async (groupId, invoiceFile) => {
  const formData = new FormData();

  if (!invoiceFile?.name) throw new Error('Sem arquivo');

  formData.append('invoice', invoiceFile, invoiceFile.name);
  formData.append('groupId', groupId);

  return CompanyAPI.validateInvoice(formData)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { validateInvoice };
