<template>
  <main class="pink-empty-layout">
    <slot />
  </main>
</template>

<style>
.pink-empty-layout {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-pink-scale-700);
}
</style>
