import { useSession } from '@stores';
import { CompanyServices } from '@services/company-services';
import { TermsAndConditionsPageName } from '@assets/constants/route-names';
import dayjs from 'dayjs';

const partnerTermsMiddleware = async (to, _from, next) => {
  const $session = useSession();

  const now = dayjs();
  const lastTimeSeenTerms = localStorage.getItem('lastTimeSeenTerms');

  if (!$session.isAuthenticated) return next();

  if (lastTimeSeenTerms) {
    const lastTimeSeenTermsDate = dayjs(lastTimeSeenTerms);
    if (now.diff(lastTimeSeenTermsDate, 'hours') < 24) return next();
  }

  const hasPendingTermAcceptances = await CompanyServices.checkPendingTerms();
  localStorage.setItem('lastTimeSeenTerms', dayjs());

  if (hasPendingTermAcceptances) return next({ name: TermsAndConditionsPageName });

  return next();
};

export { partnerTermsMiddleware };
