<template>
  <li
    v-click-outside="handleClickOutside"
    class="app-header-section-action-button__wrapper"
  >
    <component
      :is="$props.tag"
      :to="{ path: $props.href }"
      class="app-header-section-action-button animation-ripple"
      tabindex="0"
      @click="handleOpenMenu"
    >
      <slot />

      <b-icon
        v-if="$props.items"
        icon="keyboard_arrow_down"
      />
    </component>

    <b-menu-container
      v-if="$props.items"
      class="app-header-section-action-button__menu"
      :is-open="$state.menuIsOpen"
      position="bottom-center"
      @click="$state.menuIsOpen = false"
    >
      <b-menu-item
        v-for="(item, index) in $props.items"
        :key="index"
        class="app-header-section-action-button__menu-item"
        @click="handleOpenLink(item.url)"
      >
        <b-icon
          :icon="item.icon"
          class="app-header-section-action-button__menu-icon"
        />
        <b-text nowrap>
          {{ item.text }}
        </b-text>
      </b-menu-item>
    </b-menu-container>
  </li>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

const $router = useRouter();

const $props = defineProps({
  items: {
    type: Array,
    default: null,
  },
  tag: {
    type: String,
    default: 'a',
  },
  href: {
    type: String,
    default: null,
  },
});

const $state = reactive({
  menuIsOpen: false,
});

const handleOpenMenu = () => {
  if ($props.items) {
    $state.menuIsOpen = !$state.menuIsOpen;
  }
};

const handleOpenLink = (url) => {
  $router.push({ path: url });
};

const handleClickOutside = () => {
  $state.menuIsOpen = false;
};
</script>

<style lang="scss">
.app-header-section-action-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-scalable-small) var(--size-scalable-medium);
  cursor: pointer;
  font-size: var(--size-progressive-extra-small);
  font-weight: 600;
  color: var(--color-white);
  width: fit-content;
  text-decoration: none;
  position: relative;
  transition: color 0.125ms;
  text-transform: uppercase;

  &:hover {
    background-color: var(--color-pink-scale-200);
  }

  &:visited,
  &:link {
    text-decoration: none;
  }
}

.app-header-section-action-button__wrapper {
  position: relative;
}

.app-header-section-action-button--ripple {
  transform: translate3d(0, 0, 0);
}

.app-header-section-action-button.router-link-active {
  background-color: var(--color-pink-scale-200);
}

.app-header-section-action-button__menu {
  color: var(--color-grey);
}

.app-header-section-action-button__menu-item {
  white-space: nowrap;
}

.app-header-section-action-button__menu-icon {
  margin-right: var(--size-scalable-large);
}
</style>
