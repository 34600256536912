import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelAuthorizationPendenciesModal = defineStore('TravelAuthorizationPendenciesModal', {
  state: () => ({
    isModalOpen: false,
    numNeededDriver: null,
    group: null,
  }),
  getters: {
    pendencyText(state) {
      if (state.numNeededDriver === 2) return 'Escale o ônibus e os motoristas antes de gerar a autorização';
      if (state.numNeededDriver === 1) return 'Escale o ônibus e o motorista antes de gerar a autorização';
      if (state.numNeededDriver === 0) return 'Escale o ônibus antes de gerar a autorização';

      return 'Está faltando escalar Motorista e/ou Ônibus';
    },
  },
  actions: {
    open(group, numNeededDriver) {
      this.group = group;
      this.numNeededDriver = numNeededDriver;
      this.isModalOpen = true;

      EventLogger.logEvent(events.authorizationPendenciesModalOpen, {
        groupId: group?.id,
      });
    },
    close() {
      this.group = null;
      this.numNeededDriver = null;
      this.isModalOpen = false;
    },
  },
});
