import { CompanyServices } from '@services/company-services';
import { CompanyAPI } from '@api/buser-http-client';
import { createCopy } from '@utils/object/create-copy';
import { defineStore } from 'pinia';
import { needSecondDriverANTT } from '@utils/groups/need-second-driver-antt';
import { EventLogger } from '@utils/event-metrics';
import dayjs from 'dayjs';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useInterface } from '@stores';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelDriversModal = defineStore('travelDriversModal', {
  state: () => ({
    interfaceState: useInterface(),
    isModalOpen: false,
    isLoading: false,
    group: null,
    drivers: null,
    driverOne: null,
    driverTwo: null,
    tiredDrivers: null,
    duplicatedTravelDrivers: null,
    notApprovedDrivers: null,
    driverOneRules: {
      startValidating: true,
      type: Object,
      required: true,
    },
    driverTwoRules: {
      startValidating: true,
      type: Object,
      required: true,
    },
    isInvalidForm: null,
  }),
  getters: {
    cantChangeDriver(state) {
      if (!state.group?.datetime_ida) return undefined;
      const tenMinutesAgo = dayjs().subtract(10, 'minutes');
      const groupStartTime = dayjs(state.group?.datetime_ida);
      return groupStartTime.isBefore(tenMinutesAgo);
    },
    getTiredDrivers(state) {
      if (!state.tiredDrivers?.length || !state.drivers?.length) return [];

      const tiredDrivers = [];
      if (state.tiredDrivers.includes(state.driverOne?.id)) {
        tiredDrivers.push(state.driverOne);
      }
      if (state.tiredDrivers.includes(state.driverTwo?.id)) {
        tiredDrivers.push(state.driverTwo);
      }
      return tiredDrivers;
    },
    getDuplicatedTravelDrivers(state) {
      if (!state.duplicatedTravelDrivers?.length || !state.drivers?.length) { return []; }

      const duplicatedTravelDrivers = [];
      if (state.duplicatedTravelDrivers.includes(state.driverOne?.id)) {
        duplicatedTravelDrivers.push(state.driverOne);
      }
      if (state.duplicatedTravelDrivers.includes(state.driverTwo?.id)) {
        duplicatedTravelDrivers.push(state.driverTwo);
      }
      return duplicatedTravelDrivers;
    },
    getNotApprovedDrivers(state) {
      if (!state.notApprovedDrivers?.length || !state.drivers?.length) { return []; }

      const notApprovedDrivers = [];
      if (state.notApprovedDrivers.includes(state.driverOne?.id)) {
        notApprovedDrivers.push(state.driverOne);
      }
      if (state.notApprovedDrivers.includes(state.driverTwo?.id)) {
        notApprovedDrivers.push(state.driverTwo);
      }
      return notApprovedDrivers;
    },
    isOneDriverSelected(state) {
      return state.driverOne?.id;
    },
    isSetDriverButtonDisabled(state) {
      return (
        state.tiredDrivers?.length > 0
        || state.duplicatedTravelDrivers?.length > 0
        || state.notApprovedDrivers?.length > 0
        || state.isInvalidForm
      );
    },
    ellegibleFirstDrivers(state) {
      return state.drivers;
    },
    ellegibleSecondDrivers(state) {
      if (!state.drivers?.length) {
        return [];
      }
      const canBeSecondDriver = ({ id }) => (!needSecondDriverANTT(state.group) || !!id)
        && state.driverOne?.id !== id;

      return state.drivers.filter(canBeSecondDriver);
    },
    oneDriverException(state) {
      return state.group?.one_driver_exception;
    },
    twoDriversRequired(state) {
      return needSecondDriverANTT(state.group) && !this.oneDriverException;
    },
  },
  actions: {
    async checkDriversAvaliability() {
      if (!this.driverOne?.id && this.driverTwo?.id) {
        this.driverOne = this.driverTwo;
        this.driverTwo = null;
      }

      if (this.driverOne?.id === this.driverTwo?.id) {
        this.driverTwo = null;
      }

      if (!this.driverOne?.id) {
        this.tiredDrivers = [];
        this.duplicatedTravelDrivers = [];
        this.notApprovedDrivers = [];
        return;
      }

      CompanyServices.checkDriversAvaliability(
        this.driverOne?.id,
        this.driverTwo?.id,
        this.group.id,
      ).then((result) => {
        this.tiredDrivers = result.tired_drivers || [];
        this.duplicatedTravelDrivers = result.duplicated_travel_drivers || [];
        this.notApprovedDrivers = result.not_approved_drivers || [];
      });
    },
    close() {
      this.isModalOpen = false;
      this.group = null;
    },
    open(group) {
      this.group = createCopy(group);
      this.driverOne = group.driver_one;
      this.driverTwo = group.driver_two;
      this.isModalOpen = true;
    },
    async requestDrivers() {
      return CompanyAPI.getCompanyDrivers()
        .then((result = []) => {
          result.unshift({ id: null, name: 'A definir' });
          this.drivers = result;
        })
        .catch(({ error = 'Error ao listar motoristas' }) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        });
    },
    async requestSetGroupDrivers() {
      this.isLoading = true;

      CompanyServices.setGroupDrivers(
        this.group.id,
        this.driverOne?.id,
        this.driverTwo?.id,
      )
        .then(() => {
          this.isModalOpen = false;

          this.interfaceState.toast.add({
            label: 'Motorista(s) escalado(s) com sucesso!',
            color: 'success',
          });

          const TravelsPageStore = useTravelsPage();
          TravelsPageStore.requestGroups();
        })
        .catch(({ error }) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
