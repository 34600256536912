/* eslint-disable no-underscore-dangle */
import { buserHttpClient } from './buser-http-client';

const getAllTerms = () => buserHttpClient.get('/v1/company/terms');

const checkPendingTerms = (params) => buserHttpClient.get('/v1/company/terms/pending', params);

const acceptTerm = (params) => buserHttpClient.post('/v1/company/terms/accept', params);

const getCompanyCourseLink = () => buserHttpClient.post('/company/get_course_link').then(({ data }) => data).catch((error) => Promise.reject(error.response));

const getCompanyContract = () => buserHttpClient.post('/company/contrato');

const updateCompanyInfo = (params) => buserHttpClient.patch('/v1/company/update_company', params);

const updateCompanyStateDocument = (params) => buserHttpClient.post(
  '/v1/company/update/documento_estadual',
  params,
);

const updateCompanyCertificate = (params) => buserHttpClient.post(
  '/v1/company/update_company/certificate',
  params,
  { headers: { 'Content-Type': 'multipart/form-data' } },
);

const getCities = (params) => buserHttpClient.get('/v1/company/cities', { params });

const getCompanyManagers = () => buserHttpClient.get('/v1/company/managers').then(({ data }) => data).catch((error) => Promise.reject(error.response));

const getCompanyNotifications = () => buserHttpClient.get('/v1/company/avisos');

const getCompanyOverview = () => buserHttpClient.get('/v1/company/overview');

const setCompanyNotificationsToReaded = (params) => buserHttpClient.post('/v1/company/aviso/ler', params);

const getBusesGrades = () => buserHttpClient.get('/v1/company/grades/bus');

const getDriversGrades = () => buserHttpClient.get('/v1/company/grades/driver');

const getGroupsGrades = (params) => buserHttpClient.get('/v1/company/grades/groups', { params });

const getCompanyBuses = (params) => buserHttpClient.get('/v1/company/bus', { params });

const getCompanyBus = (params) => buserHttpClient.get('/v1/company/bus/show', { params });

const removeBus = (params) => buserHttpClient.post('/v1/company/bus/remove', params);

const getRoutesFromBus = (params) => buserHttpClient.get('/v1/company/bus/routes', { params });

const saveBus = (params) => buserHttpClient.post(
  '/v1/company/bus/save',
  params,
  { headers: { 'Content-Type': `multipart/form-data; boundary=${params._boundary}` } },
);

const updateBus = (params) => buserHttpClient.post(
  '/v1/company/bus/edit',
  params,
  { headers: { 'Content-Type': `multipart/form-data; boundary=${params._boundary}` } },
);

const createBus = (params) => buserHttpClient.post(
  '/v1/company/bus/create',
  params,
  { headers: { 'Content-Type': `multipart/form-data; boundary=${params._boundary}` } },
);

const getGroupsList = (params) => buserHttpClient.get('/v1/company/groups', { params });

const setGroupBus = (params) => (buserHttpClient.post('/v1/company/groups/set_bus', params));

const calculateFreightReduction = (params) => buserHttpClient.post(
  '/v1/company/groups/calculate_freight_reduction',
  params,
);

const askSetGroupBus = (params) => (buserHttpClient.post('/v1/company/groups/ask_set_bus', params));

const setGroupDrivers = (params) => buserHttpClient.post(
  '/v1/company/groups/set_driver',
  params,
);

const updateUser = (params) => buserHttpClient.post('/v1/company/user/update', params);

const getCompanyMonthInvoices = (params) => buserHttpClient.get('/company/consulta_notas_mes', { params });

const getCompanyInvoicesDownloadLinkByMonth = (params) => buserHttpClient.get('/company/link_notas_mes', { params });

const acceptOrRejectLending = (params) => buserHttpClient
  .patch('v1/company/lending/request', params);

const getCompanyDrivers = (detailed = false) => buserHttpClient.get('/v1/company/drivers', { params: { detailed } })
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response));

const removeDriverPermission = (params) => buserHttpClient.post('/v1/company/drivers/remove', params);

const getRoutesFromDriver = (params) => buserHttpClient.get('/v1/company/drivers/future_routes', { params });

const getDriverById = (params) => buserHttpClient.get('/v1/company/drivers/show', { params });

const getConfiguracaoPagamento = () => buserHttpClient.get('v1/company/groups/configuracao_pagamento');

const getAntecipacaoPagamento = (params) => buserHttpClient.get('v1/company/groups/antecipacao_pagamento', { params });

const solicitarAntecipacaoPagamento = (params) => buserHttpClient.post(
  'v1/company/groups/solicitar_antecipacao_pagamento',
  params,
);

const getExtratoAntecipacaoPagamento = (params) => buserHttpClient.get('v1/company/groups/extrato_antecipacao_pagamento', { params });


const saveDriver = (params) => buserHttpClient.post(
  '/v1/company/drivers/save',
  params,
  { headers: { 'Content-Type': `multipart/form-data; boundary=${params._boundary}` } },
);

const getLendings = (params) => buserHttpClient.get('/v1/company/lending', { params });

const extratoCarteira = (params) => buserHttpClient.get('/v1/company/carteira/extrato', { params });

const getSaldoFuturoMarketplace = (params) => buserHttpClient.get(
  '/v1/company/carteira/marketplace/saldo_futuro',
  { params },
);

const getNovoRelatorioFinanceiroMarketplace = (params) => buserHttpClient.get(
  '/v1/company/repasse/marketplace/pax/relatorio/novo',
  { params },
);

const getDataConfiguracaoPagamentoRecente = () => buserHttpClient.get(
  '/v1/company/repasse/marketplace/get_data_configuracao_pagamento_recente',
  { },
);

const getBankAccount = () => buserHttpClient.get('/v1/company/bank_account');

const saque = (params) => buserHttpClient.post('/v1/company/carteira/saque', params);

const acceptContract = () => buserHttpClient.post('/v1/company/contract/accept');

const getContract = () => buserHttpClient.get('/v1/company/contract');

const getGroupRouteLegClasses = (params) => buserHttpClient.get('/v1/company/groups/trechos_classes', { params });

const getGroupPrices = (params) => buserHttpClient.get('/v1/company/groups/prices', { params });

const checkDriversAvaliability = (params) => buserHttpClient.get(
  '/v1/company/drivers/check_avaliability',
  { params },
);

const checkDriversAvaliabilityNew = (params) => buserHttpClient.get(
  '/v1/company/drivers/availability',
  { params },
);

const generateArtesp = (params) => buserHttpClient.post('/v1/company/groups/authorization/artesp/generate', params);

const listAnttDrivers = (params) => buserHttpClient.get('/v1/company/groups/authorization/antt/drivers', { params });

const generateAntt = (params) => buserHttpClient.post('/v1/company/groups/authorization/antt/generate', params);

const printAntt = (params) => buserHttpClient.post('/v1/company/groups/authorization/antt/print', params);

const manualGroupAuthorization = (params) => buserHttpClient.post(
  '/v1/company/groups/authorization/manual/upload',
  params,
  { headers: { 'Content-Type': `multipart/form-data; boundary=${params._boundary}` } },
);

const updateGroupClasses = (params) => buserHttpClient.patch('/v1/company/groups/classes', params);

const updateGroupPrices = (params) => buserHttpClient.patch('/v1/company/groups/prices/update', params);

const getGroupPricesLogs = (params) => buserHttpClient.get('/v1/company/groups/prices/logs', { params });

const getBPES = (params) => buserHttpClient.get('/v1/company/groups/bpes', { params });

const getSuggestedICMS = (params) => buserHttpClient.get('/v1/company/groups/cteos/get_suggested_icms', { params });

const automaticInvoice = (params) => buserHttpClient.post('/v1/company/groups/cteos/automatic', params);

const simulateInvoiceCancelation = (params) => buserHttpClient.get(
  '/v1/company/groups/cteos/cancel_simulation',
  { params },
);

const validateInvoice = (params) => buserHttpClient.post(
  '/v1/company/groups/cteos/validate',
  params,
  { headers: { 'Content-Type': 'multipart/form-data' } },
);

const uploadInvoice = (params) => buserHttpClient.post(
  '/v1/company/groups/cteos/upload',
  params,
  { headers: { 'Content-Type': 'multipart/form-data' } },
);

const getDriverGroups = (params) => buserHttpClient.get(
  '/v1/company/driver/get-groups',
  { params },
);

const dermgLogin = (params) => buserHttpClient.post('/v1/company/groups/authorization/dermg/login', params);

const getGroupsInverseRoute = (params) => buserHttpClient.get('/v1/company/groups/inverse_route', { params });

const dermgGenerate = (params) => buserHttpClient.post('/v1/company/groups/authorization/dermg/generate', params);

const getCompanyTollsInfo = () => buserHttpClient.get('/v1/company/tolls-info');

const getDebitNote = (params) => buserHttpClient.get('/company/pedagios/nota_debito', { params, responseType: 'blob' });

const getFinancialPenaltiesByMechanicalFailureFromLastWeek = () => buserHttpClient
  .get('/v1/company/financial/penalties');

const getMultaList = (params) => buserHttpClient.get('/v1/company/multas', params);

const getMulta = (multaId) => buserHttpClient.get(`/v1/company/multas/${multaId}`);

const createContestacao = (params) => buserHttpClient.post(
  '/v1/company/multas/contestacoes/create',
  params,
  { headers: { 'Content-Type': 'multipart/form-data' } },
);

const getLinkDashboardPagamento = async () => buserHttpClient.get('/v1/company/carteira/dashboard')
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response));

const CompanyAPI = {
  acceptTerm,
  getAllTerms,
  getBusesGrades,
  getCities,
  getCompanyBuses,
  getCompanyBus,
  getCompanyContract,
  getCompanyCourseLink,
  getCompanyManagers,
  getCompanyNotifications,
  getCompanyOverview,
  getDriversGrades,
  getGroupsList,
  getGroupsGrades,
  getMultaList,
  getMulta,
  checkPendingTerms,
  getGroupsInverseRoute,
  setCompanyNotificationsToReaded,
  updateCompanyCertificate,
  updateCompanyInfo,
  updateCompanyStateDocument,
  removeBus,
  saveBus,
  createBus,
  updateBus,
  getRoutesFromBus,
  setGroupBus,
  calculateFreightReduction,
  askSetGroupBus,
  setGroupDrivers,
  updateUser,
  getCompanyMonthInvoices,
  acceptOrRejectLending,
  getCompanyInvoicesDownloadLinkByMonth,
  getCompanyDrivers,
  removeDriverPermission,
  getConfiguracaoPagamento,
  getAntecipacaoPagamento,
  solicitarAntecipacaoPagamento,
  getExtratoAntecipacaoPagamento,
  getRoutesFromDriver,
  getDriverById,
  saveDriver,
  getLendings,
  extratoCarteira,
  getSaldoFuturoMarketplace,
  getNovoRelatorioFinanceiroMarketplace,
  getDataConfiguracaoPagamentoRecente,
  getBankAccount,
  saque,
  acceptContract,
  getContract,
  generateArtesp,
  listAnttDrivers,
  generateAntt,
  printAntt,
  dermgLogin,
  manualGroupAuthorization,
  checkDriversAvaliability,
  checkDriversAvaliabilityNew,
  updateGroupClasses,
  updateGroupPrices,
  getGroupRouteLegClasses,
  getBPES,
  getGroupPrices,
  getGroupPricesLogs,
  getSuggestedICMS,
  automaticInvoice,
  simulateInvoiceCancelation,
  validateInvoice,
  uploadInvoice,
  getDriverGroups,
  dermgGenerate,
  getCompanyTollsInfo,
  getDebitNote,
  getFinancialPenaltiesByMechanicalFailureFromLastWeek,
  createContestacao,
  getLinkDashboardPagamento,
};

export { CompanyAPI };
