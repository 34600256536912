import { currentUser } from './current-user';
import { getUserCompany } from './get-user-company';
import { findUserByEmailOrPhone } from './find-user-by-email-or-phone';
import { forgotPassword } from './forgot-password';
import { signIn } from './sign-in';
import { signInWithGoogle } from './sign-in-with-google';
import { signOut } from './sign-out';
import { signUp } from './sign-up';

const AuthServices = {
  currentUser,
  getUserCompany,
  findUserByEmailOrPhone,
  forgotPassword,
  signIn,
  signInWithGoogle,
  signOut,
  signUp,
};

export { AuthServices };
