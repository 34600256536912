<template>
  <div class="app-header-section-session-menu-button">
    <b-divider />

    <b-menu-item :clickable="false">
      <b-text
        uppercase
        emphasis="medium"
        nowrap
      >
        {{ $props.text }}
      </b-text>
    </b-menu-item>

    <b-menu-item
      v-for="(element, index) in items"
      :key="index"
      class="app-header-section-session-menu-button__item"
      @click="handleAllHandlers(element)"
    >
      <b-icon
        :icon="element.icon"
        size="large"
      />

      <b-text
        class="app-header-section-session-menu-button__description"
        nowrap
      >
        {{ element.text }}
      </b-text>
    </b-menu-item>
  </div>
</template>

<script setup>
import { useInterface } from '@stores';

const $interfaceState = useInterface();

const $props = defineProps({
  text: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
});

const handleAllHandlers = (element) => {
  element.handler().catch((error) => {
    if (error.data.error === 'Usuário não possui CPF!') {
      $interfaceState.$modal.userCpfUpdate.isOpen = true;
    }
  });
};
</script>

<style lang="scss">
.app-header-section-session-menu-button__item {
  white-space: nowrap;
}

.app-header-section-session-menu-button__description {
  margin-left: var(--size-scalable-large);
}
</style>
