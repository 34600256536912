<template>
  <div
    class="mdc-select__menu mdc-menu mdc-menu-surface b-select__menu mdc-menu-surface--open"
  >
    <ul
      class="mdc-list b-select__menu-list"
      role="listbox"
      :aria-label="$props.ariaDescription"
    >
      <li
        v-for="(item, index) in $props.items"
        :key="index"
        :class="{
          'mdc-list-item': true,
          'mdc-list-item--disabled': item?.isDisabled,
          'mdc-list-item--selected': checkItemIsSelected(item),
          'mdc-list-item--arrow-focused': $props.currentListIndex == index
        }"
        :aria-selected="checkItemIsSelected(item) ? 'true' :'false'"
        :aria-disabled="item?.isDisabled"
        :data-value="getValue(item, $props.keyedBy)"
        role="option"
        @click="$emit('click', {item, index})"
      >
        <span class="mdc-list-item__ripple" />
        <span class="mdc-list-item__text">
          {{ getValue(item, $props.labelledBy) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { getValue } from '@utils/object/get-value';

const $emit = defineEmits(['click']);

const $props = defineProps({
  currentListIndex: {
    type: Number,
    default: undefined,
  },
  items: {
    type: Array,
    default: undefined,
  },
  ariaDescription: {
    type: String,
    default: 'Teste',
  },
  internalValue: {
    type: [String, Object],
    default: undefined,
  },
  labelledBy: {
    type: String,
    default: undefined,
  },
  keyedBy: {
    type: String,
    default: undefined,
  },
});

const checkItemIsSelected = (item) => (
  (item?.value && item?.value === $props.internalValue?.value)
  || item === $props.internalValue);

</script>

<style lang="scss" >
.b-select__menu {
  z-index: 3000 !important;
}
</style>
