import { CompanyAPI } from '@api';

const getNovoRelatorioFinanceiroMarketplace = (params) => CompanyAPI
  .getNovoRelatorioFinanceiroMarketplace(params)
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response?.data || error.response));

export { getNovoRelatorioFinanceiroMarketplace };

const getDataConfiguracaoPagamentoRecente = () => CompanyAPI
  .getDataConfiguracaoPagamentoRecente()
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response?.data || error.response));

export { getDataConfiguracaoPagamentoRecente };