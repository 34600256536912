import axios from 'axios';
import { Settings } from '@settings';
import { buserHttpClient } from '../buser-http-client';

axios.defaults.xsrfHeaderName = 'x-csrftoken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const incidentsHttpClient = axios.create({
  baseURL: `${Settings.envs.INCIDENTS_BASE_URL}`,
  timeout: 10000,
});

incidentsHttpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 403 && error?.response?.data?.code === 'csrf') {
      await incidentsHttpClient.get('/csrf');
      return incidentsHttpClient.request(error.config);
    }

    return Promise.reject(error);
  },
);

incidentsHttpClient.interceptors.response.use(
  null,
  async (error) => {
    const { config, response } = error;
    config.retries = config.retries || 0;
    const MAX_RETRIES = 3;

    if ((response.status === 401 || response.status === 403) && config.retries < MAX_RETRIES) {
      config.retries += 1;
      await buserHttpClient.get('ensure-auth-token');
      return incidentsHttpClient.request(config);
    }

    return Promise.reject(error);
  },
);

export { incidentsHttpClient };
