<template>
  <b-input
    ref="$element"
    v-click-outside="clickOutside"
    :is-invalid="$validationState.isInvalid"
    :input-id="$state.inputId"
    :input-field-id="$state.inputFieldId"
    :value="$props.text"
    browser-autocomplete="off"
    :autocomplete="true"
    :autocomplete-selected="$props.selected"
    :helper-text="$validationState.internalHelperText"
    class="b-autocomplete"
    :class="{
      'b-autocomplete--column-chips': $props.chips && $props.chipsInColumn && $props.multiselect,
    }"
    :is-floating="$state.isFloating"
    :name="$props.name"
    :label="$props.label"
    :icon-right="$props.clearButton && $state.hasSelectedItem ? 'close' : undefined"
    @click-icon-right="handleClickIconRight"
    @input="handleInput"
    @focus="handleFocus"
    @blur="handleBlur"
    @click="handleClick"
  >
    <div
      v-if="$props.chips && $props.multiselect && $props.selected?.length"
      class="b-autocomplete__chips-wrapper"
      @mousedown.prevent
      @click.stop
    >
      <template
        v-for="(selectedItem, index) in $props.selected"
        :key="index"
      >
        <b-chip
          v-if="!isEmptyObject(selectedItem)"
          :is-disabled="selectedItem?.[$props?.disabledBy] === true"
          size="small"
          icon-right="cancel"
          class="b-autocomplete__chip"
          @click-icon-right="handleRemoveSelectedItem(selectedItem, index)"
        >
          {{ selectedItem?.[$props.labelledBy] }}
        </b-chip>
      </template>
    </div>

    <template #append>
      <teleport to="body">
        <b-menu-body
          v-if="$state.hasMenu"
          v-model="$state.isActive"
          :style="updateStyle(style)"
          @click.stop="clickInOptions"
          @mousedown.prevent
        >
          <b-menu-item
            v-for="(item, index) in $state.parsedItems"
            :key="index"
            :clickable="!item[$props.disabledBy] || item[$props.disabledBy] === false"
            @click="handleAddSelectedItem(item)"
          >
            <b-checkbox
              v-if="!$props.chips && $props.multiselect"
              t-o-d-o
            />
            {{ item?.[$props.labelledBy] }}
          </b-menu-item>

          <b-menu-item
            v-if="$state.allItemsSearchedIsSelected"
            :clickable="false"
          >
            <b-icon
              v-if="$props.allSelectedIcon"
              :icon="$props.allSelectedIcon"
              class="b-margin-right--small"
            />
            {{ $props.allSelectedText }}
          </b-menu-item>

          <b-menu-item
            v-else-if="$state.hasNotFoundText"
            :clickable="false"
          >
            <b-icon
              v-if="$props.notFoundIcon"
              :icon="$props.notFoundIcon"
              class="b-margin-right--small"
            />
            {{ $props.notFoundText }}
          </b-menu-item>
        </b-menu-body>
      </teleport>
    </template>
  </b-input>
</template>

<script setup>
import {
  reactive,
  computed,
  onMounted,
  watch,
  toRef,
  inject,
  ref,
} from 'vue';
import { idGenerator, useElementPosition } from '@composables/utilities';
import { isEmptyObject } from '@utils/validations/is-empty-object';
import { isEmpty } from '@utils/validations/is-empty';
import { debounce } from 'debounce';
import { Validate } from '@utils/validations/validate';
import { pascalCase } from 'change-case';
import { BFormKey } from '@components/ui/molecules/b-form';

const $element = ref();

const $props = defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: undefined,
  },
  modelValue: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  selected: {
    type: [String, Number, Array, Object],
    default: undefined,
  },
  items: {
    type: Array,
    default: undefined,
  },
  multiselect: {
    type: Boolean,
    default: true,
  },
  debounce: {
    type: Number,
    default: 1000,
  },
  labelledBy: {
    type: String,
    default: 'text',
  },
  keyedBy: {
    type: String,
    default: 'id',
  },
  searchedBy: {
    type: String,
    default: 'name',
  },
  disabledBy: {
    type: String,
    default: 'disabled',
  },
  allSelectedActive: {
    type: Boolean,
    default: true,
  },
  allSelectedText: {
    type: String,
    default: 'Todos os items foram selecionados.',
  },
  allSelectedIcon: {
    type: String,
    default: 'check_circle',
  },
  notFoundIcon: {
    type: String,
    default: 'error',
  },
  notFoundActive: {
    type: Boolean,
    default: true,
  },
  notFoundText: {
    type: String,
    default: 'Não encontrado.',
  },
  inputId: {
    type: String,
    default: undefined,
  },
  inputFieldId: {
    type: String,
    default: undefined,
  },
  startRequesting: {
    type: Boolean,
    default: false,
  },
  removeSelectedFromItems: {
    type: Boolean,
    default: true,
  },
  chips: {
    type: Boolean,
    default: true,
  },
  chipsInColumn: {
    type: Boolean,
    default: true,
  },
  internalSearch: {
    type: Boolean,
    default: false,
  },
  maxMenuHeight: {
    type: String,
    default: '240px',
  },
  rules: {
    type: [Object, Array],
    default: undefined,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  helperText: {
    type: String,
    default: undefined,
  },
  clearButton: {
    type: Boolean,
    default: false,
  },
  sameWidthInMenu: {
    type: Boolean,
    default: true,
  },
  autoUpdateTextWhenSelect: {
    type: Boolean,
    default: true,
  },
});

const $emit = defineEmits([
  'update:modelValue',
  'update:text',
  'update:selected',
  'input',
  'select',
  'remove',
  'request',
  'clear',
]);

const compareItems = (element, selectedElement) => element?.[$props.keyedBy] === selectedElement?.[$props.keyedBy];

const $state = reactive({
  nameFromProp: computed(() => $props.name || $props.label),
  name: computed(() => pascalCase($state.nameFromProp)),
  debounce: debounce(() => {
    $emit('request', $props.text);
  }, $props.debounce),
  isActive: false,
  sameSurfaceWidth: $props.sameWidthInMenu,
  parsedItems: computed(() => {
    let result = $props.items;

    if (result?.length && ($props.removeSelectedFromItems || $props.internalSearch)) {
      result = result?.filter((element) => {
        let isOk = true;

        if (isOk && $props.internalSearch && $props.text?.length >= 1) {
          isOk = element?.[$props.searchedBy]?.toLowerCase()?.includes($props.text?.toLowerCase());
        }

        if (isOk && $props.removeSelectedFromItems && $props.chips) {
          if ($props.selected?.length) {
            isOk = !$props?.selected?.find((selectedElement) => compareItems(element, selectedElement));
          }
          if ($props.selected?.[$props.keyedBy]) {
            isOk = !compareItems($props?.selected, element);
          }
        }

        return isOk;
      });
    }

    return result;
  }),
  allItemsSearchedIsSelected: computed(() => {
    if (
      !$props.allSelectedActive
        || !($props.chips)
        || !($props.items?.length >= 1)
        || !($state.parsedItems?.length === 0)
    ) return false;

    if ($props.selected?.length >= 1) {
      const result = $state?.parsedItems?.every(
        (element) => Boolean($props?.selected?.find((selectedElement) => compareItems(element, selectedElement))),
      );

      return result;
    }
    if ($props.selected?.[$props.keyedBy]) {
      const result = $state?.parsedItems?.every(
        (element) => compareItems($props?.selected, element),
      );

      return result;
    }

    return false;
  }),
  hasSelectedItem: computed(() => !isEmpty($props.selected) && !isEmptyObject($props.selected)),
  hasMenu: computed(() => $state.parsedItems?.length || $props.notFoundActive),
  hasNotFoundText: computed(() => $props.notFoundActive && !$state.parsedItems?.length),
  inputId: computed(() => $props.inputId || idGenerator('autocomplete')),
  inputFieldId: computed(() => $props.inputFieldId || idGenerator('autocomplete-field')),
  isFloating: computed(() => $state.isActive || Boolean($props.selected?.length)),
});

const $validationState = reactive({
  isInvalid: false,
  validationMessages: null,
  internalInvalid: computed(() => $props.isInvalid || $validationState.isInvalid),
  internalHelperText: computed(() => ($validationState.validationMessages?.[0]?.message || $props.helperText)),
  currentValidation: computed(() => ({
    name: $state.name,
    value: $props.selected,
    tag: 'autocomplete',
    type: computed(() => ($props.multiselect ? 'multiselect' : 'simple')),
    messages: $validationState.validationMessages,
  })),
});

const { style } = useElementPosition($element, $state);

const updateStyle = (absoluteStyle) => (
  {
    ...absoluteStyle,
    maxHeight: $props.maxMenuHeight,
    zIndex: 1500,
  }
);

const handleClick = () => {
  if (!$state.isActive) $state.isActive = true;
};

const clickInOptions = () => {
  if (!$props.multiselect) $state.isActive = false;
};

const handleInput = ($event) => {
  $emit('input', $event);
  $emit('update:text', $event);
  if (!$state.isActive) $state.isActive = true;
  if (!$props.internalSearch) $state.debounce();
};

const handleBlur = () => {
  $state.isActive = false;
};

const createSelectedListCopy = () => ($props.selected || [])?.map((element) => element);

const handleRemoveSelectedItem = (item, itemIndex) => {
  let newValue;
  if ($props.multiselect) {
    const selectedItems = createSelectedListCopy();
    selectedItems.splice(itemIndex, 1);
    newValue = selectedItems;
  } else {
    newValue = item;
  }

  $emit('remove', item);
  $emit('update:selected', newValue);
};

const handleAddSelectedItem = (item) => {
  let newValue;
  if ($props.multiselect) {
    const selectedItems = createSelectedListCopy();

    const searchIfAlreadyAdded = selectedItems
      ?.find(
        (element) => (element?.[$props.keyedBy] === item?.[$props.keyedBy]),
      );

    if (!searchIfAlreadyAdded) {
      selectedItems.push(item);
      newValue = selectedItems;
    } else {
      newValue = $props.selected;
    }
  } else if (!$props.multiselect) {
    newValue = item;
    if ($props.autoUpdateTextWhenSelect) $emit('update:text', item?.[$props.labelledBy]);
  }

  $emit('select', item);
  $emit('update:selected', newValue);
};

const handleFocus = () => {
  $state.isActive = true;
};

const clickOutside = () => {
  if ($state.isActive) $state.isActive = false;
};

const handleClickIconRight = () => {
  if ($props.clearButton && $props.selected) {
    $emit('clear');
    $emit('update:text', undefined);
    $emit('update:selected', undefined);
  }
};

const checkValidation = (toValid = false) => {
  if (!$props.rules) return;

  const result = Validate($props.selected, $props.rules, $state.nameFromProp);

  $validationState.validationMessages = result;
  $validationState.isInvalid = toValid ? Boolean($validationState.validationMessages?.length) : false;
};

const BForm = inject(BFormKey, undefined);

watch(() => $props.selected, () => checkValidation(true), { flush: 'post' });

onMounted(() => {
  if ($props.startRequesting && !$props.internalSearch) $emit('request', $props.text);

  if ($props?.rules) checkValidation($props?.rules?.startValidating);
  if (BForm) {
    const currentValidation = toRef($validationState, 'currentValidation');
    BForm.registerField(currentValidation);
  }
});

</script>

<style lang="scss">
$sizeInput: 56px;

.b-autocomplete {
  &--column-chips {
    & .mdc-text-field--filled::before {
      display: none;
    }
    & .mdc-text-field {
      display: flex;
      flex-direction: column;
    }
    & .mdc-text-field__input {
      order: 1;
    }
    & .b-autocomplete__chips-wrapper {
      order: 2;
      max-width: -webkit-fill-available;
    }
  }
  & .mdc-text-field--filled .mdc-text-field__input{
    padding-top: var(--size-scalable-small);
  }
  & .mdc-text-field--filled,
  & .mdc-text-field--outlined {
    min-height: $sizeInput;
    height: auto;
  }

  & .mdc-text-field__input {
    min-height: $sizeInput;
  }
}

.mdc-text-field .mdc-floating-label {
  top: calc($sizeInput / 2) !important;
}

.b-autocomplete__chips-wrapper {
  display: flex;
  padding-right: var(--size-scalable-small);
  padding-bottom: var(--size-scalable-extra-small);
  gap: var(--size-scalable-extra-small);
  max-width: 50%;
  width: 100%;
  flex-wrap: wrap;
}

.b-autocomplete__chip {
  white-space: wrap;
}
</style>
