const Settings = {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isHomolog: process.env.NODE_ENV === 'homolog',
  envs: {
    ENVIRONMENT: process.env.NODE_ENV,
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_VERSION: process.env.SENTRY_VERSION,
    SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    RECAPTCHA_SITE: process.env.RECAPTCHA_SITE,
    AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
    BASE_URL: process.env.BASE_URL,
    INCIDENTS_BASE_URL: process.env.INCIDENTS_BASE_URL,
  },
  getRecaptchaConfig() {
    return {
      siteKey: this.envs.RECAPTCHA_SITE,
      loaderOptions: { autoHideBadge: true },
    };
  },
};

export { Settings };
