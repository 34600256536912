<template>
  <div class="antt-login-step__header">
    <b-text weight="semi-bold">
      Dados de acesso na ANTT
    </b-text>

    <b-text>
      Não se preocupe, não armazenaremos seus dados.
    </b-text>
  </div>

  <b-form
    v-if="TravelAnttModal.group"
    v-model="TravelAnttModal.isInvalidForm"
    name="TravelAnttModal"
  >
    <div class="antt-login-step__body b-margin-top--medium">
      <b-input
        v-model="TravelAnttModal.group.onibus.placa"
        label="Placa do veículo"
        block
        :is-disabled="true"
        :rules="{
          required: true
        }"
        class="b-margin-bottom--small"
      />

      <b-input
        v-model="TravelAnttModal.anttCNPJ"
        label="CNPJ"
        mask="cnpj"
        :block="true"
        :rules="{
          required: true,
          cnpj: true
        }"
        class="b-margin-bottom--small"
      />

      <b-input
        v-model="TravelAnttModal.anttPassword"
        label="Senha ANTT"
        :rules="{
          required: true
        }"
        :type="$state.showPassword ? 'text' : 'password'"
        :icon-right="$state.showPassword ? 'visibility_off' : 'visibility'"
        block
        @click-icon-right="$state.showPassword = !$state.showPassword"
      />
    </div>

    <div class="antt-login-step__footer b-margin-top--medium">
      <b-button
        variant="text"
        @click="TravelAnttModal.close"
      >
        Cancelar
      </b-button>

      <b-button
        class="b-margin-left--small"
        :is-loading="TravelAnttModal.isListAnttDriversLoading"
        :is-disabled="TravelAnttModal.isInvalidForm"
        @click="TravelAnttModal.listAnttDrivers"
      >
        Continuar
      </b-button>
    </div>
  </b-form>
</template>

<script setup>
import { useTravelAnttModal } from '@stores';
import { reactive } from 'vue';

const $state = reactive({
  showPassword: false,
});

const TravelAnttModal = useTravelAnttModal();
</script>

<style lang="scss">
.antt-login-step__footer {
  display: flex;
  justify-content: flex-end;
}
</style>
