import { CompanyServices } from '@services/company-services';
import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { useSession } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { createCopy } from '@utils/object/create-copy';
import { useTravelInvoiceCertificateWarnModal, useTravelsPage } from '@stores/travels';

const events = EVENTS_DICTIONARY[TravelsPageName];

const parseValorFrete = (value) => value?.toLocaleString('en-US', { minimumFractionDigits: 2, useGrouping: false })
  || '0.00';

export const useTravelInvoiceAutomaticModal = defineStore('TravelInvoiceAutomaticModal', {
  state: () => ({
    fixingInvoice: false,
    isLoading: false,
    isModalOpen: false,
    error: null,
    group: null,
    company: null,
    currentStep: null,
    invoiceCancelSimulation: null,
    invoice: {
      icms: null,
      value: '0.00',
      bcReduction: 0,
    },
  }),
  actions: {
    async openAndFix(group) {
      this.fixingInvoice = true;
      await this.requestSimulateInvoiceCancelation(group);
      this.open(group);
    },
    async open(group) {
      const $session = useSession();
      this.company = $session.getUserCompany();
      this.group = createCopy(group);

      if ($session.company.hasCertificateError) {
        const TravelInvoiceCertificateWarnModal = useTravelInvoiceCertificateWarnModal();
        this.close();
        TravelInvoiceCertificateWarnModal.open();
        return;
      }

      this.invoice = {
        icms: 0,
        value: parseValorFrete(this.group.valor_frete),
        bcReduction: 0,
      };
      if ($session.company.ICMSType !== 'isento') await this.requestGetSuggestedICMS();

      this.currentStep = 'TravelInvoiceAutomaticFormStep';
      this.isModalOpen = true;
    },
    close() {
      this.isModalOpen = false;
      this.group = null;
      this.company = null;
      this.currentStep = null;
      this.invoiceCancelSimulation = null;
      this.error = null;
      this.invoice = {
        icms: null,
        value: '0.00',
        bcReduction: 0,
      };
    },
    async requestGetSuggestedICMS() {
      const itinerary = this.group?.rota.itinerario;
      const firstItinerary = itinerary.at(0);
      const lastItinerary = itinerary.at(-1);

      const originState = firstItinerary.local.uf;
      const destinyState = lastItinerary.local.uf;
      return CompanyServices.getSuggestedICMS(originState, destinyState)
        .then(({ icms }) => {
          this.invoice.icms = icms;
        });
    },
    async requestAutomaticInvoice() {
      this.isLoading = true;
      const $session = useSession();
      const TravelsPage = useTravelsPage();

      if ($session.company.isSimplesNacional) {
        this.invoice.icms = null;
      }

      return CompanyServices.automaticInvoice(this.group.id, this.invoice)
        .then(() => {
          TravelsPage.requestGroups();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async requestSimulateInvoiceCancelation(group) {
      return CompanyServices.simulateInvoiceCancelation(group.id)
        .then(({ message }) => {
          this.invoiceCancelSimulation = message;
        });
    },
  },
});
