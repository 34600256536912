import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useSession } from '@stores';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelInvoiceCertificateWarnModal = defineStore('TravelInvoiceCertificateWarnModal', {
  state: () => ({
    isModalOpen: false,
  }),
  getters: {
    certificateError() {
      if (!this.company?.datetime_expiracao_certificado) {
        return 'Certificado Digital não disponível.';
      }
      if (new Date(this.company?.datetime_expiracao_certificado) < new Date()) {
        return 'Certificado Digital vencido.';
      }
      return null;
    },
  },
  actions: {
    async open() {
      const $session = useSession();
      this.company = $session.getUserCompany();
      this.isModalOpen = true;
    },
    close() {
      this.isModalOpen = false;
    },
  },
});
