<template>
  <transition
    name="b-modal__transition"
    appear
  >
    <div
      v-if="isActive"
      :class="{
        [`b-modal--alignment-${alignment}`]: alignment,
      }"
    >
      <b-card
        elevation="024"
        class="b-modal__body"
        :class="{
          [`b-modal__body--size-${size}`]: size,
        }"
        v-bind="$attrs"
      >
        <div
          v-if="hasHeaderSlot"
          class="b-modal__header"
        >
          <slot name="header" />
        </div>

        <div class="b-modal__content">
          <slot />
        </div>

        <div
          v-if="hasFooterSlot"
          class="b-modal__footer"
        >
          <slot name="footer" />
        </div>
      </b-card>

      <div
        class="b-modal__overlayer"
        @click="handleClose"
      />
    </div>
  </transition>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isClosableByUser: {
      type: Boolean,
      default: true,
    },
    alignment: {
      type: String,
      default: 'center',
    },
    size: {
      type: String,
      default: 'large',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update:modelValue'],
  computed: {
    isActive() {
      return this.modelValue || this.isOpen;
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    handleClose() {
      if (this.isClosableByUser) {
        this.$emit('update:modelValue');
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
@import '@assets/styles/utils/breakpoints';

:root {
  --b-modal__body--max-height: 90%;
  --b-modal__overlayer--background-color: rgba(0, 0, 0, .32);
  --b-modal__footer--position: relative;
  --b-modal__footer--padding: 0;
  --b-modal__footer--background-color: var(--color-white);
  --b-modal__footer--box-shadow: none;
  --b-modal__footer--margin-top: auto;
  --b-modal__footer--margin-bottom: calc(-1 * var(--b-card--padding));
  --b-modal__footer--margin-left: calc(-1 * var(--b-card--padding));
  --b-modal__footer--margin-right: calc(-1 * var(--b-card--padding));
}

.b-modal {
  &--alignment {
    &-center {
      display: flex;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000;
      align-items: center;
      justify-content: center;
    }
    &-end {
      display: flex;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.b-modal__overlayer {
  background-color: var(--b-modal__overlayer--background-color);
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.b-modal__body {
  margin-left: var(--size-scalable-large);
  margin-bottom: var(--size-scalable-large);
  margin-right: var(--size-scalable-large);
  max-height: var(--b-modal__body--max-height);
  overflow: auto;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  padding: 0;

  &--size {
    &-large {
      width: 100%;
      max-width: 1200px;
    }
    &-medium {
      width: 100%;
      max-width: 800px;
    }
    &-small {
      width: 100%;
      max-width: 450px;
    }
    &-fullscreen {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
      --b-card--border-radius: 0;

      @include for-tablet-down {
        --b-modal__footer--padding: var(--b-card--padding);
        --b-modal__footer--box-shadow: var(--shadow-e-002);
      }
    }
  }
}

.b-modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--size-scalable-large);
  overflow: auto;
}

.b-modal__footer {
  position: var(--b-modal__footer--position);
  padding: var(--b-modal__footer--padding);
  background-color: var(--b-modal__footer--background-color);
  box-shadow: var(--b-modal__footer--box-shadow);
}

.b-modal__transition-enter-active,
.b-modal__transition-leave-active {
  transition: opacity 0.25s ease;
}

.b-modal__transition-enter-from,
.b-modal__transition-leave-to {
  opacity: 0;
}
</style>
