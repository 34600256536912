<template>
  <component
    :is="tag"
    :class="{
      [`b-card`]: true,
      [`b-card--variant-${variant}`]: variant,
      [`b-card--elevation-${elevation}`]: elevation,
      [`b-card--is-outlined`]: isOutlined,
    }"
  >
    <slot />
  </component>
</template>

<script>
import { shouldBeOneOf } from 'vue-prop-validation-helper';

export default {
  name: 'BCard',
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: shouldBeOneOf([
        'default',
        'outlined',
        'custom',
      ]),
    },
    elevation: {
      type: String,
      default: '002',
      validator: shouldBeOneOf([
        '000',
        '001',
        '002',
        '003',
        '004',
        '005',
        '006',
        '007',
        '008',
        '009',
        '010',
        '011',
        '012',
        '013',
        '014',
        '015',
        '016',
        '017',
        '018',
        '019',
        '020',
        '021',
        '022',
        '023',
        '024',
      ]),
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss">
:root {
  --b-card--background-color: transparent;
  --b-card--border: none;
  --b-card--border-color: var(--color-grey-scale-300);
  --b-card--box-shadow: none;
  --b-card--padding: var(--size-scalable-large);
  --b-card--color: inherit;
  --b-card--border-radius: var(--border-radius-normal);
  --b-card--position: relative;
  --b-card--overflow-x: auto;
}

.b-card {
  background-color: var(--b-card--background-color);
  border: var(--b-card--border);
  border-radius: var(--b-card--border-radius);
  box-shadow: var(--b-card--box-shadow);
  color: var(--b-card--color);
  padding: var(--b-card--padding);
  position: var(--b-card--position);
  overflow-x: var(--b-card--overflow-x);

  &--variant {
    &-default {
      --b-card--background-color: var(--color-white);
    }
    &-outlined {
      --b-card--border: var(--size-scalable-nano) solid var(--b-card--border-color);
      --b-card--box-shadow: none;
    }
  }

  &--elevation {
    &-000 {
      --b-card--box-shadow: var(--shadow-e-000);
    }
    &-001 {
      --b-card--box-shadow: var(--shadow-e-001);
    }
    &-002 {
      --b-card--box-shadow: var(--shadow-e-002);
    }
    &-003 {
      --b-card--box-shadow: var(--shadow-e-003);
    }
    &-004 {
      --b-card--box-shadow: var(--shadow-e-004);
    }
    &-005 {
      --b-card--box-shadow: var(--shadow-e-005);
    }
    &-006 {
      --b-card--box-shadow: var(--shadow-e-006);
    }
    &-007 {
      --b-card--box-shadow: var(--shadow-e-007);
    }
    &-008 {
      --b-card--box-shadow: var(--shadow-e-008);
    }
    &-009 {
      --b-card--box-shadow: var(--shadow-e-009);
    }
    &-010 {
      --b-card--box-shadow: var(--shadow-e-010);
    }
    &-011 {
      --b-card--box-shadow: var(--shadow-e-011);
    }
    &-012 {
      --b-card--box-shadow: var(--shadow-e-012);
    }
    &-013 {
      --b-card--box-shadow: var(--shadow-e-013);
    }
    &-014 {
      --b-card--box-shadow: var(--shadow-e-014);
    }
    &-015 {
      --b-card--box-shadow: var(--shadow-e-015);
    }
    &-016 {
      --b-card--box-shadow: var(--shadow-e-016);
    }
    &-017 {
      --b-card--box-shadow: var(--shadow-e-017);
    }
    &-018 {
      --b-card--box-shadow: var(--shadow-e-018);
    }
    &-019 {
      --b-card--box-shadow: var(--shadow-e-019);
    }
    &-020 {
      --b-card--box-shadow: var(--shadow-e-020);
    }
    &-021 {
      --b-card--box-shadow: var(--shadow-e-021);
    }
    &-022 {
      --b-card--box-shadow: var(--shadow-e-022);
    }
    &-023 {
      --b-card--box-shadow: var(--shadow-e-023);
    }
    &-024 {
      --b-card--box-shadow: var(--shadow-e-024);
    }
  }

  &--is-outlined {
    --b-card--border: var(--size-scalable-nano) solid var(--b-card--border-color);
  }
}

</style>
