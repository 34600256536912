import {
  BText,
  BButton,
  BCard,
  BInput,
  BIcon,
  BSelect,
  BLoading,
  BAvatar,
  BSuspense,
  BDivider,
  BGridContainer,
  BGridItem,
  BCheckbox,
  BFile,
  BProgressiveBar,
  BTable,
  BTrow,
  BTcell,
  BTPagination,
  BTooltip,
  BList,
  BListItem,
  BChip,
  BRadio,
} from '@components/ui/atoms';

import {
  BToast,
  BModal,
  BMenu,
  BMenuItem,
  BMenuContainer,
  BMenuBody,
  BAlert,
  BForm,
  BAutocomplete,
  BAccordion,
  BTabs,
  BTab,
  BSteps,
  BStep,
} from '@components/ui/molecules';

const BuserPartnersUIPlugin = {
  install(Vue) {
    this.installAtoms(Vue);
    this.installMolecules(Vue);
  },
  installAtoms(Vue) {
    // Atoms
    Vue.component('BButton', BButton);
    Vue.component('BCard', BCard);
    Vue.component('BText', BText);
    Vue.component('BInput', BInput);
    Vue.component('BIcon', BIcon);
    Vue.component('BSelect', BSelect);
    Vue.component('BLoading', BLoading);
    Vue.component('BAvatar', BAvatar);
    Vue.component('BSuspense', BSuspense);
    Vue.component('BDivider', BDivider);
    Vue.component('BGridContainer', BGridContainer);
    Vue.component('BGridItem', BGridItem);
    Vue.component('BRow', BGridContainer);
    Vue.component('BColumn', BGridItem);
    Vue.component('BCheckbox', BCheckbox);
    Vue.component('BFile', BFile);
    Vue.component('BProgressiveBar', BProgressiveBar);
    Vue.component('BTable', BTable);
    Vue.component('BTrow', BTrow);
    Vue.component('BTcell', BTcell);
    Vue.component('BTpagination', BTPagination);
    Vue.component('BTooltip', BTooltip);
    Vue.component('BChip', BChip);
    Vue.component('BRadio', BRadio);
    Vue.component('BList', BList);
    Vue.component('BListItem', BListItem);
  },
  installMolecules(Vue) {
    // Molecules
    Vue.component('BToast', BToast);
    Vue.component('BModal', BModal);
    Vue.component('BMenuContainer', BMenuContainer);
    Vue.component('BMenuItem', BMenuItem);
    Vue.component('BMenu', BMenu);
    Vue.component('BMenuBody', BMenuBody);
    Vue.component('BAlert', BAlert);
    Vue.component('BAccordion', BAccordion);
    Vue.component('BForm', BForm);
    Vue.component('BAutocomplete', BAutocomplete);
    Vue.component('BTabs', BTabs);
    Vue.component('BTab', BTab);
    Vue.component('BStep', BStep);
    Vue.component('BSteps', BSteps);
  },
};

export { BuserPartnersUIPlugin };
