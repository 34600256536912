<template>
  <slot
    v-if="errorMsg"
    name="error"
    :error-msg="errorMsg"
    v-bind="attrs"
  >
    {{ errorMsg }}
  </slot>

  <Suspense v-else-if="!errorMsg">
    <template #default>
      <slot v-bind="attrs" />
    </template>

    <template #fallback>
      <slot
        name="loading"
        v-bind="attrs"
      />
    </template>
  </Suspense>
</template>

<script>
import { ref, onErrorCaptured } from 'vue';

export default {
  inheritAttrs: false,
  setup(_props, { slots, attrs }) {
    const errorMsg = ref(null);

    onErrorCaptured((error) => {
      const hasErrorSlot = Boolean(slots.error);
      if (hasErrorSlot) errorMsg.value = error.message;
      return !hasErrorSlot;
    });
    return { errorMsg, attrs };
  },
};
</script>
