<template>
  <b-toast
    :is-active="$interfaceState.toast.hasToast"
    :item="$interfaceState.toast.currentToast"
  />
</template>

<script setup>
import { useInterface } from '@stores';

const $interfaceState = useInterface();
</script>
