<template>
  <b-card class="b-list">
    <ul
      class="b-list__container"
      role="menu"
      aria-hidden="true"
      aria-orientation="vertical"
    >
      <slot />
    </ul>
  </b-card>
</template>

<script setup>

</script>

<style lang="scss">
.b-list {
  --b-card--padding: 0;
  min-width: 112px;
}

.b-list__container {
  padding: var(--size-scalable-small) 0;
}
</style>
