import { CompanyAPI } from '@api';

const updateCompanyCertificate = (companyId, certificate, password) => {
  const formDataCertificate = new FormData();

  formDataCertificate.append('certificado_digital', certificate, certificate.name);
  formDataCertificate.append('id', companyId);
  formDataCertificate.append('senha_certificado', password);

  return CompanyAPI.updateCompanyCertificate(formDataCertificate)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { updateCompanyCertificate };
