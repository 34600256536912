<template>
  <div
    ref="$root"
    class="b-input"
    :class="{
      [`b-input--behavior-block`]: $props.block,
      [`b-input--behavior-${$props.behavior}`]: $props.behavior
    }"
  >
    <slot name="prepend" />

    <label
      :id="$state.inputFieldId"
      ref="$inputField"
      :for="$state.inputId"
      :class="{
        'mdc-text-field': true,
        'mdc-text-field--disabled': $props.isDisabled,
        'mdc-text-field--with-leading-icon': $props.iconLeft,
        'mdc-text-field--with-trailing-icon': $props.iconRight,
        'mdc-text-field--invalid': $validationState.internalInvalid,
        'mdc-text-field--textarea': $props.tag === 'textarea',
        'mdc-text-field--label-floating': $inputState.isFloating,
        'mdc-text-field--focused': $inputState.isFocused,
        [`mdc-text-field--${$props.variant}`]: $props.variant,
      }"
      @mousedown="handleMousedown"
      @click="handleClick"
    >
      <slot name="prepend-inner">
        <i
          v-if="$props.iconLeft"
          ref="inputFieldLeftIconRef"
          class="material-icons mdc-text-field__icon mdc-text-field__icon--leading"
          tabindex="0"
          role="button"
          @click.stop="$emit('click-icon-left')"
        >
          {{ $props.iconLeft }}
        </i>
      </slot>

      <span
        v-if="$props.variant === 'filled'"
        class="mdc-text-field__ripple"
      />

      <span
        v-if="$props.variant === 'filled'"
        class="mdc-floating-label"
        :class="{'mdc-floating-label--float-above': $inputState.isFloating}"
      >
        {{ $props.label }}
      </span>

      <span
        v-if="$props.variant === 'outlined'"
        class="mdc-notched-outline"
      >
        <span class="mdc-notched-outline__leading" />

        <span class="mdc-notched-outline__notch">
          <span
            class="mdc-floating-label"
            :class="{'mdc-floating-label--float-above': $inputState.isFloating}"
          >
            {{ $props.label }}
          </span>
        </span>

        <span class="mdc-notched-outline__trailing" />
      </span>

      <slot />

      <component
        :is="$props.tag"
        :id="$state.inputId"
        ref="$inputElement"
        :disabled="$props.isDisabled"
        :maxlength="$inputState.internalMaxLength"
        :type="$props.type"
        :value="$inputState.internalValue"
        :autocomplete="$props.browserAutocomplete"
        :name="$props.name"
        :max="$props.max"
        :min="$props.min"
        class="mdc-text-field__input"
        @blur="handleBlur"
        @change="handleChange"
        @focus="handleFocus"
        @input="handleInput"
      />

      <slot name="append-inner">
        <i
          v-if="$props.iconRight"
          ref="inputFieldRightIconRef"
          class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing"
          tabindex="0"
          role="button"
          @click.stop="$emit('click-icon-right')"
        >
          {{ $props.iconRight }}
        </i>
      </slot>

      <span
        v-if="$props.variant === 'filled'"
        class="mdc-line-ripple"
      />
    </label>

    <div
      v-if="$props.helperTextVisible"
      class="mdc-text-field-helper-line"
    >
      <div
        class="mdc-text-field-helper-text"
        :class="{
          'mdc-text-field-helper-text--validation-msg': $validationState.internalInvalid,
          'mdc-text-field-helper-text--persistent':
            $props.helperTextPersistent || $validationState.internalInvalid,
        }"
        :aria-hidden="$props.helperTextPersistent || $validationState.internalInvalid"
      >
        {{ $validationState.internalHelperText }}
      </div>
    </div>

    <slot name="append" />
  </div>
</template>

<script setup>
/* eslint-disable no-new */
import { shouldBeOneOf } from 'vue-prop-validation-helper';
import {
  onMounted,
  ref,
  computed,
  reactive,
  watch,
  inject,
  toRef,
} from 'vue';
import { BFormKey } from '@components/ui/molecules/b-form';
import { idGenerator } from '@composables/utilities';

import { textField } from 'material-components-web';
import { Mask } from '@utils/formats/mask';
import { Validate } from '@utils/validations/validate';
import { notEmpty } from '@utils/validations/not-empty';
import { pascalCase } from 'change-case';

const $props = defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: undefined,
  },
  tag: {
    type: String,
    default: 'input',
    validator: shouldBeOneOf(['input', 'textarea']),
  },
  type: {
    type: String,
    default: 'text',
  },
  variant: {
    default: 'outlined',
    type: String,
    validator: shouldBeOneOf(['filled', 'outlined']),
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  value: {
    type: [String, Number],
    default: null,
  },
  behavior: {
    type: String,
    default: null,
  },
  iconLeft: {
    type: String,
    default: null,
  },
  iconRight: {
    type: String,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  isFloating: {
    type: Boolean,
    default: false,
  },
  helperTextVisible: {
    type: Boolean,
    default: true,
  },
  helperText: {
    type: String,
    default: undefined,
  },
  helperTextPersistent: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: [String, Array],
    default: null,
  },
  returnAsMasked: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: [String, Number],
    default: null,
  },
  block: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Object,
    default: null,
  },
  autoUseMaxLength: {
    type: Boolean,
    default: true,
  },
  inputId: {
    type: String,
    default: undefined,
  },
  inputFieldId: {
    type: String,
    default: undefined,
  },
  browserAutocomplete: {
    type: String,
    default: undefined,
  },
  autocomplete: {
    type: Boolean,
    default: false,
  },
  max: {
    type: [String, Number],
    default: undefined,
  },
  min: {
    type: [String, Number],
    default: undefined,
  },
  registerField: {
    type: Boolean,
    default: true,
  },
});

const $emit = defineEmits([
  'input',
  'focus',
  'blur',
  'change',
  'update:modelValue',
  'click-icon-left',
  'click-icon-right',
]);

const $state = reactive({
  maskInstance: computed(() => $props.mask && Mask($props.mask)),
  hasMask: computed(() => Boolean($state.maskInstance)),
  returnMasked: computed(() => $state.hasMask && $props.returnAsMasked),
  nameFromProp: computed(() => $props.name || $props.label),
  name: computed(() => pascalCase($state.nameFromProp)),
  inputId: computed(() => $props.inputId || idGenerator('input')),
  inputFieldId: computed(() => $props.inputFieldId || idGenerator('input-field')),
});

const $inputState = reactive({
  valueFromProp: computed(() => {
    if ($props.modelValue !== undefined && $props.modelValue !== null) return $props.modelValue;

    return $props.value;
  }),
  internalValue: computed(() => {
    const newValue = $inputState.valueFromProp;

    const result = ($props.mask ? $state.maskInstance.masked(newValue) || null : newValue);
    return result;
  }),
  isFilled: computed(() => notEmpty($inputState.internalValue)),
  isFocused: false,
  isFloating: computed(() => $inputState.isFilled || $inputState.isFocused || $props.isFloating),
  maxLengthFromMaskOrRules: computed(() => (
    $props.autoUseMaxLength
    && ($state.maskInstance?.getPatternLength() || $props.rules?.length?.max)
  )),
  internalMaxLength: computed(() => (
    $props.maxlength || $inputState.maxLengthFromMaskOrRules || undefined
  )),
});

const $validationState = reactive({
  isInvalid: false,
  validationMessages: null,
  internalInvalid: computed(() => $props.isInvalid || $validationState.isInvalid),
  internalHelperText: computed(() => ($validationState.validationMessages?.[0]?.message || $props.helperText)),
  currentValidation: computed(() => ({
    name: $state.name,
    value: $inputState.internalValue,
    tag: $props.tag,
    type: $props.type,
    messages: $validationState.validationMessages,
  })),
});

const BForm = inject(BFormKey, undefined);

const $root = ref();
const $inputField = ref();
const $inputElement = ref();
const inputFieldLeftIconRef = ref();
const inputFieldRightIconRef = ref();

const openPickers = () => {
  const supportedInputTypes = ['date', 'month', 'week', 'time', 'datetime-local', 'color', 'file'];
  if ($props.isDisabled) return;
  if (!(supportedInputTypes.includes($props.type))) return;
  if (!('showPicker' in HTMLInputElement.prototype)) return;
  try {
    $inputElement?.value?.showPicker();
  } catch (error) {
    console.log(error);
  }
};

const handleInput = (event) => {
  let newValue;
  const targetValue = event.target.value;

  if ($state.hasMask && !$state.returnMasked) {
    newValue = $state.maskInstance.unmasked(targetValue);
  } else {
    newValue = targetValue;
  }

  newValue = $props.type === 'number' ? Number(newValue) : newValue;

  $emit('update:modelValue', newValue);
  $emit('input', newValue);
};

const handleChange = (event) => {
  $emit('change', event);
};

const handleFocus = (event) => {
  $inputState.isFocused = true;
  $emit('focus', event);
};

const handleBlur = (event) => {
  $inputState.isFocused = false;
  $emit('blur', event);
};

const handleClick = (_event) => {
  openPickers();
  $inputElement.value.focus();
};

const handleMousedown = (event) => {
  if (event.target !== $inputElement.value) event.preventDefault();
};

const checkValidation = (toValid = false) => {
  if (!$props.rules || $props.autocomplete) return;
  const value = !$props.returnAsMasked ? $inputState.valueFromProp : $inputState.internalValue;

  const result = Validate(value, $props.rules, $state.nameFromProp);

  $validationState.validationMessages = result;
  $validationState.isInvalid = toValid ? Boolean($validationState.validationMessages?.length) : false;
};

watch(() => [$inputState.valueFromProp, $props.rules], () => checkValidation(true));

onMounted(() => {
  if ($props.iconLeft) new textField.MDCTextFieldIcon(inputFieldLeftIconRef.value);
  if ($props.iconRight) new textField.MDCTextFieldIcon(inputFieldRightIconRef.value);

  if ($props?.rules) checkValidation($props?.rules?.startValidating);
  if (BForm && $props.registerField && !$props.autocomplete) {
    const currentValidation = toRef($validationState, 'currentValidation');
    BForm.registerField(currentValidation);
  }
});

defineExpose({
  $inputState,
  $root,
  $inputField,
  $inputElement,
});
</script>

<style lang="scss">
.b-input {
  width: 240px;
  &--behavior {
    &-block {
      width: 100%;
    }
  }
}

.mdc-text-field-helper-text--persistent {
  & .mdc-text-field-helper-text {
    opacity: 1;
  }
}

.mdc-text-field-helper-text--validation-msg {
  & .mdc-text-field-helper-text {
    color: #b00020 !important;
  }
}

.mdc-text-field:not(.mdc-text-field--label-floating) input[type="date"].mdc-text-field__input,
.mdc-text-field:not(.mdc-text-field--label-floating) input[type="month"].mdc-text-field__input {
  &::-webkit-datetime-edit-text,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
}

input[type="date"], input[type="month"] {
  &.mdc-text-field__input {

    &:not(:disabled) {
      cursor: pointer;

      &::-webkit-calendar-picker-indicator {
        display: inline-block !important;
        cursor: pointer;
      }
    }
  }
}

.mdc-text-field {
  width: 100%;
  cursor: text;
}

.mdc-notched-outline {
  & .mdc-floating-label {
    padding-left: var(--size-scalable-extra-small);
    padding-right: var(--size-scalable-extra-small);
  }
}

.mdc-notched-outline .mdc-floating-label--float-above {
  background-color: var(--color-white);
}
</style>
