<template>
  <div
    v-click-outside="handleClickOutside"
    class="app-header-section-session"
  >
    <b-avatar
      id="app-header-section-session"
      size="extra-large"
      tag="button"
      :src="$session?.currentUser?.photo_url"
      @click="handleAvatarClick"
    />

    <AppHeaderSectionSessionMenu
      :menu-is-open="$state.menuIsOpen"
      @close="$state.menuIsOpen = false"
    />
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import { useSession } from '@stores';
import AppHeaderSectionSessionMenu from './app-header-section-session-menu.vue';

const $session = useSession();

const $state = reactive({
  menuIsOpen: false,
});

const handleAvatarClick = () => {
  $state.menuIsOpen = !$state.menuIsOpen;
};

const handleClickOutside = () => {
  $state.menuIsOpen = false;
};
</script>

<style lang="scss">
.app-header-section-session {
  display: flex;
  align-items: center;
  position: relative;
}
</style>
