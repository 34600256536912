<template>
  <b-row
    :responsive="false"
    class="account-info__another-account b-margin-top--medium"
  >
    <b-column :column="12">
      <b-select
        v-model="$state.bank"
        label="Banco"
        variant="outlined"
        :block="true"
        :items="banks"
        :rules="{
          required: true,
        }"
        @change="handleBankAccountChange"
      />
    </b-column>

    <b-column :column="8">
      <b-input
        v-model="$WalletSacarModalStore.saqueData.bankAccount.agencia"
        label="Agência"
        variant="outlined"
        maxlength="4"
        :block="true"
        :rules="{
          required: true,
          onlyNumbers: true,
        }"
      />
    </b-column>

    <b-column :column="4">
      <b-input
        v-model="$WalletSacarModalStore.saqueData.bankAccount.agenciaDV"
        label="DV (Opcional)"
        variant="outlined"
        maxlength="1"
        :block="true"
        :rules="{
          onlyNumbers: true,
          required: false
        }"
      />
    </b-column>

    <b-column :column="8">
      <b-input
        v-model="$WalletSacarModalStore.saqueData.bankAccount.conta"
        label="Conta"
        variant="outlined"
        maxlength="10"
        :block="true"
        :rules="{
          required: true,
          onlyNumbers: true,
        }"
      />
    </b-column>

    <b-column :column="4">
      <b-input
        v-model="$WalletSacarModalStore.saqueData.bankAccount.contaDV"
        label="DV"
        variant="outlined"
        maxlength="1"
        :block="true"
        :rules="{
          required: true,
          onlyNumbers: true,
        }"
      />
    </b-column>

    <b-column :column="12">
      <b-input
        v-model="$WalletSacarModalStore.saqueData.bankAccount.documentNumber"
        label="CNPJ"
        variant="outlined"
        mask="cnpj"
        :block="true"
        :rules="{
          required: true,
          cnpj: true
        }"
      />
    </b-column>

    <b-column :column="12">
      <b-input
        v-model="$WalletSacarModalStore.saqueData.bankAccount.legalName"
        label="Nome Completo"
        variant="outlined"
        :block="true"
        :rules="{
          required: true,
        }"
      />
    </b-column>
  </b-row>
</template>

<script setup>
import { banks } from '@utils/dictionary/banks';
import { reactive } from 'vue';
import { useWalletSacarModalStore } from '@stores/finances';

const $WalletSacarModalStore = useWalletSacarModalStore();

const $state = reactive({
  bank: {
    value: undefined,
    text: undefined,
  },
});

const handleBankAccountChange = (bank) => {
  const code = bank.value;
  const name = bank.text;

  $state.bank.value = code;
  $state.bank.text = name;

  $WalletSacarModalStore.saqueData.bankAccount.bankCode = code;
};
</script>
