import { AuthAPI } from '@api';

const STATUS_CODE = 200;

const currentUser = async () => AuthAPI.currentUser()
  .then(({ data, status }) => {
    if (status !== STATUS_CODE) return null;

    return data;
  })
  .catch((_error) => null);

export { currentUser };
