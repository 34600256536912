<template>
  <li
    v-wave="$props.clickable"
    class="b-list-item"
    :class="{
      [`b-list-item--behavior-clickable`]: $props.clickable,
      [`b-list-item--behavior-nowrap`]: $props.nowrap,
    }"
    role="menuitem"
    @click="handleClick"
  >
    <slot />
  </li>
</template>

<script setup>
const $props = defineProps({
  clickable: {
    type: Boolean,
    default: true,
  },
  nowrap: {
    type: Boolean,
    default: true,
  },
});

const $emit = defineEmits(['click']);

const handleClick = ($event) => {
  if ($props.clickable) $emit('click', $event);
};
</script>

<style lang="scss">
.b-list-item {
  padding: 0 var(--size-scalable-medium);
  min-height: var(--size-scalable-extra-jumbo);
  overflow: hidden;
  display: flex;
  align-items: center;
  min-width: auto;

  &--behavior {
    &-clickable {
      cursor: pointer;
    }
    &-nowrap {
      white-space: nowrap;
    }
  }
}
</style>
