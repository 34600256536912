<template>
  <ul
    :class="{
      'b-steps': true,
      'b-steps--behavior-block': $config.block,
      [`b-steps--direction-${$config.direction}`]: $config.direction,
    }"
  >
    <slot />
  </ul>
</template>

<script>
/* eslint-disable import/first, no-param-reassign */
export default {
  name: 'BSteps',
};
</script>

<script setup>
import {
  provide,
} from 'vue';
import { BStepKey } from './b-step-key';

const $config = defineProps({
  direction: {
    type: String,
    default: 'horizontal',
  },
  textDirection: {
    type: String,
    default: 'horizontal',
  },
  modelValue: {
    type: Number,
    default: undefined,
  },
  clickable: {
    type: Boolean,
    default: true,
  },
  block: {
    type: Boolean,
    default: true,
  },
  showDescriptionOnlyIfSelected: {
    type: Boolean,
    default: true,
  },
  hasAnimation: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'progress',
  },
});

const $emit = defineEmits(['update:modelValue']);

const updatePosition = (index) => {
  $emit('update:modelValue', index);
};

provide(BStepKey, {
  $config,
  updatePosition,
});
</script>

<style lang="scss">
.b-steps {
  position: relative;
  display: flex;

  &--behavior {
    &-block {
      width: 100%;
    }
  }

  &--direction {
    &-horizontal {
      flex-direction: row;
    }

    &-vertical {
      flex-direction: column;
    }
  }
}
</style>
