import { CompanyAPI } from '@api';

const createContestacao = async (payload, files) => {
  const formData = new FormData();
  const payloadString = JSON.stringify(payload);

  files.forEach((file) => {
    formData.append(file.name, file);
  });

  formData.append('params', payloadString);
  return CompanyAPI.createContestacao(formData)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};
export { createContestacao };
