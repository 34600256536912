/* eslint-disable class-methods-use-this */
/* eslint-disable no-class-assign */
import { mask, tokens } from 'maska';
import { format, unformat } from 'v-money3';
import { maskDictionary } from './mask-dictionary';

class MaskClass {
  constructor(pattern, options) {
    this.pattern = this.getPatternFromDictionary(pattern);
    this.options = options;
  }

  masked(value) {
    let result;
    if (!value) return undefined;

    if (['money', 'percent'].includes(this.pattern?.name)) result = format(value, this.pattern.config);
    else result = mask(value, this.pattern?.mask, tokens, true);

    return result;
  }

  unmasked(value) {
    if (!value) return undefined;

    let result;

    if (['money', 'percent'].includes(this.pattern?.name)) result = unformat(value, this.pattern.config);
    else result = mask(value, this.pattern?.mask, tokens, false);

    return result;
  }

  getPattern() {
    return this.pattern;
  }

  getPatternLength() {
    return this.pattern?.maskLength;
  }

  getPatternFromDictionary(value) {
    return maskDictionary[value] || { mask: value };
  }
}

const Mask = new Proxy(MaskClass, {
  apply(Target, _thisArg, argumentsList) {
    return new Target(...argumentsList);
  },
});

export { Mask, MaskClass };
