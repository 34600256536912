/* eslint-disable no-underscore-dangle, no-param-reassign */
import { GeneralAPI } from '@api/buser-http-client';
import { ErrorPageName } from '@assets/constants/route-names';
import { useGeneralSettings } from '@stores';

const generalSettingsMiddleware = async (to, _from, next) => {
  if (to.name !== ErrorPageName) {
    const $general = useGeneralSettings();

    $general.settings = await GeneralAPI.getSettings()
      .then(({ data }) => data)
      .catch(() => null);

    if (!$general.settings) return next({ name: ErrorPageName });
  }

  return next();
};

export { generalSettingsMiddleware };
