<template>
  <component
    :is="tag"
    :class="{
      ['b-text']: true,
      [`b-text--variant-${$props.variant}`]: $props.variant != 'inherit',
      [`b-text--size-${$props.size}`]: $props.size != 'inherit',
      [`b-text--color-${$props.color}`]: $props.color != 'inherit',
      [`b-text--align-${$props.align}`]: $props.align != 'inherit',
      [`b-text--weight-${$props.weight}`]: $props.weight != 'inherit',
      [`b-text--behavior-uppercase`]: $props.uppercase,
      [`b-text--behavior-lowercase`]: $props.lowercase,
      [`b-text--behavior-capitalize`]: $props.capitalize,
      [`b-text--behavior-underline`]: $props.underline,
      [`b-text--behavior-italic`]: $props.italic,
      [`b-text--behavior-ellipsis-nowrap`]: $props.ellipsisNowrap,
      [`b-text--behavior-nowrap`]: $props.nowrap,
      [`b-text--behavior-link`]: $props.link,
      [`b-text--behavior-dynamic`]: $props.dynamic,
      [`b-text--emphasis-${$props.emphasis}`]: $props.emphasis != 'inherit',
    }"
    @click="handleClick($event)"
  >
    <slot />
  </component>
</template>

<script setup>
import { shouldBeOneOf } from 'vue-prop-validation-helper';

const $props = defineProps({
  size: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'nano',
      'micro',
      'extra-small',
      'small',
      'medium',
      'large',
      'extra-large',
      'jumbo',
      'extra-jumbo',
      'giant',
      'extra-giant',
      'colossal',
      'extra-colossal',
      'mega',
      'extra-mega',
      'immense',
    ]),
  },
  color: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'primary',
      'secondary',
      'grey',
      'inverse',
      'success',
      'warn',
      'danger',
      'info',
    ]),
  },
  variant: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'button',
      'caption',
      'overline',
    ]),
  },
  tag: {
    type: String,
    default: 'p',
    validator: shouldBeOneOf([
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'span',
      'div',
      'a',
      'strong',
      'caption',
      'li',
      'td',
      'th',
      'tr',
      'label',
      'section',
    ]),
  },
  align: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'left',
      'right',
      'center',
      'justify',
    ]),
  },
  weight: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'black',
      'extra-bold',
      'bold',
      'semi-bold',
      'medium',
      'regular',
      'light',
      'extra-light',
      'thin',
    ]),
  },
  emphasis: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'low',
      'medium',
      'high',
    ]),
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
  lowercase: {
    type: Boolean,
    default: false,
  },
  capitalize: {
    type: Boolean,
    default: false,
  },
  italic: {
    type: Boolean,
    default: false,
  },
  nowrap: {
    type: Boolean,
    default: false,
  },
  underline: {
    type: Boolean,
    default: false,
  },
  ellipsisNowrap: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Boolean,
    default: false,
  },
  dynamic: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(['click']);

const handleClick = ($event) => {
  if (!$props.isLoading) $emit('click', $event);
};
</script>

<style lang="scss">
:root {
  --b-text--color: inherit;
  --b-text--font-size: inherit;
  --b-text--font-style: inherit;
  --b-text--font-weight: inherit;
}

.b-text {
  color: var(--b-text--color);
  font-size: var(--b-text--font-size);
  font-style: var(--b-text--font-style);
  font-weight: var(--b-text--font-weight);
  word-wrap: break-word;
  white-space: normal;

  &--variant {
    &-h1 {
      --b-text--font-size: 96px;
      letter-spacing: -1.5px;
      --b-text--font-weight: 100;
    }
    &-h2 {
      --b-text--font-size: 60px;
      letter-spacing: -0.5px;
      --b-text--font-weight: 100;
    }
    &-h3 {
      --b-text--font-size: 48px;
      letter-spacing: 0px;
      --b-text--font-weight: 400;
    }
    &-h4 {
      --b-text--font-size: 34px;
      letter-spacing: 0.25px;
      --b-text--font-weight: 400;
    }
    &-h5 {
      --b-text--font-size: 24px;
      letter-spacing: 0px;
      --b-text--font-weight: 400;
    }
    &-h6 {
      --b-text--font-size: 20px;
      letter-spacing: 0.15px;
      --b-text--font-weight: 500;
    }
    &-subtitle1 {
      --b-text--font-size: 16px;
      letter-spacing: 0.15px;
      --b-text--font-weight: 400;
    }
    &-subtitle2 {
      --b-text--font-size: 14px;
      letter-spacing: 0.1px;
      --b-text--font-weight: 500;
    }
    &-body1 {
      --b-text--font-size: 16px;
      letter-spacing: 0.5px;
      --b-text--font-weight: 400;
    }
    &-body2 {
      --b-text--font-size: 14px;
      letter-spacing: 0.25px;
      --b-text--font-weight: 400;
    }
    &-button {
      --b-text--font-size: 14px;
      letter-spacing: 1.25px;
      --b-text--font-weight: 400;
      text-transform: uppercase;
    }
    &-caption {
      --b-text--font-size: 12px;
      letter-spacing: 0.4px;
      --b-text--font-weight: 400;
    }
    &-overline {
      --b-text--font-size: 10px;
      letter-spacing: 1.5px;
      --b-text--font-weight: 400;
    }
  }

  &--weight {
    &-black {
      --b-text--font-weight: 900;
    }
    &-extra-bold {
      --b-text--font-weight: 800;
    }
    &-bold {
      --b-text--font-weight: 700;
    }
    &-semi-bold {
      --b-text--font-weight: 600;
    }
    &-medium {
      --b-text--font-weight: 500;
    }
    &-regular {
      --b-text--font-weight: 400;
    }
    &-light {
      --b-text--font-weight: 300;
    }
    &-extra-light {
      --b-text--font-weight: 200;
    }
    &-thin {
      --b-text--font-weight: 100;
    }
  }

  &--color {
    &-primary {
      --b-text--color: var(--color-blue-grey-scale-800);
    }
    &-secondary {
      --b-text--color: var(--color-secondary);
    }
    &-grey {
      --b-text--color: var(--color-grey);
    }
    &-inverse {
      --b-text--color: var(--color-white);
    }
    &-success {
      --b-text--color: var(--color-success);
    }
    &-warn {
      --b-text--color: var(--color-warn);
    }
    &-danger {
      --b-text--color: var(--color-danger);
    }
    &-info {
      --b-text--color: var(--color-info);
    }
  }

  &--emphasis {
    &-high {
      opacity: 0.87;
    }
    &-medium {
      opacity: 0.60;
    }
    &-low {
      opacity: 0.38;
    }
  }

  &--size {
    &-nano {
      --b-text--font-size: var(--size-progressive-nano);
    }
    &-micro {
      --b-text--font-size: var(--size-progressive-micro);
    }
    &-extra-small {
      --b-text--font-size: var(--size-progressive-extra-small); // 14px
    }
    &-small {
      --b-text--font-size: var(--size-progressive-small); // 16px
      line-height: 24px;
    }
    &-medium {
      --b-text--font-size: var(--size-progressive-medium);
    }
    &-large {
      --b-text--font-size: var(--size-progressive-large);
    }
    &-extra-large {
      --b-text--font-size: var(--size-progressive-extra-large);
    }
    &-jumbo {
      --b-text--font-size: var(--size-progressive-jumbo);
    }
    &-extra-jumbo {
      --b-text--font-size: var(--size-progressive-extra-jumbo);
    }
    &-giant {
      --b-text--font-size: var(--size-progressive-giant);
    }
    &-extra-giant {
      --b-text--font-size: var(--size-progressive-extra-giant);
    }
    &-colossal {
      --b-text--font-size: var(--size-progressive-colossal);
    }
    &-extra-colossal {
      --b-text--font-size: var(--size-progressive-extra-colossal);
    }
    &-mega {
      --b-text--font-size: var(--size-progressive-mega);
    }
    &-extra-mega {
      --b-text--font-size: var(--size-progressive-extra-mega);
    }
    &-immense {
      --b-text--font-size: var(--size-progressive-immense);
    }
  }

  &--align {
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
    &-justify {
      text-align: justify;
      text-justify: inter-word;
    }
  }

  &--behavior {
    &-uppercase {
      text-transform: uppercase;
    }
    &-lowercase {
      text-transform: lowercase;
    }
    &-capitalize {
      text-transform: capitalize;
    }
    &-italic {
      --b-text--font-style: italic;
    }
    &-ellipsis-nowrap {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-nowrap {
      white-space: nowrap;
    }
    &-underline {
      text-decoration: underline;
    }
    &-link {
      cursor: pointer;
      --b-text--color: var(--color-secondary);
      --b-text--font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
    &-dynamic {
      & a {
        cursor: pointer;
        color: var(--color-secondary);
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
      & i:not(.material-icons) {
        font-style: italic;
      }
      & strong,
      & b {
        font-weight: 700;
      }
      & p {
        margin-bottom: var(--size-scalable-medium)
      }
    }
  }
}
</style>
