import { getDaysDifference } from '@utils/date/get-days-difference';

const isDateInThePast = (date) => {
  if (!date) {
    // Se data estiver vazia, ignora validação
    return true
  }
  const days = getDaysDifference(new Date(), date);
  return days < 0;
};

export { isDateInThePast };
