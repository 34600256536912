/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
import { defineStore } from 'pinia';
import { CompanyServices } from '@services/company-services';
import { EventLogger } from '@utils/event-metrics';
import { useInterface } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { createCopy } from '@utils/object/create-copy';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelManualAuthorizationModal = defineStore('TravelManualAuthorization', {
  state: () => ({
    interfaceState: useInterface(),
    isModalOpen: false,
    group: null,
    isLoading: false,
    isInvalidForm: false,
    selectedFile: null,
  }),
  actions: {
    open(group) {
      this.group = createCopy(group);
      this.isModalOpen = true;
    },
    close() {
      this.isModalOpen = false;
      this.group = null;
      this.isLoading = false;
      this.isInvalidForm = false;
      this.selectedFile = null;
    },
    uploadAuthorization() {
      this.isLoading = true;

      const params = { group_id: this.group.id };

      const authorizationData = new FormData();
      authorizationData.append('authorization', this.selectedFile, this.selectedFile.name);
      authorizationData.append('params', JSON.stringify(params));

      CompanyServices.manualGroupAuthorization(authorizationData)
        .then((data) => {
          const { autorizacao_url } = data;
          this.group.autorizacao_url = autorizacao_url;

          const TravelsPageStore = useTravelsPage();
          TravelsPageStore.requestGroups();

          this.interfaceState.toast.add({
            label: 'Autorização enviada com sucesso!',
            color: 'success',
          });

          this.isModalOpen = false;
        })
        .catch((error) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });

          EventLogger.logEvent(events.errorSendGroupManualAuthorization, {
            groupId: this.group?.id,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
