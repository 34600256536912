const getNumberInCurrency = (value) => {
  // eslint-disable-next-line no-param-reassign
  if (!value) value = 0;

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatter.format(value);
};

export { getNumberInCurrency };
