<template>
  <div class="app-notifier-card-mobile">
    <b-text
      variant="h2"
      weight="semi-bold"
      class="app-notifier-card-mobile__title b-margin-bottom--large"
    >
      {{ $props.title }}
    </b-text>

    <img
      :src="BusinhoSad"
      class="app-notifier-card-mobile__image b-margin-bottom--large"
    >

    <b-text
      size="jumbo"
      weight="semi-bold"
      color="inverse"
      align="center"
      class="b-margin-bottom--small"
    >
      {{ $props.description }}
    </b-text>

    <b-button
      v-if="$props.hasReturnButton"
      variant="text"
      color="inverse"
      icon-left="arrow_back"
      class="app-notifier-card-mobile__button"
      @click="$router.back"
    >
      Voltar à página anterior
    </b-button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import BusinhoSad from '@assets/images/businho-sad.svg';

const $router = useRouter();

const $props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  hasReturnButton: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss">
.app-notifier-card-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-scalable-large);
  height: 400px;
  transform: translateY(calc(-1 * var(--size-progressive-micro)));
}

.app-notifier-card-mobile__image {
  width: 142px;
  height: 142px;
}

.app-notifier-card-mobile__title {
  --b-text--color: var(--color-pink-scale-500);
}

.app-notifier-card-mobile__button {
  --mdc-text-button-label-text-weight: 600;
}
</style>
