import { CompanyAPI } from '@api';

const getGroupsList = async ({ filters, pagination }) => {
  const params = {
    filters,
    pagination,
  };
  return CompanyAPI.getGroupsList(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getGroupsList };
