import { maxDistanceAllowedOneDriver } from '@assets/constants/max-distance-allowed-one-driver';

const needSecondDriverANTT = (group) => {
  if (group.one_driver_exception) return false;

  const milisecond = 1;
  const second = milisecond * 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const maxOneDriverHourAntt = hour * 7 + minute * 20; // 7h20m

  return (
    group.rota?.distancia_total >= maxDistanceAllowedOneDriver() || group.rota?.duracao_total > maxOneDriverHourAntt
  );
};

export { needSecondDriverANTT };
