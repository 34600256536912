import { inject, provide } from 'vue';
import { useRoute } from 'vue-router';

const DOCUMENTATION_PROVIDER = Symbol('DOCUMENTATION_PROVIDER');

export function useDocumentationProvider() {
  const $route = useRoute();
  const pageDoc = $route?.meta?.docs;
  provide(DOCUMENTATION_PROVIDER, {
    pageDoc,
  });
}

export function useDocumentationContext() {
  const context = inject(DOCUMENTATION_PROVIDER);

  if (!context) {
    throw new Error('useDocumentationContext must be used with useDocumentationProvider');
  }

  return context;
}
