/* eslint-disable prefer-destructuring */
import { defineStore } from 'pinia';
import { stepsDictionary } from '@modules/travels/travel-authorizations/authorization-artesp/steps-dictionary';
import { CompanyServices } from '@services/company-services';
import { EventLogger } from '@utils/event-metrics';
import { useInterface } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelArtespModal = defineStore('TravelArtespModal', {
  state: () => ({
    interfaceState: useInterface(),
    isModalOpen: false,
    actualStep: null,
    group: null,
    artespPassword: null,
    artespCPF: null,
    isPrinting: false,
    isLoading: false,
    isInvalidForm: false,
    error: null,
  }),
  getters: {
    currentStepComponent(state) {
      return stepsDictionary[state.actualStep];
    },
    errorStatusIsTimeout() {
      return this?.error?.response?.status === 504;
    },
  },
  actions: {
    open(group) {
      this.group = group;
      this.actualStep = group.autorizacao_url ? 'ArtespPreview' : 'ArtespForm';
      this.isModalOpen = true;
    },
    close() {
      this.$reset();
    },
    generateArtesp() {
      this.isLoading = true;
      const params = {
        group_id: this.group.id,
        license_plate: this.group.onibus.placa,
        artesp_cpf: this.artespCPF,
        artesp_password: this.artespPassword,
      };

      CompanyServices.generateArtesp(params)
        .then(({ data }) => {
          if (data.error) {
            this.interfaceState.toast.add({
              label: data.error,
              color: 'danger',
            });

            EventLogger.logEvent(events.errorGenerateArtesp, {
              groupId: this.group?.id,
            });
          } else {
            this.group.artesp_preview = data.artesp_preview;
            this.group.autorizacao_url = data.autorizacao_url;
            this.group.artesp_number = data.artesp_number;

            const TravelsPageStore = useTravelsPage();
            TravelsPageStore.requestGroups();
            this.actualStep = 'ArtespPreview';
          }
        })
        .catch((error) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });

          EventLogger.logEvent(events.errorGenerateArtesp, {
            groupId: this.group?.id,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    printArtesp() {
      if (this.group.autorizacao_url) window.open(this.group.autorizacao_url, '_blank');
    },
  },
});
