import { createComponentWithLoader } from '@utils/promises/create-component-with-loader';
import SuccessSacar from './steps/success-sacar.vue';
import SacarForm from './steps/sacar-form.vue';
import LoadingStep from './steps/loading-step.vue';

const loadingComponent = LoadingStep;

const stepsDictionary = {
  SacarForm: createComponentWithLoader({ component: SacarForm, loadingComponent }),
  SuccessSacar: createComponentWithLoader({ component: SuccessSacar, loadingComponent }),
};

export { stepsDictionary };
