import { defineStore } from 'pinia';

export const useInterface = defineStore('useInterface', {
  state: () => ({
    $toast: {
      duration: 4000,
      timer: null,
      items: [],
    },
    $sidebar: {
      isOpen: false,
    },
    $modal: {
      userCpfUpdate: {
        isOpen: false,
      },
    },
  }),
  getters: {
    toast(state) {
      const toastItem = state.$toast?.items[0];
      const hasToast = Boolean(toastItem);

      if (hasToast) {
        if (!toastItem.type !== 'static') toastItem.timer(toastItem.duration);
      }

      const currentToast = toastItem?.item;

      const removeFirst = () => {
        state.$toast.items.shift();
      };

      const createTimer = (duration) => {
        const timer = setTimeout(() => {
          removeFirst();
          clearTimeout(timer);
        }, duration || state.$toast.duration);
      };

      const add = (item) => {
        const newItem = {
          item,
          timer: createTimer,
        };
        state.$toast.items.push(newItem);
      };

      return {
        currentToast,
        hasToast,
        removeFirst,
        createTimer,
        add,
      };
    },
  },
});
