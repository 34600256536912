import { CompanyAPI } from '@api';

const setCompanyNotificationsToReaded = (id) => {
  const params = {
    notification_id: id,
  };

  return CompanyAPI.setCompanyNotificationsToReaded(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { setCompanyNotificationsToReaded };
