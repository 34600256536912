import { createComponentWithLoader } from '@utils/promises/create-component-with-loader';
import LoadingStep from './steps/loading-step.vue';
import AnttLogin from './steps/antt-login-step.vue';
import AnttInfos from './steps/antt-travel-info-step.vue';
import AnttPreview from './steps/antt-preview-step.vue';

const loadingComponent = LoadingStep;

const stepsDictionary = {
  AnttLogin: createComponentWithLoader({ component: AnttLogin, loadingComponent }),
  AnttInfos: createComponentWithLoader({ component: AnttInfos, loadingComponent }),
  AnttPreview: createComponentWithLoader({ component: AnttPreview, loadingComponent }),
};

export { stepsDictionary };
