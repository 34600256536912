import { CompanyAPI } from '@api';

const getCities = async (uf) => {
  const params = { uf };

  return CompanyAPI.getCities(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getCities };
