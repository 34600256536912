<template>
  <div
    class="b-select"
    :class="{
      [`b-select--behavior-block`]: $props.block,
      [`b-select--behavior-${$props.behavior}`]: $props.behavior,
    }"
  >
    <div
      :id="$state.id"
      ref="selectRef"
      v-click-outside="handleBlur"
      class="mdc-select b-select-field"
      :class="{
        'mdc-select--disabled': $props.isDisabled,
        'mdc-select--focused': $state.isFocused,
        'mdc-select--activated': $state.isActive,
        'mdc-select--with-leading-icon': $props.iconLeft,
        'mdc-select--invalid': $validationState.internalInvalid,
        'mdc-select--no-label': $props.noLabel,
        [` mdc-select--${$props.variant}`]: $props.variant,
      }"
      @blur="handleBlur"
    >
      <div
        class="mdc-select__anchor"
        tabindex="0"
        role="button"
        aria-haspopup="listbox"
        :aria-expanded="$state.isActive"
        :aria-disabled="$props.isDisabled"
        @click="handleAnchorClick"
        @focus="$state.isFocused = true"
      >
        <span
          v-if="$props.variant === 'outlined'"
          class="mdc-notched-outline"
        >
          <span class="mdc-notched-outline__leading" />
          <span class="mdc-notched-outline__notch">
            <span
              class="mdc-floating-label"
              :class="{'mdc-floating-label--float-above': $state.isFloating}"
            >
              {{ $props.label }}
            </span>
          </span>
          <span class="mdc-notched-outline__trailing" />
        </span>

        <span
          v-if="$props.variant === 'filled'"
          class="mdc-select__ripple"
        />

        <span
          v-if="$props.variant === 'filled'"
          class="mdc-floating-label"
          :class="{'mdc-floating-label--float-above': $state.isFloating}"
        >
          {{ $props.label }}
        </span>

        <i
          v-if="$props.iconLeft"
          ref="selectIconRef"
          class="material-icons mdc-select__icon"
          role="button"
        >
          {{ $props.iconLeft }}
        </i>

        <span class="mdc-select__selected-text-container">
          <span
            class="mdc-select__selected-text"
          >
            {{ getValue($state.internalValue, $props.labelledBy) }}
          </span>
        </span>

        <span
          class="mdc-select__dropdown-icon"
        >
          <svg
            v-if="!$state.isSelected || !hasClearButton"
            class="mdc-select__dropdown-icon-graphic"
            viewBox="7 10 10 5"
            focusable="false"
          >
            <polygon
              class="mdc-select__dropdown-icon-inactive"
              stroke="none"
              fill-rule="evenodd"
              points="7 10 12 15 17 10"
            />
            <polygon
              class="mdc-select__dropdown-icon-active"
              stroke="none"
              fill-rule="evenodd"
              points="7 15 12 10 17 15"
            />
          </svg>
        </span>

        <span
          v-if="$props.variant === 'filled'"
          class="mdc-line-ripple"
        />
      </div>

      <b-icon
        v-if="$state.isSelected && hasClearButton"
        icon="clear"
        tag="button"
        class="mdc-select__custom-icon"
        size="large"
        @click="$emit('clear')"
      />

      <teleport to="body">
        <BSelectMenu
          v-if="$state.isActive"
          :labelled-by="$props.labelledBy"
          :keyed-by="$props.keyedBy"
          :aria-description="$props.ariaDescription"
          :items="$props.items"
          :internal-value="$state.internalValue"
          :current-list-index="$state.currentListIndex"
          :style="style"
          @click="handleItemClick"
        />
      </teleport>
    </div>

    <div
      v-if="$props.helperTextVisible"
      class="mdc-text-field-helper-line"
    >
      <div
        class="mdc-text-field-helper-text"
        :class="{
          'mdc-text-field-helper-text--validation-msg': $validationState.internalInvalid,
          'mdc-text-field-helper-text--persistent':
            $props.helperTextPersistent || $validationState.internalInvalid,
        }"
        :aria-hidden="$props.helperTextPersistent || $validationState.internalInvalid"
      >
        {{ $validationState.internalHelperText }}
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-new */
import {
  onMounted,
  ref,
  computed,
  reactive,
  onUnmounted,
  inject,
  toRef,
  watch,
} from 'vue';
import { select } from 'material-components-web';
import { pascalCase } from 'change-case';
import { BFormKey } from '@components/ui/molecules/b-form';
import { Validate } from '@utils/validations/validate';
import { shouldBeOneOf } from 'vue-prop-validation-helper';
import { useElementPosition, idGenerator } from '@composables/utilities';
import { getValue } from '@utils/object/get-value';
import BSelectMenu from './b-select-menu.vue';

const $props = defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: undefined,
  },
  variant: {
    default: 'outlined',
    type: String,
    validator: shouldBeOneOf(['filled', 'outlined']),
  },
  items: {
    type: Array,
    default: undefined,
  },
  ariaDescription: {
    type: String,
    default: 'Teste',
  },
  modelValue: {
    type: [String, Object],
    default: null,
  },
  value: {
    type: [String, Object],
    default: null,
  },
  labelledBy: {
    type: String,
    default: 'text',
  },
  keyedBy: {
    type: String,
    default: 'value',
  },
  hasClearButton: {
    type: Boolean,
    default: false,
  },
  iconLeft: {
    type: String,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  helperText: {
    type: String,
    default: null,
  },
  helperTextVisible: {
    type: Boolean,
    default: true,
  },
  helperTextPersistent: {
    type: Boolean,
    default: false,
  },
  behavior: {
    type: String,
    default: null,
  },
  block: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Object,
    default: null,
  },
  noLabel: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: undefined,
  },
});

const $emit = defineEmits(['change', 'update:modelValue', 'select', 'clear']);
const selectRef = ref();
const selectIconRef = ref();

const $state = reactive({
  isFocused: false,
  isActive: false,
  isSelected: computed(() => Boolean(getValue($state.internalValue, $props.labelledBy))),
  isFloating: computed(() => $state.isFocused || $state.isSelected),
  internalValue: computed(() => $props?.modelValue || $props?.value),
  currentListIndex: 0,
  nameCleaned: computed(() => $props.name || $props.label),
  name: computed(() => pascalCase($state.nameCleaned)),
  id: computed(() => $props.id || idGenerator('select')),
  sameSurfaceWidth: true,
});

const $validationState = reactive({
  isInvalid: false,
  validationMessages: null,
  internalInvalid: computed(() => $props.isInvalid || $validationState.isInvalid),
  internalHelperText: computed(() => $validationState.validationMessages?.[0]?.message || $props.helperText),
  currentValidation: computed(() => ({
    name: $state.name,
    value: $state.internalValue,
    tag: 'select',
    type: 'simple',
    messages: $validationState.validationMessages,
  })),
});

const { style } = useElementPosition(selectRef, $state);

const BForm = inject(BFormKey, undefined);

const handleBlur = () => {
  $state.isFocused = false;
  $state.isActive = false;
};

const handleAnchorClick = () => {
  const newValue = !$state.isActive;
  $state.isActive = newValue;
  $state.isFocused = newValue;
};

const handleItemClick = ({ item, index }) => {
  $state.currentListIndex = index;
  $state.isActive = false;
  $state.isFocused = true;
  $emit('change', item);
  $emit('select', item);
  $emit('update:modelValue', item);
};

const changeFocusItem = (goUp = false) => {
  if (!$props.items?.length) return;
  const minValue = 0;
  const maxValue = $props.items.length - 1;

  if ($state.currentListIndex < maxValue && !goUp) {
    $state.currentListIndex += 1;
  }

  if ($state.currentListIndex > minValue && goUp) {
    $state.currentListIndex -= 1;
  }
};

const checkValidation = (toValid = false) => {
  if (!$props.rules) return;
  const value = getValue($state.internalValue, $props.keyedBy);
  const result = Validate(value, $props.rules, $state.nameCleaned);

  $validationState.validationMessages = result;
  $validationState.isInvalid = toValid ? Boolean($validationState.validationMessages?.length) : false;
};

watch(() => $state.internalValue, () => checkValidation(true));

const handleKeyPress = (keyPressed) => {
  if ($state.isFocused) {
    const up = 'ArrowUp';
    const down = 'ArrowDown';
    const enter = 'Enter';
    const esc = 'Escape';
    const tab = 'Tab';

    if (keyPressed.key === enter && !$state.isActive) {
      $state.isActive = true;
      return;
    }

    if (keyPressed.key === up) {
      changeFocusItem(true);
      keyPressed.preventDefault();
    } else if (keyPressed.key === down) {
      changeFocusItem(false);
      keyPressed.preventDefault();
    } else if (keyPressed.key === enter) {
      handleItemClick({ item: $props.items[$state.currentListIndex], index: $state.currentListIndex });
      keyPressed.preventDefault();
    } else if (keyPressed.key === esc) {
      $state.isActive = false;
      keyPressed.preventDefault();
    } else if (keyPressed.key === tab) {
      $state.isFocused = false;
      if ($state.isActive) $state.isActive = false;
    }
  }
};

onMounted(() => {
  document.addEventListener('keydown', handleKeyPress);

  if ($props.iconLeft) new select.MDCSelectIcon(selectIconRef.value);

  if ($props?.rules) checkValidation($props?.rules?.startValidating);

  if (BForm) {
    const currentValidation = toRef($validationState, 'currentValidation');
    BForm.registerField(currentValidation);
  }
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyPress);
});
</script>

<style lang="scss">
.b-select {
  --b-select--width: 240px;

  position: relative;
  width: var(--b-select--width);

  & .mdc-select,
    .mdc-select__anchor {
    width: 100%;
  }

  &--behavior {
    &-block {
      --b-select--width: 100%;
    }
  }
}

.b-select__menu-list {
  max-height: 250px;
  overflow-y: scroll;
}

.mdc-list-item {
  min-height: var(--size-scalable-extra-jumbo);
  align-items: center !important;
  display: flex;

  &--arrow-focused {
    background-color: var(--color-grey-scale-300);
  }
}

.mdc-select__custom-icon {
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: var(--size-progressive-micro);
  position: absolute;
  right: 0;
}

.mdc-select + .mdc-text-field-helper-line {
  padding-right: var(--size-scalable-medium);
  padding-left: var(--size-scalable-medium);
}

.mdc-select--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  &:not(.mdc-text-field-helper-text--validation-msg) {
    opacity: 1;
  }
}

.mdc-select--invalid:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line {
  & .mdc-text-field-helper-text--validation-msg {
    color: var(--mdc-theme-error, #b00020);
  }
}

.mdc-notched-outline .mdc-floating-label--float-above {
  background-color: var(--color-white);
}
</style>
