import { filterOnlyVisible } from '@utils/auth/filter-only-visible';
import { useSession } from '@stores';

const getMenuItem = () => {
  const $session = useSession();

  return [
    {
      icon: 'calendar_today',
      text: 'Viagens',
      url: '/viagens',
      visible: $session.isCompany,
    },
    {
      icon: 'warning',
      text: 'Avisos',
      url: '/avisos',
      visible: $session.isCompany,
    },
    {
      icon: 'report',
      text: 'Incidentes',
      url: '/incidentes',
      visible: $session.isCompany,
    },
    {
      icon: 'computer',
      text: 'Operação',
      items: [
        {
          icon: 'directions_bus_filled',
          text: 'Onibus',
          url: '/onibus',
        },
        {
          icon: 'face',
          text: 'Motoristas',
          url: '/motoristas',
        },
      ],
      visible: $session.isCompany,
    },
    {
      icon: 'attach_money',
      text: 'Financeiro',
      visible: $session.isCompany && ($session.company.isCompanyNFEnabled || $session.canDo('CARTEIRA_PARCEIRO')),
      items: [
        {
          icon: 'description',
          text: 'Notas fiscais',
          url: '/financeiro/notas-fiscais',
          visible: $session.company.isCompanyNFEnabled,
        },
        {
          icon: 'account_balance_wallet',
          text: 'Carteira',
          url: '/financeiro/carteira',
          visible: $session.canDo('CARTEIRA_PARCEIRO'),
        },
        {
          icon: 'attach_money',
          text: 'Antecipação Pagamento',
          url: '/financeiro/antecipacao-pagamento',
          visible: $session.canDo('CARTEIRA_PARCEIRO'),
        },
        {
          icon: 'payments',
          text: 'Empréstimos',
          url: '/financeiro/emprestimos',
          visible: $session.canDo('CARTEIRA_PARCEIRO'),
        },
        {
          icon: 'currency_exchange',
          text: 'Reembolso',
          url: '/financeiro/reembolso',
          visible: $session.canDo('CARTEIRA_PARCEIRO'),
        },
        {
          icon: 'receipt_long',
          text: 'Multas',
          url: '/financeiro/multas',
          visible: $session.canDo('CARTEIRA_PARCEIRO'),
        },
      ],
    },
    {
      icon: 'insights',
      text: 'Relatórios',
      url: '/relatorios',
      visible: $session.isCompany,
    },
    {
      icon: 'help',
      text: 'Ajuda',
      url: '/ajuda',
    },
  ]
    .filter(filterOnlyVisible);
};

export { getMenuItem };
