<template>
  <div class="app-feedback-bar">
    <b-icon
      v-for="index in roundedStars"
      :key="index+'star-filled'"
      icon="star"
      variant="outlined"
      class="app-feedback-bar__icon"
      :size="size"
      :color="color"
    />

    <b-icon
      v-if="hasHalfStar"
      icon="star_half"
      variant="outlined"
      class="app-feedback-bar__icon"
      :size="size"
      :color="color"
    />

    <b-icon
      v-for="index in emptyStars"
      :key="index+'star-empty'"
      icon="grade"
      variant="outlined"
      class="app-feedback-bar__icon"
      :size="size"
      :color="color"
    />
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'inherit',
    },
    color: {
      type: String,
      default: 'inherit',
    },
    maxStars: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    roundedStars() {
      return Math.trunc(this.count);
    },
    hasHalfStar() {
      return (this.count - this.roundedStars) > 0;
    },
    emptyStars() {
      return Math.trunc((this.maxStars - this.count));
    },
  },
};
</script>

<style lang="scss">
.app-feedback-bar {
  display: flex;

  &__icon:not(:last-of-type) {
    margin-right: var(--size-scalable-micro);
  }
}
</style>
