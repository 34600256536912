<template>
  <button
    class="app-header-mobile-menu"
    @click="$interfaceState.$sidebar.isOpen = !$interfaceState.$sidebar.isOpen"
  >
    <b-icon
      icon="menu"
      color="inverse"
    />
  </button>
</template>

<script setup>
import { useInterface } from '@stores';

const $interfaceState = useInterface();
</script>

<style lang="scss">
.app-header-mobile-menu {
  padding: 0;
}
</style>
