export const contestacaoStatus = {
  APLICADA: 'aplicada',
  CANCELADA: 'cancelada',
  CONTESTADA: 'contestada',
  ESTORNADA: 'estornada',
};

export const contestacaoBluePrint = {
  justification: '',
  files: [{
    file: null,
    description: '',
  }],
};
