<template>
  <div
    class="b-radio"
    :class="{
      'b-radio--behavior-block': block,
      'b-radio--behavior-is-disabled': isDisabled,
    }"
  >
    <div
      class="mdc-radio"
      :class="{
        'mdc-radio--disabled': isDisabled,
      }"
    >
      <input
        :id="id"
        class="mdc-radio__native-control"
        type="radio"
        :name="name"
        :value="value"
        :checked="internalChecked"
        :disabled="isDisabled"
        @change.prevent.stop="handleChange"
      >

      <div class="mdc-radio__background">
        <div class="mdc-radio__outer-circle" />
        <div class="mdc-radio__inner-circle" />
      </div>

      <div class="mdc-radio__ripple" />
    </div>

    <b-text
      :for="id"
      tag="label"
      class="b-radio__label"
    >
      {{ label }}
    </b-text>
  </div>
</template>

<script>
import { idGenerator } from '@composables/utilities';

export default {
  name: 'BRadio',
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    checked: {
      type: Boolean,
      default: undefined,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    block: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    internalChecked() {
      return (this.modelValue === this.value) || this.checked;
    },
    id() {
      const id = idGenerator();
      return id;
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
      this.$emit('update:modelValue', this.value);
    },
  },
};

</script>

<style lang="scss">
:root {
  --b-radio-main-color: var(--color-primary);
  --b-radio-background-color: transparent;
  --b-radio-border-color: var(--color-theme-primary);
  --b-radio-color: var(--color-theme-white);
  --b-radio__label--color: inherit;
  --b-radio__label--color-is-disabled: rgba(0, 0, 0, 0.38);
}

.b-radio {
  display: flex;
  align-items: center;
  width: fit-content;
  --mdc-theme-secondary: var(--b-radio-main-color);

  &--behavior {
    &-block {
      width: 100%;
    }
    &-is-disabled {
      --b-radio__label--color: var(--b-radio__label--color-is-disabled);
    }
  }

  &--color {
    &-primary {
      --b-radio-main-color: var(--color-primary);
    }
  }

  &__label {
    color: var(--b-radio__label--color);
  }
}
</style>
