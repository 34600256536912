import { defineStore } from 'pinia';
import { createCopy } from '@utils/object/create-copy';
import { CompanyServices } from '@services/company-services';
import { useTravelsPage } from '@stores';
import { isEqual } from '@utils/object/is-equal';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelBatchOperationsModal = defineStore('TravelBatchOperationsModal', {
  state: () => ({
    isLoading: false,
    isModalOpen: false,
    groups: null,
    groupsPrices: null,
    groupsPricesToEdit: null,
    confirmDialogIsOpen: false,
    isAlertOpen: true,
  }),
  getters: {
    hasChanged(state) {
      return !isEqual(state.groupsPrices, state.groupsPricesToEdit);
    },
  },
  actions: {
    open(groups) {
      this.groups = createCopy(groups);
      this.isModalOpen = true;
      this.confirmDialogIsOpen = false;
      this.isAlertOpen = true;

      EventLogger.logEvent(events.openModalUpdatePricesInBatches);
    },
    close() {
      this.isModalOpen = false;
      this.groups = null;
      this.groupsPrices = null;
      this.groupsPricesToEdit = null;
      this.confirmDialogIsOpen = false;
    },
    tryEditOperation() {
      if (this.hasChanged) {
        this.confirmDialogIsOpen = true;
      }
    },
    requestGetGroupPrices() {
      const groupIds = this.groups.map((group) => group.id);
      CompanyServices.getGroupPrices(groupIds)
        .then((result) => {
          if (result?.length >= 0) {
            this.groupsPrices = createCopy(result);
            this.groupsPricesToEdit = createCopy(result);
          } else {
            this.groupsPrices = null;
            this.groupsPricesToEdit = null;
          }
        });
    },
    getChangedValue() {
      const changedValue = [];
      this.groupsPricesToEdit?.forEach((group, groupIndex) => {
        Object.entries(group.trechos_classes).forEach(([routeLegClassesId, routeLegClasses]) => {
          Object.entries(routeLegClasses).forEach(([classeId, classe]) => {
            if (!isEqual(this.groupsPrices[groupIndex].trechos_classes[routeLegClassesId][classeId], classe)) {
              changedValue.push({ trecho_classe_id: classe.id, valor_novo: classe.max_split_value });
            }
          });
        });
      });
      return changedValue;
    },
    requestUpdateGroupPrices() {
      EventLogger.logEvent(events.requestToUpdatePricesInBatches);

      this.isLoading = true;
      const payload = this.getChangedValue();
      const TravelsPage = useTravelsPage();
      const groupIds = this.groups.map((group) => group.id);
      return CompanyServices.updateGroupPrices(groupIds, payload, this.groupPriceUpdateInBatch)
        .then(() => {
          TravelsPage.requestGroups();
          TravelsPage.allGroupsIsSelected = false;
          TravelsPage.groupsSelected = [];
          this.close();

          EventLogger.logEvent(events.pricesUpdatedInBatchesSuccess);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
