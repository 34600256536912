import { suggestNewTollValue } from './suggest-new-toll-value';
import { verifyMonthlyTollStatement } from './verify-monthly-toll-statement';
import { sendMonthlyStatement } from './send-monthly-statement';

const OpsServices = {
  suggestNewTollValue,
  sendMonthlyStatement,
  verifyMonthlyTollStatement,
};

export { OpsServices };
