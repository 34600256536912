import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import { CompanyServices } from '@services/company-services';
import { contestacaoBluePrint } from '@modules/multas/contestacao-const';

export const useMultaStore = defineStore('MultaStore', () => {
  const $state = reactive({
    multas: [],
    multa: {},
    quantityMultas: 0,
  });

  const fetchMultasList = async (paginator) => {
    const { multas, quantity_multas: quantityMultas } = await CompanyServices.getMultaList(paginator);
    $state.multas = multas;
    $state.quantityMultas = quantityMultas;
  };

  const fetchMulta = async (multaId) => {
    const res = await CompanyServices.getMulta(multaId);
    $state.multa = res.multa;
    if (!$state.multa.has_contestation) {
      $state.multa.contestation = { ...contestacaoBluePrint };
    }
  };

  const clearFileData = (index) => { $state.multa.contestation.files.splice(index - 1, 1); };
  const addFileInput = () => {
    $state.multa.contestation.files.push({
      file: undefined,
      description: '',
    });
  };

  const removeNullFiles = () => {
    const { files: filesObjects } = $state.multa.contestation;
    const indexesToRemove = [];
    filesObjects.forEach((f, index) => {
      if (!f.file) {
        indexesToRemove.push(index);
      }
    });
    indexesToRemove.forEach((index) => {
      filesObjects.splice(index, 1);
    });
  };

  const createContestacao = async () => {
    const payload = {
      multa_id: $state.multa.id,
      justification: $state.multa.contestation.justification,
      files: $state.multa.contestation.files.map(
        (f) => ({ name: f.file.name, description: f.description }),
      ),
    };
    const files = $state.multa.contestation.files.map((f) => f.file);

    await CompanyServices.createContestacao(payload, files).then(() => { }).catch((error) => {
      throw new Error(error);
    });
  };

  const state = toRefs($state);

  return {
    multa: state.multa,
    multas: state.multas,
    quantityMultas: state.quantityMultas,
    fetchMulta,
    fetchMultasList,
    removeFileInput: clearFileData,
    addFileInput,
    createContestacao,
    removeNullFiles,
  };
});
