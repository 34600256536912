const getFileName = (filePath) => {
  if (!filePath) return undefined;

  let fileName = filePath.toLowerCase().split('/').pop();
  const indexOfQuery = fileName.indexOf('?');

  if (indexOfQuery > 0) fileName = fileName.substring(1, indexOfQuery);

  return fileName;
};

export { getFileName };
