import { CompanyAPI } from '@api';
import { Settings } from '@settings';

const getCompanyContract = async () => {
  if (!Settings.isProduction && !Settings.isHomolog) return Promise.resolve({ url: 'https://www.buser.com.br/' });

  return CompanyAPI.getCompanyContract()
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getCompanyContract };
