import { reactive, toRefs } from 'vue';
import { screenSizes } from '@assets/constants/screen-sizes';
import { defineStore } from 'pinia';
import { useWindowSize } from '@vueuse/core';

export const useScreen = defineStore('ScreenStore', () => {
  const { width, height } = useWindowSize();

  const $screen = reactive({
    width,
    height,
  });

  const isSizeUp = (sizeName) => $screen.width > screenSizes[sizeName];
  const isSizeDown = (sizeName) => $screen.width <= screenSizes[sizeName];

  const screen = toRefs($screen);

  return {
    height: screen.height,
    width: screen.width,
    isSizeUp,
    isSizeDown,
  };
});
