<template>
  <div
    v-click-outside="clickOutside"
    class="b-menu"
  >
    <div
      ref="$element"
    >
      <slot
        name="activator"
        v-bind="{click, isActive: $state.isActive}"
      />
    </div>

    <teleport to="body">
      <b-menu-body
        v-model="$state.isActive"
        :style="style"
        @click.stop="clickInList"
      >
        <slot />
      </b-menu-body>
    </teleport>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { useElementPosition } from '@composables/utilities';
import { POSITION_OPTIONS } from '@assets/constants/positions';
import { shouldBeOneOf } from 'vue-prop-validation-helper';
import BMenuBody from './b-menu-body.vue';

const $props = defineProps({
  autoClose: {
    type: Boolean,
    default: true,
  },
  sameSurfaceWidth: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'bottom',
    validator: shouldBeOneOf(POSITION_OPTIONS),
  },
});

const $state = reactive({
  isActive: false,
  position: computed(() => $props.position),
});

const $element = ref();

const { style } = useElementPosition($element, $state);

const click = () => {
  $state.isActive = !$state.isActive;
};

const clickInList = () => {
  if ($props.autoClose) $state.isActive = false;
};

const clickOutside = () => {
  if ($state.isActive) $state.isActive = false;
};
</script>
