import { checkIfCompanyCertificateError } from '@utils/company/check-if-company-certificate-error';
import { defineStore } from 'pinia';

export const useSession = defineStore('SessionStore', {
  state: () => ({
    currentUser: null,
  }),
  getters: {
    isCompany(state) {
      return state.currentUser?.company;
    },
    isCompanyNFEnabled(state) {
      return state.currentUser?.company?.emissao_nf_enabled;
    },
    isUserFromBuser(state) {
      return state.currentUser?.email?.includes('@buser.com.br');
    },
    isTeste(state) {
      return state.currentUser?.email === 'parceiro@admin.com';
    },
    isAuthenticated(state) {
      return Boolean(state?.currentUser?.id);
    },
    currentCompany(state) {
      return state.currentUser?.company;
    },
    company(state) {
      return {
        isCompanyNFEnabled: Boolean(state.currentUser?.company?.emissao_nf_enabled),
        isSimplesNacional: state.currentUser?.company?.is_simples_nacional,
        ICMSType: state.currentUser?.company?.tipo_icms,
        hasCertificateError: checkIfCompanyCertificateError(state.currentUser?.company?.datetime_expiracao_certificado),
      };
    },
  },
  actions: {
    canDo(permission) {
      if (!this.currentUser) return false;
      return Object.keys(this.currentUser?.permissions ?? {})
        ?.indexOf(permission) >= 0;
    },
    getUserCompany() {
      if (!this.isCompany) {
        throw new Error('User has no access to company details');
      }

      return this.currentUser?.company;
    },
    isUserFromCompany(companiesIds) {
      if (!companiesIds) return false;
      return Boolean(companiesIds?.includes(this.currentUser?.company?.id));
    },
  },
});
