import { CompanyAPI } from '@api/buser-http-client';

const setGroupBus = (busId, grupoId) => {
  const params = {
    bus_id: busId,
    group_id: grupoId,
  };

  return CompanyAPI.setGroupBus(params)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { setGroupBus };
