import { isEmptyObject } from '@utils/validations/is-empty-object';
import { objectHasField } from '@utils/validations/object-has-field';

export const getValue = (internalValue, field) => {
  if (!internalValue) return undefined;

  return (
    (objectHasField(internalValue, field) || isEmptyObject(internalValue))
      ? internalValue?.[field]
      : internalValue
  );
};
