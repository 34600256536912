const LOG_TEMPLATES = {
  CRIADO: 'Solicitada pelo parceiro a alteração do preço da viagem',
  APROVADO: 'Aprovada e publicada pela Buser a solicitação do parceiro de alteração do preço da viagem',
  REJEITADO: 'Recusada e não publicada pela Buser, a solicitação do parceiro de alteração do preço da viagem',
  SOBRESCRITO: 'Solicitação cancelada por novo pedido: Solicitada pelo parceiro a alteração do preço da viagem',
};

const getDescription = (type, log) => {
  const template = LOG_TEMPLATES[type];

  const cidadeOrigem = log.trecho_classe.trecho_vendido.origem.cidade;
  const UFOrigem = log.trecho_classe.trecho_vendido.origem.uf;
  const cidadeDestino = log.trecho_classe.trecho_vendido.destino.cidade;
  const UFDestino = log.trecho_classe.trecho_vendido.destino.uf;
  const classe = log.trecho_classe.grupo_classe.tipo_assento;
  const precoAntigo = log.valor_antigo;
  const precoNovo = log.valor_novo;

  return `${template} de ${cidadeOrigem} - ${UFOrigem} para ${cidadeDestino} -
   ${UFDestino} na classe ${classe} de R$ ${precoAntigo} para R$ ${precoNovo}`;
};

const formatLog = (log, tipo) => {
  const logCopy = { ...log };
  logCopy.description = getDescription(tipo, logCopy);
  logCopy.timestamp = tipo === 'CRIADO' ? logCopy.created_at : logCopy.updated_at;
  return logCopy;
};

export const formatLogs = (logs) => {
  const formattedLogs = [];
  logs.forEach((log) => {
    if (log.status !== 'PENDENTE') {
      formattedLogs.push(formatLog(log, log.status));
    }
    formattedLogs.push(formatLog(log, 'CRIADO'));
  });
  return formattedLogs;
};
