/* eslint-disable max-len */
import {
  computed,
  effectScope,
  watch,
  reactive,
  toRefs,
} from 'vue';
import {
  useEventListener,
  tryOnScopeDispose,
  useResizeObserver,
  unrefElement,
} from '@vueuse/core';
import { shouldBeOneOf } from 'vue-prop-validation-helper';

import { POSITION_OPTIONS } from '@assets/constants/positions';

export const useElementPosition = (surfaceEl, options = {}) => {
  const scope = effectScope();

  const $state = reactive({
    position: computed(() => options?.position || 'bottom'),
    surfaceBounding: undefined,
    containerIndicator: undefined,
    style: undefined,
  });

  const updateContainerIndicator = () => {
    const newIndicator = {
      tx: 0.5,
      ty: 0.5,
      translateX: -50,
      translateY: -50,
    };
    if ($state.position.includes('left')) {
      newIndicator.tx = 0;
      newIndicator.translateX = 0;
    } else if ($state.position.includes('right')) {
      newIndicator.tx = 1;
      newIndicator.translateX = -100;
    }

    if ($state.position.includes('top')) {
      newIndicator.ty = 0;
      newIndicator.translateY = -100;
    } else if ($state.position.includes('bottom')) {
      newIndicator.ty = 1;
      newIndicator.translateY = 0;
    }

    $state.containerIndicator = newIndicator;
  };

  const update = () => {
    const el = unrefElement(surfaceEl);
    $state.surfaceBounding = el.getBoundingClientRect();

    if ($state.position) shouldBeOneOf(POSITION_OPTIONS)($state.position);
    if (!$state.containerIndicator) return;
    if (!$state.surfaceBounding) return;

    const left = (1 - $state.containerIndicator.tx)
    * $state.surfaceBounding.left + $state.containerIndicator.tx * $state.surfaceBounding.right;
    const top = (1 - $state.containerIndicator.ty)
    * $state.surfaceBounding.top + $state.containerIndicator.ty * $state.surfaceBounding.bottom;

    const transform = `translateX(${$state.containerIndicator.translateX}%) translateY(${$state.containerIndicator.translateY}%)`;
    $state.style = {
      left: `${left}px`,
      top: `${top}px`,
      transform,
      position: 'fixed',
      width: options.sameSurfaceWidth ? `${$state.surfaceBounding.width}px` : undefined,
    };
  };

  const runner = () => {
    watch(() => $state.position, updateContainerIndicator, { immediate: true });

    watch(() => $state.containerIndicator, update, { immediate: true });

    useEventListener('scroll', update, { passive: true, capture: true });
    useEventListener('resize', update, { passive: true });

    if (surfaceEl) useResizeObserver(surfaceEl, update);

    // watch(() => unrefElement(surfaceEl), (ele) => !ele && update());
  };

  const runScope = () => {
    scope.run(runner);
  };

  const cleanupScope = () => {
    if (scope.cleanups?.length) {
      scope.cleanups.forEach((clean) => clean());
      scope.cleanups = [];
    }
  };

  const watcher = watch([() => options.isActive, surfaceEl], ([valueIsActive, valueEl]) => {
    const toActive = valueIsActive && Boolean(valueEl);
    cleanupScope();
    if (toActive) runScope();
  }, { immediate: true });

  tryOnScopeDispose(() => {
    cleanupScope();
    watcher();
  });

  return toRefs($state);
};
