import { CompanyAPI } from '@api';

const defaultPaginator = {
  descending: false,
  page: 1,
  sortBy: '-datetime_ida',
};

const getGroupsGrades = ({ page, descending, sortBy } = defaultPaginator) => CompanyAPI.getGroupsGrades({
  paginator: {
    descending,
    page,
    sortBy,
  },
})
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response));

export { getGroupsGrades };
