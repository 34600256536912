import { AuthAPI } from '@api';

const signIn = async (userId, password) => {
  const params = { id: userId, password };

  return AuthAPI.signIn(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { signIn };
