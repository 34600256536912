import { getDateIsoFormat } from '@utils/date/get-date-iso-format';

const getDefaultWallets = () => {
  const today = new Date();
  const weekFromNow = new Date();
  weekFromNow.setDate(weekFromNow.getDate() + 7);

  return {
    frete: {
      saldo: undefined,
      data: undefined,
      title: 'SALDO FRETE',
      showMeTheMoney: false,
      isExtractLoading: false,
      saqueBloqueado: true,
      motivoSaqueBloqueado: true,
      saldoSacavel: null,
    },
    dias_parados: {
      saldo: undefined,
      data: undefined,
      title: 'SALDO DIAS PARADOS',
      showMeTheMoney: false,
      isExtractLoading: false,
      saqueBloqueado: undefined,
      motivoSaqueBloqueado: true,
      saldoSacavel: null,
    },
    bonus: {
      saldo: undefined,
      data: undefined,
      title: 'SALDO BÔNUS',
      showMeTheMoney: false,
      isExtractLoading: false,
      saqueBloqueado: true,
      motivoSaqueBloqueado: true,
      saldoSacavel: null,
    },
    pedagio: {
      saldo: undefined,
      data: undefined,
      title: 'SALDO PEDÁGIO',
      showMeTheMoney: false,
      isExtractLoading: false,
      saqueBloqueado: true,
      motivoSaqueBloqueado: true,
      saldoSacavel: null,
    },
    emprestimo: {
      saldo: undefined,
      data: undefined,
      title: 'SALDO EMPRÉSTIMO',
      showMeTheMoney: false,
      isExtractLoading: false,
      saqueBloqueado: true,
      motivoSaqueBloqueado: true,
      saldoSacavel: null,
    },
    marketplace: {
      saldo: undefined,
      data: undefined,
      title: 'REPASSE RODOVIÁRIA',
      showMeTheMoney: false,
      isExtractLoading: false,
      saqueBloqueado: true,
      motivoSaqueBloqueado: true,
      initialDate: getDateIsoFormat(today),
      endDate: getDateIsoFormat(weekFromNow),
      saldoSacavel: null,
    },
  };
};

export const state = () => ({
  walletsData: getDefaultWallets(),
  currentActiveWalletName: 'frete',
  isLoading: true,
  isSacarButtonLoading: true,
  isSacarModalOpen: true,
  isLoadingFinancialPenalties: false,
  financialPenalties: null,
  howManyDaysFilter: 30,
});
