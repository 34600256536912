import { CompanyAPI } from '@api';

const getDriverGroups = (driverId, groupId, datetimeStart, duration) => {
  const params = {
    driver_id: driverId,
    group_id: groupId,
    datetime_start: datetimeStart,
    duration,
  };

  return CompanyAPI.getDriverGroups(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { getDriverGroups };
