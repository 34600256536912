<template>
  <component
    :is="$props.tag"
    ref="buttonRef"
    class="b-button mdc-button"
    :type="$props.type"
    :disabled="isDisabled || isLoading"
    :class="{
      'b-button--behavior-block': $props.block,
      'b-button--behavior-normalcase': !$props.uppercase,
      'mdc-button--touch': $props.isAccessible,
      'mdc-button--raised': $props.variant === 'contained',
      'mdc-button--outlined': $props.variant === 'outlined',
      'mdc-button--unelevated': $props.variant === 'unelevated',
      'mdc-button--icon-leading': $props.iconLeft,
      'mdc-button--icon-trailing': $props.iconRight,
      [`mdc-button--custom-color-${$props.color}`]: $props.color,
      [`mdc-button--custom-behavior-${$props.behavior}`]:
        $props.behavior !== 'default',
      [`mdc-button--custom-behavior-isLoading`]: $props.isLoading,
    }"
    :data-test="dataTest"
    @click="handleClick($event)"
  >
    <span class="mdc-button__ripple" />

    <i
      v-if="$props.iconLeft"
      class="material-icons mdc-button__icon"
      aria-hidden="true"
    >{{ $props.iconLeft }}</i>

    <span class="mdc-button__label b-button__label"><slot /></span>

    <i
      v-if="$props.iconRight"
      class="material-icons mdc-button__icon"
      aria-hidden="true"
    >{{ $props.iconRight }}</i>

    <div
      v-if="isLoading"
      class="mdc-button__custom-loading"
    >
      <b-loading
        class="mdc-button__custom-loading-component"
        size="large"
      />
    </div>
  </component>
</template>

<script setup>
/* eslint-disable no-new */
import { BLoading } from '@components/ui/atoms/b-loading';
import { onMounted, ref } from 'vue';
import { ripple } from 'material-components-web';
import { shouldBeOneOf } from 'vue-prop-validation-helper';

const $props = defineProps({
  behavior: {
    type: String,
    default: 'default',
  },
  color: {
    type: String,
    default: 'primary',
    validator: shouldBeOneOf([
      'primary',
      'inverse',
      'success',
      'info',
      'danger',
      'warn',
      'inverse',
      'black',
      'grey',
    ]),
  },
  tag: {
    type: String,
    default: 'button',
    validator: shouldBeOneOf([
      'button',
      'a',
      'router-link',
      'label',
    ]),
  },
  variant: {
    type: String,
    default: 'contained',
    validator: shouldBeOneOf(['text', 'contained', 'outlined', 'unelevated']),
  },
  iconRight: {
    type: String,
    default: null,
  },
  iconLeft: {
    type: String,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isAccessible: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
    validator: shouldBeOneOf(['button', 'submit']),
  },
  link: {
    type: String,
    default: null,
  },
  block: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: String,
    default: null,
  },
  uppercase: {
    type: Boolean,
    default: undefined,
  },
});

const $emit = defineEmits(['click']);

const buttonRef = ref();

const handleClick = ($event) => {
  if ($props.link) window.open($props.link);
  if (!$props.isLoading) $emit('click', $event);
};

onMounted(() => {
  const elementRef = $props.tag === 'router-link' ? buttonRef?.value?.$el : buttonRef.value;

  new ripple.MDCRipple(elementRef);
});

</script>

<style lang="scss">
:root {
  --b-button--font-weight: 500;
  --b-button--height: var(--size-progressive-mega);
  --b-button--border-radius: var(--border-radius-normal);
  --b-button__label--white-space: nowrap;
  --b-button__label--overflow: hidden;
  --b-button__label--text-overflow: ellipsis;
}

.b-button {
  --mdc-protected-button-label-text-weight: var(--b-button--font-weight);
  --mdc-text-button-label-text-weight: var(--b-button--font-weight);
  --mdc-filled-button-label-text-weight: var(--b-button--font-weight);

  --mdc-text-button-container-height: var(--b-button--height);
  --mdc-filled-button-container-height: var(--b-button--height);
  --mdc-outlined-button-container-height: var(--b-button--height);

  --mdc-filled-button-container-shape: var(--b-button--border-radius);
  --mdc-outlined-button-container-shape: var(--b-button--border-radius);

  white-space: nowrap;
  width: fit-content;

  &--behavior {
    &-block {
      width: 100%;
    }
    &-normalcase {
      --mdc-protected-button-label-text-transform: none;
      --mdc-text-button-label-text-transform: none;
      --mdc-filled-button-label-text-transform: none;
      --mdc-outlined-button-label-text-transform: none;
    }
  }
}

.b-button__label {
  white-space: var(--b-button__label--white-space);
  text-overflow: var(--b-button__label--text-overflow);
  overflow: var(--b-button__label--overflow);
}

.mdc-button--custom-behavior {
  &-isLoading > *:not(.mdc-button__custom-loading):not(.mdc-button__ripple) {
    opacity: 0;
  }
}

.mdc-button--custom-color {
  &-primary {
    --mdc-theme-primary: var(--color-primary);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-success {
    --mdc-theme-primary: var(--color-green-scale-400);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-warn {
    --mdc-theme-primary: var(--color-amber-scale-400);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-info {
    --mdc-theme-primary: var(--color-blue-scale-400);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-danger {
    --mdc-theme-primary: var(--color-red-scale-800);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-inverse {
    --mdc-theme-primary: var(--color-white);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-black {
    --mdc-theme-primary: var(--color-black);
    --mdc-theme-on-primary: var(--color-white);
    --b-loading__spinner--stroke: var(--color-white);
  }
  &-grey {
    --mdc-theme-primary: var(--color-grey-scale-300);
    --mdc-theme-on-primary: var(--color-blue-grey-scale-900);
    --b-loading__spinner--stroke: var(--color-blue-grey-scale-800);
  }
}

.mdc-button__custom-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}
</style>
