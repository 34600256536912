import { CompanyAPI } from '@api/buser-http-client';

const saque = (saquePayload) => {
  const payload = {
    value: saquePayload.value,
    password: saquePayload.password,
    tipo_conta: saquePayload.tipoConta,
    bank_account: {
      id: saquePayload.bankAccount?.id,
      bank_name: saquePayload.bankAccount.bankName,
      bank_code: saquePayload.bankAccount.bankCode,
      agencia: saquePayload.bankAccount.agencia,
      agencia_dv: saquePayload.bankAccount.agenciaDV || '',
      conta: saquePayload.bankAccount.conta,
      conta_dv: saquePayload.bankAccount.contaDV,
      document_number: saquePayload.bankAccount.documentNumber,
      legal_name: saquePayload.bankAccount.legalName,
    },
  };
  return CompanyAPI.saque(payload)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};
export { saque };
