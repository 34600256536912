<template>
  <b-menu-item
    class="app-header-section-session-profile"
    :clickable="false"
  >
    <b-avatar
      :src="$session?.currentUser?.photo_url"
      size="extra-large"
    />

    <div class="app-header-section-session-profile__description">
      <b-text
        nowrap
        size="small"
        color="primary"
      >
        {{ $session?.currentUser?.name }}
      </b-text>

      <b-text
        nowrap
        size="extra-small"
        color="grey"
      >
        {{ $session?.currentUser?.email }}
      </b-text>
    </div>
  </b-menu-item>
</template>

<script setup>
import { useSession } from '@stores';

const $session = useSession();
</script>

<style lang="scss">
.app-header-section-session-profile {
  padding: var(--size-scalable-medium);
}

.app-header-section-session-profile__description {
  margin-left: var(--size-scalable-large);
}
</style>
