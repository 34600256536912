import { AuthServices } from '@services/auth-services';
import { SessionsPageName, TermsAndConditionsPageName, CompanyProfileName } from '@assets/constants/route-names';
import { filterOnlyVisible } from '@utils/auth/filter-only-visible';
import { useSession } from '@stores';
import { CompanyAPI } from '@api/buser-http-client';

const getDropdownSections = ($router) => {
  const $session = useSession();
  return [
    {
      visible: $session.canDo('COMPANY'),
      text: 'Treinamentos',
      items: [
        {
          text: 'Cursos',
          icon: 'menu_book',
          handler() {
            return CompanyAPI.getCompanyCourseLink()
              .then(({ link }) => {
                window.open(link, '_blank');
              });
          },
        },
      ],
    },
    {
      visible: $session.canDo('COMPANY'),
      text: 'Configurações',
      items: [
        {
          text: 'Cadastro da Empresa',
          icon: 'edit',
          handler() {
            return new Promise((resolve) => {
              resolve($router.push({ name: CompanyProfileName }));
            });
          },
        },
        {
          text: 'Termos e Contratos',
          icon: 'description',
          handler() {
            return new Promise((resolve) => {
              resolve($router.push({ name: TermsAndConditionsPageName }));
            });
          },
        },
      ],
    },
    {
      text: 'Outros',
      items: [
        {
          text: 'Sair',
          icon: 'logout',
          handler() {
            return AuthServices.signOut().then(() => {
              $router.push({ name: SessionsPageName });
            });
          },
        },
      ],
    },
  ]
    .filter(filterOnlyVisible);
};

export { getDropdownSections };
