import { createRouter, createWebHistory } from 'vue-router';
import { MultiMiddlewares } from '@utils/router/multi-middlewares';
import { MultiGuards } from '@utils/router/multi-guards';
import { routes } from './routes';
import { authGuard } from './auth-guard';
import { loggerMiddleware } from './logger-middleware';
import { permissionGuard } from './permission-guard';
import { generalSettingsMiddleware } from './general-settings-middleware';
import { partnerTermsMiddleware } from './partner-terms-middleware';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(
  MultiGuards([
    generalSettingsMiddleware,
    authGuard,
    permissionGuard,
    partnerTermsMiddleware,
  ]),
);

router.afterEach(
  MultiMiddlewares([
    loggerMiddleware,
  ]),
);

export { router };
