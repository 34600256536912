<template>
  <div
    class="b-file__wrapper"
    :class="{
      'b-file__wrapper--behavior-inline': $props.showFileName,
    }"
  >
    <label
      class="b-file"
      @click="handleClick"
    >
      <b-button
        icon-left="attach_file"
        variant="outlined"
        tag="button"
        :is-disabled="$props.isDisabled"
      >
        <slot />
      </b-button>

      <input
        ref="inputRef"
        type="file"
        class="b-file__input"
        :disabled="$props.isDisabled"
        @change="handleFileChange($event.target.files)"
      >
    </label>

    <b-text
      v-if="$props.showFileName"
      class="b-file__filename b-margin-left--medium b-text--size-extra-small"
    >
      {{ $state.selectedFileName || $props.placeholderNoFile }}
    </b-text>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { BButton } from '@components/ui/atoms';
import { getFileName } from '@utils/files/get-file-name';

const inputRef = ref();

const $props = defineProps({
  modelValue: {
    type: [Object, Array, String],
    default: null,
  },
  modelModifiers: {
    default: () => ({}),
    type: Object,
  },
  value: {
    type: [Object, Array],
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  showFileName: {
    type: Boolean,
    default: false,
  },
  placeholderNoFile: {
    type: String,
    default: 'Nenhum arquivo anexado',
  },
});

const $state = reactive({
  selectedFileName: computed(() => {
    if ($props?.modelValue?.name) return $props?.modelValue?.name;
    if ($props.modelModifiers['only-name']) return getFileName($props.modelValue);
    return $props.modelValue;
  }),
});

const $emits = defineEmits(['update:modelValue', 'change']);

const handleFileChange = (files) => {
  const toSend = files?.length > 1 ? files : files[0];
  $emits('update:modelValue', toSend);
  $emits('change', toSend);
};

const handleClick = () => {
  inputRef.value.click();
};
</script>

<style lang="scss">
.b-file__wrapper {
  &--behavior {
    &-inline {
      display: flex;
      align-items: center;
    }
  }
}

.b-file {
  height: fit-content;
  width: fit-content;
  display: block;
}

.b-file__input {
  display: none;
}

.b-file__filename {
  overflow: auto;
}
</style>
