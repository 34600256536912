export const getDaysDifference = (dateOne, dateTwo) => {
  const firstDate = dateOne instanceof Date ? dateOne : new Date(dateOne);
  const secondDate = dateTwo instanceof Date ? dateTwo : new Date(dateTwo);

  const firstDateFormatted = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
  const secondDateFormatted = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

  const diffDays = Math.floor((secondDateFormatted - firstDateFormatted) / (1000 * 60 * 60 * 24));

  return diffDays;
};
