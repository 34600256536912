import { OpsAPI } from '@api/buser-http-client';

const sendMonthlyStatement = async (companyId, pedagios) => {
  const formData = new FormData();

  const filesCompanyMap = {};

  if (pedagios.length) {
    pedagios.forEach((pedagio) => {
      formData.append(pedagio.file?.name, pedagio.file, pedagio.file?.name);
      filesCompanyMap[pedagio.file?.name] = pedagio.tollCompany;
    });
    formData.append('mapa_arquivo_empresas_pedagio', JSON.stringify(filesCompanyMap));
  }

  return OpsAPI.sendMonthlyStatement(companyId, formData)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { sendMonthlyStatement };
