/* eslint-disable no-console */
import { AuthAPI } from '@api';

const signUp = async ({
  email,
  name,
  phone,
  password,
  invitecode = null,
}) => {
  const params = {
    user: JSON.stringify({
      email,
      name,
      phone,
      password,
      invitecode,
    }),
  };

  console.error('Rota do servição não foi ainda implementada.');

  return AuthAPI.signUp(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { signUp };
