import { CompanyAPI } from '@api';

const getGroupsInverseRoute = async (groupId) => {
  const params = {
    group_id: groupId,
  };
  return CompanyAPI.getGroupsInverseRoute(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getGroupsInverseRoute };
