const defaultOptions = {
  additionalCss: null,
  additionalScript: null,
};

export function PrintHTML(html, opts = {}) {
  const options = { ...defaultOptions, ...opts };

  const mywindow = window.open('', 'PRINT');

  mywindow.document.write(html);

  if (options.additionalCss) {
    const style = document.createElement('style');
    style.textContent = options.additionalCss;
    mywindow.document.head.appendChild(style);
  }

  if (options.additionalScript) {
    const script = document.createElement('script');
    script.innerHTML = options.additionalScript;
    mywindow.document.head.appendChild(script);
  }

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.onload = function callPrint() {
    mywindow.print();
  };
}
