import {
  SessionsPageName,
  TermsAndConditionsPageName,
  CompanyProfileName,
  HelpPageName,
  NotificationsPageName,
  ReportsPageName,
  ReportsBusPageName,
  ReportsDriversPageName,
  ReportsTravelsPageName,
  IncidentsPageName,
  NotFoundPageName,
  InvoicePageName,
  FinancialLoansPageName,
  DriversListPageName,
  DriverPageName,
  DriverCreatePageName,
  BusCreatePageName,
  BusPageName,
  BusesListPageName,
  TravelsPageName,
  BPESPageName,
  WalletPageName,
  RefundPageName,
  OpenApiPageName,
  ErrorPageName,
  NotAuthorizedPageName,
  MultaListPageName,
  MultaPageName,
} from '@assets/constants/route-names';
import { Settings } from '@settings';

const SessionsPageLazy = () => import('@pages/sessions-page')
  .then(({ SessionsPage }) => SessionsPage);
const CompanyDocumentsPageLazy = () => import('@pages/company-documents-page')
  .then(({ CompanyDocumentsPage }) => CompanyDocumentsPage);
const CompanyProfilePageLazy = () => import('@pages/company-profile-page')
  .then(({ CompanyProfilePage }) => CompanyProfilePage);
const HelpPageLazy = () => import('@pages/help-page')
  .then(({ HelpPage }) => HelpPage);
const NotificationsPageLazy = () => import('@pages/notifications-page')
  .then(({ NotificationsPage }) => NotificationsPage);
const ReportsPageLazy = () => import('@pages/reports-page')
  .then(({ ReportsPage }) => ReportsPage);
const ReportsBusPageLazy = () => import('@pages/reports-bus-page')
  .then(({ ReportsBusPage }) => ReportsBusPage);
const ReportsDriversPageLazy = () => import('@pages/reports-drivers-page')
  .then(({ ReportsDriversPage }) => ReportsDriversPage);
const ReportsTravelsPageLazy = () => import('@pages/reports-travels-page')
  .then(({ ReportsTravelsPage }) => ReportsTravelsPage);
const TravelsPageLazy = () => import('@pages/travels-page')
  .then(({ TravelsPage }) => TravelsPage);
const BusesListPageLazy = () => import('@pages/buses-list-page')
  .then(({ BusesListPage }) => BusesListPage);
const BusCreatePageLazy = () => import('@pages/bus-create-page')
  .then(({ BusCreatePage }) => BusCreatePage);
const BusPageLazy = () => import('@pages/bus-page')
  .then(({ BusPage }) => BusPage);
const IncidentsPageLazy = () => import('@pages/incidents-page')
  .then(({ IncidentsPage }) => IncidentsPage);
const NotFoundPageLazy = () => import('@pages/not-found-page')
  .then(({ NotFoundPage }) => NotFoundPage);
const InvoicePageLazy = () => import('@pages/invoice-page')
  .then(({ InvoicePage }) => InvoicePage);
const AntecipacaoPage = () => import('@pages/antecipation-payment-page')
.then(({ AntecipacaoPage }) => AntecipacaoPage);
const FinancialLoansPageLazy = () => import('@pages/financial-loans-page')
  .then(({ FinancialLoansPage }) => FinancialLoansPage);
const DriversListPageLazy = () => import('@pages/drivers-list-page')
  .then(({ DriversListPage }) => DriversListPage);
const DriverPageLazy = () => import('@pages/driver-page')
  .then(({ DriverPage }) => DriverPage);
const DriverCreatePageLazy = () => import('@pages/driver-create-page')
  .then(({ DriverCreatePage }) => DriverCreatePage);
const WalletPageLazy = () => import('@pages/wallet-page')
  .then(({ WalletPage }) => WalletPage);
const RefundPageLazy = () => import('@pages/refund-page')
  .then(({ RefundPage }) => RefundPage);
const BPESPageLazy = () => import('@pages/bpes-page')
  .then(({ BPESPage }) => BPESPage);
const OpenapiPageLazy = () => import('@pages/openapi-page')
  .then(({ OpenapiPage }) => OpenapiPage);
const ErrorPageLazy = () => import('@pages/error-page')
  .then(({ ErrorPage }) => ErrorPage);
const NotAuthorizedPageLazy = () => import('@pages/not-authorized-page')
  .then(({ NotAuthorizedPage }) => NotAuthorizedPage);
const MultaListPageLazy = () => import('@pages/multa-list-page')
  .then(({ MultaListPage }) => MultaListPage);
const MultaPageLazy = () => import('@pages/multa-page')
  .then(({ MultaPage }) => MultaPage);

const routes = [
  {
    path: '/',
    redirect: '/viagens',
  },
  {
    path: '/sessions',
    component: SessionsPageLazy,
    name: SessionsPageName,
    meta: {
      layout: {
        name: 'PinkEmptyLayout',
      },
    },
  },
  {
    path: '/documentos-importantes',
    component: CompanyDocumentsPageLazy,
    name: TermsAndConditionsPageName,
  },
  {
    path: '/perfil-empresa',
    component: CompanyProfilePageLazy,
    name: CompanyProfileName,
  },
  {
    path: '/incidentes',
    component: IncidentsPageLazy,
    name: IncidentsPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
    },
  },
  {
    path: '/ajuda',
    component: HelpPageLazy,
    name: HelpPageName,
  },
  {
    path: '/avisos',
    component: NotificationsPageLazy,
    name: NotificationsPageName,
  },
  {
    path: '/motoristas',
    component: DriversListPageLazy,
    name: DriversListPageName,
  },
  {
    path: '/motorista/:driverId',
    component: DriverPageLazy,
    name: DriverPageName,
  },
  {
    path: '/motorista/create',
    component: DriverCreatePageLazy,
    name: DriverCreatePageName,
  },
  {
    path: '/relatorios',
    component: ReportsPageLazy,
    name: ReportsPageName,
  },
  {
    path: '/relatorios/onibus',
    component: ReportsBusPageLazy,
    name: ReportsBusPageName,
  },
  {
    path: '/relatorios/motoristas',
    component: ReportsDriversPageLazy,
    name: ReportsDriversPageName,
  },
  {
    path: '/relatorios/viagens',
    component: ReportsTravelsPageLazy,
    name: ReportsTravelsPageName,
  },
  {
    path: '/onibus/create',
    component: BusCreatePageLazy,
    name: BusCreatePageName,
  },
  {
    path: '/onibus/:busId',
    component: BusPageLazy,
    name: BusPageName,
  },
  {
    path: '/onibus',
    component: BusesListPageLazy,
    name: BusesListPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
    },
  },
  {
    path: '/viagens',
    component: TravelsPageLazy,
    name: TravelsPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
    },
  },
  {
    path: '/viagens/:groupId/bpes',
    component: BPESPageLazy,
    name: BPESPageName,
  },
  {
    path: '/financeiro/notas-fiscais',
    name: InvoicePageName,
    component: InvoicePageLazy,
    meta: {
      layout: {
        widthFullWidth: true,
      },
      permissions: {
        needNFEnabled: true,
      },
    },
  },
  {
    path: '/financeiro/emprestimos',
    name: FinancialLoansPageName,
    component: FinancialLoansPageLazy,
    meta: {
      layout: {
        widthFullWidth: true,
      },
      permissions: {
        items: ['CARTEIRA_PARCEIRO'],
      },
    },
  },
  {
    path: '/financeiro/carteira',
    component: WalletPageLazy,
    name: WalletPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
      permissions: {
        items: ['CARTEIRA_PARCEIRO'],
      },
    },
  },
  {
    path: '/financeiro/reembolso',
    component: RefundPageLazy,
    name: RefundPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
      permissions: {
        items: ['CARTEIRA_PARCEIRO'],
      },
    },
  },
  {
    path: '/financeiro/multas',
    name: MultaListPageName,
    component: MultaListPageLazy,
    meta: {
      layout: {
        widthFullWidth: true,
      },
      permissions: {
        items: ['CARTEIRA_PARCEIRO'],
      },
    },
  },
  {
    path: '/financeiro/multas/:multaId',
    name: MultaPageName,
    component: MultaPageLazy,
    meta: {
      permissions: {
        items: ['CARTEIRA_PARCEIRO'],
      },
    },
  },
  {
    path: '/error',
    component: ErrorPageLazy,
    name: ErrorPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
    },
  },
  {
    path: '/not-authorized',
    component: NotAuthorizedPageLazy,
    name: NotAuthorizedPageName,
    meta: {
      layout: {
        widthFullWidth: true,
      },
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: NotFoundPageName,
    component: NotFoundPageLazy,
    meta: {
      layout: {
        widthFullWidth: true,
      },
    },
  },
  {
    path: '/financeiro/antecipacao-pagamento',
    name: AntecipacaoPage,
    component: AntecipacaoPage,
    meta: {
      layout: {
        widthFullWidth: true,
      },
      permissions: {
        items: ['CARTEIRA_PARCEIRO'],
      },
    },
  },
];

if (Settings.isDevelopment) {
  routes.push({
    path: '/openapi',
    component: OpenapiPageLazy,
    name: OpenApiPageName,
  });
}

export { routes };
