import { CompanyAPI } from '@api';

const getCompanyMonthInvoices = async (date, orderBy, orderDescending) => {
  const params = { data: date, orderBy, orderDescending };
  return CompanyAPI.getCompanyMonthInvoices(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getCompanyMonthInvoices };
