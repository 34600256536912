<template>
  <div
    class="app-header-section-logo"
    @click="handleClick"
  >
    <img
      class="app-header-section-logo__image"
      src="@assets/images/logo_parceiro.svg"
    >
  </div>
</template>

<script setup>
import { HomeRedirect } from '@assets/constants/route-names';

const handleClick = () => {
  window.location.href = HomeRedirect;
};
</script>

<style lang="scss">
.app-header-section-logo__image {
  height: var(--size-scalable-large);
  cursor: pointer;
}
</style>
