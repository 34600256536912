const percentFormatConfig = {
  debug: false,
  masked: false,
  prefix: '',
  suffix: '',
  thousands: '.',
  decimal: ',',
  precision: 2,
  disableNegative: false,
  disabled: false,
  min: 0,
  max: 100,
  allowBlank: false,
  minimumNumberOfCharacters: 0,
  modelModifiers: {
    number: true,
  },
};

export { percentFormatConfig };
