import { CompanyAPI } from '@api';

const updateCompanyStateDocument = (autorizationDoc, expireDate, docId, uploadingTA, uploadingTAS) => {
  const params = new FormData();
  params.append('expire_date', expireDate);
  if (docId) {
    params.append('doc_id', docId);
  }

  if (autorizationDoc && uploadingTA) {
    params.append('ta', autorizationDoc, autorizationDoc.name);
  }
  if (autorizationDoc && uploadingTAS) {
    params.append('tas', autorizationDoc, autorizationDoc.name);
  }
  return CompanyAPI.updateCompanyStateDocument(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { updateCompanyStateDocument };
