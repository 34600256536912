import { defineStore } from 'pinia';
import { createCopy } from '@utils/object/create-copy';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { isEqual } from '@utils/object/is-equal';
import { useInterface } from '@stores';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelTollEditModal = defineStore('TravelTollEditModal', {
  state: () => ({
    isLoading: false,
    isModalOpen: false,
    group: null,
    file: null,
    suggestedValue: null,
    confirmDialogIsOpen: false,
    sameValueSimilarTravels: false,
  }),
  getters: {
    hasChanged(state) {
      return !isEqual(state.group.pedagio.valor_parceiro, state.suggestedValue);
    },
  },
  actions: {
    open(group) {
      this.group = createCopy(group);
      this.suggestedValue = this.group.pedagio ? this.group.pedagio.valor_parceiro : null;
      this.isModalOpen = true;
      this.confirmDialogIsOpen = false;

      EventLogger.logEvent(events.openModalTollEdit);
    },
    checkFileType(file) {
      if (file.type !== 'application/pdf') {
        this.file = null;
        useInterface().toast.add({
          label: 'Arquivos somente em PDF',
          color: 'danger',
        });
      }
    },
    close() {
      this.$reset();
    },
    openConfirmDialog() {
      this.confirmDialogIsOpen = true;
    },
    closeConfirmDialog() {
      this.isLoading = false;
      this.confirmDialogIsOpen = false;
    },
  },
});
