<template>
  <div
    class="app-sidebar-item"
  >
    <button
      v-wave
      class="app-sidebar-item__button"
      :class="{
        [`app-sidebar-item__button--is-active`]: isCurrentRoute($props.url)
      }"
      @click="handleClickButton($props.url, $props.items?.length)"
    >
      <b-icon
        :icon="$props.icon"
        :color="isCurrentRoute($props.url) || $props.submenuIsOpen ? 'primary' : 'inherit'"
      />

      <b-text
        size="extra-small"
        weight="medium"
        class="app-sidebar-item__text app-sidebar-item__text--item"
        :color="isCurrentRoute($props.url) ? 'secondary' : 'primary'"
      >
        {{ $props.text }}
      </b-text>

      <b-icon
        v-if="$props.items"
        icon="keyboard_arrow_down"
        class="app-sidebar-item__icon"
        :class="{
          [`app-sidebar-item__icon--is-open`]: $props.submenuIsOpen,
        }"
      />
    </button>

    <div
      v-if="$props.items"
      ref="appSidebarSubmenu"
      class="app-sidebar-item__submenu"
      :class="{
        [`app-sidebar-item__submenu--is-open`]: $props.submenuIsOpen,
      }"
    >
      <button
        v-for="(element, index) in $props.items"
        :key="index"
        v-wave
        class="app-sidebar-item__button"
        :class="{
          [`app-sidebar-item__button--is-active`]: isCurrentRoute(element.url)
        }"
        @click="handleClickButton(element.url)"
      >
        <b-text
          size="extra-small"
          weight="medium"
          class="app-sidebar-item__text app-sidebar-item__text--sub-item"
        >
          {{ element.text }}
        </b-text>

        <b-icon
          :icon="element.icon"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const $router = useRouter();
const appSidebarSubmenu = ref();

const $props = defineProps({
  url: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    default: null,
  },
  submenuIsOpen: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(['click', 'change-submenu']);

const handleClickButton = (url, hasMenu = false) => {
  if (hasMenu) return $emit('change-submenu', !$props.submenuIsOpen);
  $emit('click');
  return $router.push({ path: url });
};

const isCurrentRoute = (url) => Boolean(url === window.location.pathname);

onMounted(() => {
  const hasSubmenuActiveSubmenu = appSidebarSubmenu
    .value
    ?.querySelectorAll('.app-sidebar-item__button--is-active')
    .length;

  if (hasSubmenuActiveSubmenu) $emit('change-submenu', true);
});
</script>

<style lang="scss">
.app-sidebar-item__button {
  padding: var(--size-scalable-medium);
  display: flex;
  align-items: center;
  width: 100%;
  --b-text--color: var(--color-black);
  --b-icon--color: var(--grey);

  &--is-active {
    --b-text--color: var(--color-primary);
    --b-icon--color: var(--color-primary);
    background: rgba(0,0,0,0.04);
  }
}

.app-sidebar-item__text {
  display: flex;
  align-items: center;

  &--item {
    margin-left: var(--size-scalable-large);
  }
  &--sub-item {
    margin-left: var(--size-scalable-extra-jumbo);
    margin-right: auto;
  }
}

.app-sidebar-item__icon {
  transition: transform 0.25s ease-in-out;
  margin-left: auto;

  &--is-open {
    transform: rotate(180deg);
  }
}

.app-sidebar-item__submenu {
  max-height: 0;
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  &--is-open {
    max-height: 1000px;
  }
}
</style>
