/* eslint-disable camelcase */
import { CompanyAPI } from '@api';

const getSaldoFuturoMarketplace = (start_date, end_date) => CompanyAPI.getSaldoFuturoMarketplace(
  { start_date, end_date },
)
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error));

export { getSaldoFuturoMarketplace };
