import { defineStore } from 'pinia';

export const useAddressesModalStore = defineStore('useAddressesModalStore', {
  state: () => ({
    garages: [],
  }),
  actions: {
    addGarage() {
      this.garages.push({
        id: null,
        endereco_cep: null,
        cidade: { id: null, name: null, uf: null },
        endereco_logradouro: null,
        endereco_numero: null,
        endereco_complemento: null,
        endereco_bairro: null,
      });
    },
    setGarages(garages) {
      if (garages?.length) {
        this.garages = garages;
      } else {
        this.garages = [{
          id: null,
          endereco_cep: null,
          cidade: { id: null, name: null, uf: null },
          endereco_logradouro: null,
          endereco_numero: null,
          endereco_complemento: null,
          endereco_bairro: null,
        }];
      }
    },
  },
});
