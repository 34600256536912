/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { Settings } from '@settings';
import * as Sentry from '@sentry/vue';

axios.defaults.xsrfHeaderName = 'x-csrftoken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const buserHttpClient = axios.create({
  baseURL: `${Settings.envs.BASE_URL}/api`,
  timeout: 60000,
});

function captureEvent({ msg, tags, extra }) {
  const event = {
    message: msg, level: 'error', tags, extra,
  };
  Sentry.captureEvent(event);
}

const urlToEndpoint = (url) => {
  const endpoint = [];
  for (const part of url.split('/')) {
    if (/^\d+$/.test(part)) {
      endpoint.push('(\d+)');
    } else {
      endpoint.push(part);
    }
  }
  return endpoint.join('/')
}

/// Lista de tuplas de (método HTTP, endpoint, status code) que os erros são ignorados (não enviados para o Sentry)
const _axiosCatchIgnoreList = [
  ["POST", "/v1/company/groups/set_bus", 400],
];

const shouldIgnoreEndpointAndStatus = (method, endpoint, status) =>
  _axiosCatchIgnoreList.some((item) => item[0] === method && item[1] === endpoint && item[2] == status);


function axiosCatch(error, request) {
    Sentry.withScope((scope) => {
      const method = request.requestMethod;
      const endpoint = urlToEndpoint(error.config.url);
      const extra = {
        url: request.url,
      };

      if (error?.response) {
        const status = error.response.status;
        if (status === 400 || status === 401) {
          return; // ignore 400 and 401 Unauthorized errors
        }

        if (shouldIgnoreEndpointAndStatus(method, endpoint, status)) {
          console.warn(`Suppressing axios error: ${method} ${endpoint} ${status}`)
          return;
        }

        const content = JSON.stringify(error.response.data).substring(0, 300);

        extra.content = content;

        scope.setFingerprint([error.config.method, endpoint, String(status)]);
        scope.setTags({
          status,
        });

        error.message = `Request failed: ${endpoint} ${status}`;
      } else {
        scope.setFingerprint([error.config.method, endpoint]);
        error.message = `Request failed: ${endpoint}`;
      }

      Sentry.captureException(error, { data: extra }, scope);
  })
}

buserHttpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 403 && error?.response?.data?.code === 'csrf') {
      await buserHttpClient.get('/csrf');
      return buserHttpClient.request(error.config);
    }
    axiosCatch(error, error.request);
    return Promise.reject(error);
  },
);

export { buserHttpClient };
