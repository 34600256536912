import { OpsAPI } from '@api/buser-http-client';

const suggestNewTollValue = async (tollId, travelStatement, suggestedValue, sameValueSimilarTravels) => {
  const formData = new FormData();

  formData.append('suggested_value', suggestedValue);
  formData.append('similar_travels', sameValueSimilarTravels);

  if (travelStatement) {
    formData.append('travel_statement', travelStatement, travelStatement.name);
  }

  await OpsAPI.suggestNewTollValue(tollId, formData)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error.response?.data || error.response));
};

export { suggestNewTollValue };
