<template>
  <b-button
    class="b-tab"
    :class="{
      'b-tab--is-active': isCurrentTab,
    }"
    variant="unelevated"
    :color="color"
    uppercase
    @click="handleChange"
  >
    {{ label }}<slot />
  </b-button>
</template>

<script setup>
import { inject, computed } from 'vue';
import { BTabsKey } from './b-tabs-key';

const $props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  value: {
    type: [String, Number],
    required: true,
  },
});

const { currentTab, handleTabsChange, color } = inject(BTabsKey);
const $emits = defineEmits(['change']);

const handleChange = () => {
  $emits('change', $props.value);
  handleTabsChange($props.value);
};
const isCurrentTab = computed(() => currentTab.value === $props.value);
</script>

<style lang="scss">

.b-tab {
  --b-button--font-weight: 600;
  --b-button--height: auto;
  --b-button--border-radius: none;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-transform: uppercase;

  &:not(.b-tab--is-active) .b-button__label{
    opacity: 0.9;
  }
}
</style>
