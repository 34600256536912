import { CompanyAPI } from '@api';

const getCompanyBuses = async (option = null) => {
  const params = { allfields: true, only_available: option?.onlyAvailableBuses };

  return CompanyAPI.getCompanyBuses(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { getCompanyBuses };
