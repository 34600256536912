import { CompanyAPI } from '@api';

const updateGroupPrices = (groupIds, payload, updateInBatches) => {
  const params = {
    groupIds,
    payload,
    updateInBatches,
  };

  return CompanyAPI.updateGroupPrices(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { updateGroupPrices };
