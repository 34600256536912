/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
import { defineStore } from 'pinia';
import { stepsDictionary } from '@modules/travels/travel-authorizations/authorization-antt/steps-dictionary';
import { CompanyServices } from '@services/company-services';
import { comparePhrases } from '@utils/strings';
import { EventLogger } from '@utils/event-metrics';
import { useInterface } from '@stores';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { PrintHTML } from '@utils/print/print-html';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelAnttModal = defineStore('TravelAnttModal', {
  state: () => ({
    interfaceState: useInterface(),
    modalSize: 'small',
    isModalOpen: false,
    actualStep: null,
    group: null,
    anttPassword: null,
    anttCNPJ: null,
    isPrinting: false,
    isListAnttDriversLoading: false,
    isGenerateAnttLoading: false,
    isPrintAnttLoading: false,
    isInvalidForm: false,
    isInvalidTravelInfoForm: false,
    error: null,
    groupsTravelBack: null,
    selectedGroupTravelBack: null,
    driversAntt: null,
    selectedDriverOne: null,
    selectedDriverTwo: null,
    anttInvoiceValue: null,
    anttInvoiceSeries: null,
    anttInvoiceNumber: null,
    anttPreview: null,
    anttResponseNumber: null,
  }),
  getters: {
    currentStepComponent(state) {
      return stepsDictionary[state.actualStep];
    },
    errorStatusIsTimeout() {
      return this?.error?.response?.status === 504;
    },
  },
  actions: {
    open(group, cnpj) {
      this.actualStep = 'AnttLogin';
      this.group = group;
      this.anttCNPJ = cnpj || null;
      this.isModalOpen = true;
      this.isInvalidForm = false;
    },
    close() {
      this.$reset();
    },
    getLikelyAnttDriver(groupDriver) {
      let likelyDriver = null;
      let max = 0;

      this.driversAntt.forEach((driver) => {
        const similarity = comparePhrases(driver.nome, groupDriver.name);

        if (similarity > max && similarity > 0.4) {
          max = similarity;
          likelyDriver = driver;
        }
      });

      return likelyDriver;
    },
    listAnttDrivers() {
      this.isListAnttDriversLoading = true;

      const params = {
        group_id: this.group.id,
        license_plate: this.group.onibus.placa,
        antt_cnpj: this.anttCNPJ,
        antt_password: this.anttPassword,
      };

      CompanyServices.listAnttDrivers(params)
        .then((response) => {
          const {
            drivers,
            groups,
          } = response;

          this.driversAntt = drivers;
          this.groupsTravelBack = groups;

          if (this.group.driver_one) this.selectedDriverOne = this.getLikelyAnttDriver(this.group.driver_one);
          if (this.group.driver_two) this.selectedDriverTwo = this.getLikelyAnttDriver(this.group.driver_two);

          // no front antigo tinha esse valor default de 1k... Vamos manter?
          this.anttInvoiceValue = this.group?.notafiscal?.valor || 1000;
          this.anttInvoiceNumber = this.group?.notafiscal?.numero;
          this.anttInvoiceSeries = this.group?.notafiscal?.serie || 1;

          this.actualStep = 'AnttInfos';
        })
        .catch((error) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.isListAnttDriversLoading = false;
        });
    },
    generateAntt() {
      this.isGenerateAnttLoading = true;

      const params = {
        group_id: this.group.id,
        license_plate: this.group.onibus.placa,
        antt_cnpj: this.anttCNPJ,
        antt_password: this.anttPassword,
        invoice_number: this.anttInvoiceNumber,
        invoice_serie: this.anttInvoiceSeries,
        invoice_value: this.anttInvoiceValue,
        driver_one: this.selectedDriverOne,
        driver_two: this.selectedDriverTwo,
        return_group_id: this.selectedGroupTravelBack?.id,
      };

      CompanyServices.generateAntt(params)
        .then((response) => {
          if (response.error) {
            this.interfaceState.toast.add({
              label: response.error,
              color: 'danger',
            });

            EventLogger.logEvent(events.errorGenerateAntt, {
              groupId: this.group?.id,
            });
          } else {
            const { preview, antt_number } = response;

            this.anttPreview = preview;
            this.anttResponseNumber = antt_number;

            this.actualStep = 'AnttPreview';
            this.modalSize = 'medium';
          }
        })
        .catch((error) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });

          EventLogger.logEvent(events.errorGenerateAntt, {
            groupId: this.group?.id,
          });
        })
        .finally(() => {
          this.isGenerateAnttLoading = false;
        });
    },
    printAntt() {
      this.isPrintAnttLoading = true;

      const params = {
        group_id: this.group.id,
        antt_password: this.anttPassword,
      };

      CompanyServices.printAntt(params)
        .then((response) => {
          if (response.error) {
            this.error = response.error;

            this.interfaceState.toast.add({
              label: response.error,
              color: 'danger',
            });

            EventLogger.logEvent(events.errorToPrintAntt, {
              groupId: this.group?.id,
            });
          } else {
            const html = response.html;
            const additionalCss = 'table, p, p > * { font-size: small } body { margin: 0 }';
            PrintHTML(html, { additionalCss });

            const TravelsPageStore = useTravelsPage();
            TravelsPageStore.requestGroups();
          }
        })
        .catch((error) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });

          EventLogger.logEvent(events.errorToPrintAntt, {
            groupId: this.group?.id,
          });
        })
        .finally(() => {
          this.isPrintAnttLoading = false;
        });
    },
    editOnANTT() {
      const anttUrl = 'https://appweb1.antt.gov.br/autorizacaoDeViagem/AvPublico/Inicial.asp';

      window.open(anttUrl, '_blank');
    },
    backToBeginning() {
      this.actualStep = 'AnttLogin';
      this.anttPassword = null;
      this.selectedGroupTravelBack = null;
      this.modalSize = 'small';

      EventLogger.logEvent(events.startAnttFlowAgain, {
        groupId: this.group?.id,
      });
    },
  },
});
