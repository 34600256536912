import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { WalletPageName } from '@assets/constants/route-names';
import { notEmpty } from '@utils/validations/not-empty';
import { useInterface } from '@stores/app';
import { CompanyServices } from '@services/company-services';
import { stepsDictionary } from '@modules/wallet/wallet-sacar-modal/steps-dictionary';
import { useWalletPageStore } from '@stores';


const ERROR_CODE_PENDING_TERM = 'PENDING_TERMS'
const events = EVENTS_DICTIONARY[WalletPageName];

const getDefaultSacarOptions = () => ({
  value: undefined,
  bankAccount: {
    id: undefined,
    bankName: undefined,
    bankCode: undefined,
    agencia: undefined,
    agenciaDV: undefined,
    conta: undefined,
    contaDV: undefined,
    documentNumber: undefined,
    legalName: undefined,
  },
  password: undefined,
  tipoConta: undefined,
});

export const useWalletSacarModalStore = defineStore('WalletSacarModalStore', {
  state: () => ({
    isSacarModalOpen: false,
    showPendingTermsErrorModal: false,
    isConfirmTransactionModalOpen: false,
    saqueData: getDefaultSacarOptions(),
    currentStepName: 'SacarForm',
    transaction: null,
    modalSize: 'medium',
    transferToAnotherAccount: false,
    showPassword: false,
  }),
  getters: {
    currentStepComponent(state) {
      return stepsDictionary[state.currentStepName];
    },
  },
  actions: {
    async open() {
      await this.requestBankAccount();
      this.isSacarModalOpen = true;
      EventLogger.logEvent(events.openWithDrawModal);
    },
    close() {
      EventLogger.logEvent(events.closeWithDrawModal);
      this.$reset();
    },
    openSacarConfirmationModal() {
      this.isConfirmTransactionModalOpen = true;
    },
    closeSacarConfirmationModal() {
      this.saqueData.password = undefined;
      this.isConfirmTransactionModalOpen = false;
    },
    handleTransferToAnotherAccount() {
      EventLogger.logEvent(events.clickOnTranferToAnotherAccount);

      this.saqueData.bankAccount = {};
      this.transferToAnotherAccount = true;
    },
    async requestBankAccount() {
      this.isSacarButtonLoading = true;
      const $interfaceState = useInterface();

      return CompanyServices.getBankAccount()
        .then(({ data }) => {
          if (notEmpty(data)) {
            this.saqueData.bankAccount.id = data.id;
            this.saqueData.bankAccount.bankName = data.bank_name;
            this.saqueData.bankAccount.bankCode = data.bank_code;
            this.saqueData.bankAccount.agencia = data.agencia;
            this.saqueData.bankAccount.agenciaDV = data.agencia_dv;
            this.saqueData.bankAccount.conta = data.conta;
            this.saqueData.bankAccount.contaDV = data.conta_dv;
            this.saqueData.bankAccount.documentNumber = data.document_number;
            this.saqueData.bankAccount.legalName = data.legal_name;
          }
          const $WalletPageStore = useWalletPageStore();
          this.saqueData.tipoConta = $WalletPageStore.currentActiveWalletName;
        })
        .catch((error) => {
          $interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.isSacarButtonLoading = false;
        });
    },
    async requestSaque() {
      const $WalletPageStore = useWalletPageStore();
      const $interfaceState = useInterface();

      return CompanyServices.saque(this.saqueData)
        .then((result) => {
          $WalletPageStore.walletsData[$WalletPageStore.currentActiveWalletName].saldo -= this.value;

          this.transaction = result;
          this.modalSize = 'small';
          this.currentStepName = 'SuccessSacar';

          EventLogger.logEvent(events.withDrawSuccessfull);
        })
        .catch(({ error, error_code }) => {
          const defaultError = 'Saque indisponível no momento. Tente novamente mais tarde';

          if (error_code != ERROR_CODE_PENDING_TERM) {
              $interfaceState.toast.add({
                label: error || defaultError,
                color: 'danger',
              });
          }
          this.showPendingTermsErrorModal = error_code == ERROR_CODE_PENDING_TERM


          EventLogger.logEvent(events.withDrawUnsuccessful);
        })
        .finally(() => {
          this.saqueData.password = undefined;
        });
    },
  },
});
