<script>
/* eslint-disable import/first */
export default {
  name: 'BLoading',
  inheritAttrs: false,
};
</script>

<template>
  <template v-if="$props.variant === 'spinner'">
    <svg
      class="b-loading b-loading--variant-spinner"
      :class="{
        [`b-loading--color-${$props.color}`]: $props.color !== 'inherit',
        [`b-loading--size-${$props.size}`]: $props.size,
      }"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
      v-bind="$attrs"
    >
      <circle
        v-show="$props.active"
        class="b-loading__spinner"
        fill="none"
        stroke-width="6"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </template>

  <template v-if="$props.variant === 'bar'">
    <div
      class="b-loading b-loading--variant-bar"
      :class="{
        [`b-loading--color-${$props.color}`]: $props.color !== 'inherit',
        [`b-loading--size-${$props.size}`]: $props.size,
      }"
      v-bind="$attrs"
    >
      <div
        v-show="$props.active"
        class="b-loading__bar"
      />
    </div>
  </template>
</template>

<script setup>
import { useAttrs } from 'vue';
import { shouldBeOneOf } from 'vue-prop-validation-helper';

const $attrs = useAttrs();

const $props = defineProps({
  active: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: 'spinner',
  },
  size: {
    type: String,
    default: 'colossal',
    validator: shouldBeOneOf([
      'medium',
      'large',
      'extra-large',
      'jumbo',
      'extra-jumbo',
      'giant',
      'extra-giant',
      'colossal',
      'extra-colossal',
      'mega',
      'extra-mega',
      'immense',
    ]),
  },
  color: {
    type: String,
    default: 'inherit',
    validator: shouldBeOneOf([
      'inherit',
      'primary',
      'secondary',
      'inverse',
      'rainbow',
    ]),
  },
  animationDuration: {
    type: String,
    default: '1.5s',
  },
});
</script>

<style lang="scss">
$offset: 188;

:root {
  /* BLoading */
  --b-loading--width: inherit;
  --b-loading--height: inherit;
  --b-loading__spinner--stroke: inherit;
  --b-loading__bar--background-color: inherit;
}

.b-loading {
  &--variant {
    &-bar {
      position: relative;
      width: 100%;
      height: var(--size-scalable-extra-small);
      overflow: hidden;
    }
    &-spinner {
      animation: rotator v-bind('$props.animationDuration') linear infinite;
      width: var(--b-loading--width);
      height: var(--b-loading--height);
      stroke: var(--b-loading__spinner--stroke);
    }
  }

  &--size {
    &-medium {
      --b-loading--width: var(--size-scalable-medium);
      --b-loading--height: var(--size-scalable-medium);
    }
    &-large {
      --b-loading--width: var(--size-scalable-large);
      --b-loading--height: var(--size-scalable-large);
    }
    &-extra-large {
      --b-loading--width: var(--size-scalable-extra-large);
      --b-loading--height: var(--size-scalable-extra-large);
    }
    &-jumbo {
      --b-loading--width: var(--size-scalable-jumbo);
      --b-loading--height: var(--size-scalable-jumbo);
    }
    &-extra-jumbo {
      --b-loading--width: var(--size-scalable-extra-jumbo);
      --b-loading--height: var(--size-scalable-extra-jumbo);
    }
    &-giant {
      --b-loading--width: var(--size-scalable-giant);
      --b-loading--height: var(--size-scalable-giant);
    }
    &-extra-giant {
      --b-loading--width: var(--size-scalable-extra-giant);
      --b-loading--height: var(--size-scalable-extra-giant);
    }
    &-colossal {
      --b-loading--width: var(--size-scalable-colossal);
      --b-loading--height: var(--size-scalable-colossal);
    }
    &-extra-colossal {
      --b-loading--width: var(--size-scalable-extra-colossal);
      --b-loading--height: var(--size-scalable-extra-colossal);
    }
    &-mega {
      --b-loading--width: var(--size-scalable-mega);
      --b-loading--height: var(--size-scalable-mega);
    }
    &-extra-mega {
      --b-loading--width: var(--size-scalable-extra-mega);
      --b-loading--height: var(--size-scalable-extra-mega);
    }
    &-immense {
      --b-loading--width: var(--size-scalable-immense);
      --b-loading--height: var(--size-scalable-immense);
    }
  }

  &--color {
    &-primary {
      --b-loading__spinner--stroke: var(--color-primary);
      --b-loading__bar--background-color: var(--color-primary);
    }
    &-secondary {
      --b-loading__spinner--stroke: var(--color-secondary);
      --b-loading__bar--background-color: var(--color-secondary);
    }
    &-inverse {
      --b-loading__spinner--stroke: var(--color-white);
      --b-loading__bar--background-color: var(--color-white);
    }
    &-rainbow {
      animation: dash v-bind('$props.animationDuration') ease-in-out infinite,
        colors calc(v-bind('$props.animationDuration') * 4) ease-in-out infinite;
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.b-loading__spinner {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash v-bind('$props.animationDuration') ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.b-loading__bar {
  position: absolute;
  height: 100%;
  background-color: var(--b-loading__bar--background-color);
  animation: increase v-bind('$props.animationDuration') infinite ease-in-out;
}

@keyframes increase {
  0% {
    left: -5%;
    width: 5%;
  }
  50% {
    width: 20%;
  }
  100% {
    left: 100%;
    width: 5%;
  }
}
</style>
