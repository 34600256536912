import { CompanyAPI } from '@api';

const checkDriversAvaliability = (driverOneId, driverTwoId, groupId) => {
  const params = new URLSearchParams();
  params.append('group_id', groupId);
  if (driverOneId) {
    params.append('drivers', driverOneId);
  }
  if (driverTwoId) {
    params.append('drivers', driverTwoId);
  }

  return CompanyAPI.checkDriversAvaliability(params)
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));
};

export { checkDriversAvaliability };
