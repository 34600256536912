/* eslint-disable no-underscore-dangle */
import { buserHttpClient } from './buser-http-client';

const sendMonthlyStatement = (companyId, params) => buserHttpClient.post(
  `/ops/groups/pedagios/${companyId}/extrato-mensal`,
  params,
  { headers: { 'Content-Type': 'multipart/form-data' } },
);

const suggestNewTollValue = (tollId, params) => buserHttpClient.post(
  `/ops/groups/pedagios/${tollId}/extrato-viagem`,
  params,
  { headers: { 'Content-Type': 'multipart/form-data' } },
);

const verifyMonthlyTollStatement = (companyId) => buserHttpClient.get(
  `/ops/groups/pedagios/${companyId}/extrato-mes`,
);

const getRefunds = (params) => buserHttpClient.get('/ops/groups/pedagios/reembolsos', { params });

const OpsAPI = {
  verifyMonthlyTollStatement,
  sendMonthlyStatement,
  suggestNewTollValue,
  getRefunds,
};

export { OpsAPI };
