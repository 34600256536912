import { defineStore } from 'pinia';
import { EventLogger } from '@utils/event-metrics';
import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { WalletPageName } from '@assets/constants/route-names';
import { useWalletSacarModalStore } from '@stores';
import { CompanyServices } from '@services/company-services';
import { useInterface } from '@stores/app';

const events = EVENTS_DICTIONARY[WalletPageName];

export const useWalletTermsAndConditionsModalStore = defineStore('WalletTermsAndConditionsModalStore', {
  state: () => ({
    isTermsAndConditionsModalOpen: false,
    isAcceptLoading: false,
    accepted: false,
    contractInfo: {
      url: undefined,
      whatChanged: undefined,
      lastUpdated: undefined,
    },
  }),
  getters: {
    isAcceptDisabled(state) {
      return state.accepted === false;
    },
  },
  actions: {
    open() {
      this.isTermsAndConditionsModalOpen = true;
      EventLogger.logEvent(events.openTermsAndConditionsModal);
    },
    handleAccept() {
      EventLogger.logEvent(events.termsAccepted);
      this.requestAcceptContract()
        .then(() => {
          this.$reset();
          this.openSacarModal();
        });
    },
    handleByPass() {
      EventLogger.logEvent(events.byPassOnTerms);

      this.$reset();
      this.openSacarModal();
    },
    openSacarModal() {
      const $WalletSacarModalStore = useWalletSacarModalStore();
      $WalletSacarModalStore.open();
    },
    async requestAcceptContract() {
      this.isAcceptLoading = true;
      const $interfaceState = useInterface();

      return CompanyServices.acceptContract()
        .then(() => {
          $interfaceState.toast.add({
            label: 'Termos aceitos com sucesso!',
            color: 'success',
          });
        })
        .catch((error) => {
          $interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.isAcceptLoading = false;
        });
    },
    requestGetContract() {
      const $interfaceState = useInterface();

      return CompanyServices.getContract()
        .then(({ data }) => {
          this.contractInfo.url = data.url;
          this.contractInfo.whatChanged = data.what_changed_text;
          this.contractInfo.lastUpdated = data.last_updated;
        })
        .catch((error) => {
          $interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        });
    },
    downloadContract() {
      window.open(this.contractInfo.url);
    },
  },
});
