import {
  SessionsPageName,
  TermsAndConditionsPageName,
  CompanyProfileName,
  HelpPageName,
  NotificationsPageName,
  ReportsPageName,
  ReportsBusPageName,
  ReportsDriversPageName,
  ReportsTravelsPageName,
  IncidentsPageName,
  NotFoundPageName,
  InvoicePageName,
  FinancialLoansPageName,
  RefundPageName,
  DriversListPageName,
  DriverPageName,
  DriverCreatePageName,
  BusCreatePageName,
  BusPageName,
  BusesListPageName,
  TravelsPageName,
  BPESPageName,
  WalletPageName,
  OpenApiPageName,
  ErrorPageName,
  NotAuthorizedPageName,
  MultaListPageName,
  MultaPageName,
} from '@assets/constants/route-names';

const EVENTS_DICTIONARY = {
  [SessionsPageName]: {
    loginRequestUnsuccessful: 'Request made unsuccessfully',
    recoverPasswordRequestSuccess: 'Request made successfully',
    recoverPasswordUnsuccessful: 'Request made unsuccessfully',
    findUserRequestUnsuccessful: 'Request made unsuccessfully',
  },
  [TermsAndConditionsPageName]: {
    clickAcceptTermModal: 'Click in Accept Term Button (Assign Modal)',
    termAccepted: 'Term accepted with success',
    openTermsAcceptancesModal: 'Open Terms Acceptances Modal',
    closeTermsAcceptancesModal: 'Close Terms Acceptances Modal',
    openTermsAssignModal: 'Open Terms Assign Modal',
    closeTermsAssignModal: 'Close Terms Assign Modal',
  },
  [CompanyProfileName]: {
    addressUpdated: 'Company address updated successfully',
    addressNotUpdated: 'Company address updated unsuccessfully',
    contactUpdated: 'Company contact updated successfully',
    contactNotUpdated: 'Company contact updated unsuccessfully',
    cteUpdated: 'Company cte updated successfully',
    cteNotUpdated: 'Company cte updated unsuccessfully',
    profileUpdated: 'Company profile updated successfully',
    profileNotUpdated: 'Company profile updated unsuccessfully',
    regulamentationUpdated: 'Company regulamentation updated successfully',
    regulamentationNotUpdated: 'Company regulamentation updated unsuccessfully',
  },
  [HelpPageName]: {
    openManagersModal: 'Open Managers Modal',
    goToHelpCenter: 'Go to Help Center',
  },
  [MultaListPageName]: {
  },
  [MultaPageName]: {
  },
  [NotificationsPageName]: {
    notificationSelected: 'Notification selected',
    notificationReaded: 'Notification readed',
  },
  [ReportsPageName]: {
  },
  [ReportsBusPageName]: {
  },
  [ReportsDriversPageName]: {
  },
  [ReportsTravelsPageName]: {
  },
  [IncidentsPageName]: {
  },
  [NotFoundPageName]: {
  },
  [InvoicePageName]: {
    downloadMonthInvoice: 'Download month invoice extract',
  },
  [FinancialLoansPageName]: {
    loanAccepted: 'Loan accepted successfully',
    loanRejected: 'Reject loan',
  },
  [RefundPageName]: {
  },
  [DriversListPageName]: {
    driverRemoved: 'Driver removed successfully',
    removeDriverWithTravels: 'Tried to remove driver that has travels. Opening the are you sure modal',
  },
  [DriverPageName]: {
    driverUpdated: 'Driver updated successfully',
  },
  [DriverCreatePageName]: {
    driverCreated: 'Driver created successfully',
  },
  [BusCreatePageName]: {
    busCreated: 'Bus created successfully',
  },
  [BusPageName]: {
    busUpdated: 'Bus updated successfully',
  },
  [BusesListPageName]: {
    removedBus: 'Removed bus',
    removeBusWithTravels: 'Tried to remove bus that has travels. Opening the are you sure modal',
  },
  [TravelsPageName]: {
    openDerMgModal: 'Open DerMG modal',
    requestGenerateDerMg: 'Request to generate DERMG',
    errorGenerateDerMg: 'Error to generate DERMG',
    derMgGeneratedSuccessfully: 'DERMG generated successfully',
    errorLoginDerMg: 'Error to log in on DERMG',
    derMgLoginSuccessfully: 'Log in successfull on DERMG',
    errorGenerateAntt: 'Error to generate ANTT',
    errorToPrintAntt: 'Error to print Antt',
    startAnttFlowAgain: 'Click to start ANTT flow again',
    errorGenerateArtesp: 'Error to generate ARTESP preview',
    printArtespAuthorization: 'Print artesp authorization',
    authorizationPendenciesModalOpen: 'Authorization pendencies modal open',
    openModalUpdatePricesInBatches: 'Open modal update prices in batches',
    openModalTollEdit: 'Open modal toll edit',
    requestToUpdatePricesInBatches: 'Request to update prices in batches',
    pricesUpdatedInBatchesSuccess: 'Prices updated in batches successfully',
    changeStepTravelEditModal: 'Change Step in Travel Edit Modal',
    requestUpdateGroupRouteLegClasses: 'Request update Group Route Leg Classes',
    requestUpdateGroupPrices: 'Request update Group prices',
    openUploadInvoiceFixModal: 'Open Upload Invoice Modal to fix',
    openUploadInvoiceManuallyModal: 'Open Upload Invoice Modal to upload manually',
    errorSendGroupManualAuthorization: 'Error to send group manual authorization',
    searchedDriverByName: 'Searched Driver by Name',
  },
  [BPESPageName]: {
    printInA4Format: 'Print BPE in A4 format',
    printInCoilFormat: 'Print BPE in Coil format',
  },
  [WalletPageName]: {
    withDrawSuccessfull: 'Withdraw succeeded',
    withDrawUnsuccessful: 'Withdraw did not succeed',
    closeWithDrawModal: 'Close withdraw modal',
    clickOnTranferToAnotherAccount: 'Click on transfer to another account',
    openWithDrawModal: 'Open WithDraw modal',
    checkContractAccepted: 'Check if company has accepted contract',
    openTermsAndConditionsModal: 'Open terms and conditions modal',
    termsAccepted: 'Terms and conditions accepted',
    byPassOnTerms: 'ByPass terms and conditions modal',
    showNegativeFinancialPenaltiesBanner: 'Show Negative Financial Penalties Banner',
  },
  [OpenApiPageName]: {
  },
  [ErrorPageName]: {
  },
  [NotAuthorizedPageName]: {
  },
  Application: {
    updateUserCPFSuccess: 'Updated user successfully',
    updateUserCpfUnsuccessfully: 'Updated user unsuccessfully',
    inputPickerError: 'Input error trying call picker',
  },
};

export { EVENTS_DICTIONARY };
