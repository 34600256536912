/* eslint-disable no-underscore-dangle, consistent-return, no-param-reassign */
import {
  ErrorPageName,
  NotAuthorizedPageName,
  SessionsPageName,
  NotFoundPageName,
  HelpPageName,
} from '@assets/constants/route-names';
import { useSession } from '@stores';

const permissionGuard = async (to, _from, next) => {
  const $session = useSession();
  const routesPermissions = to?.meta?.permissions;
  const BYPASS_PERMISSIONS_PAGES = [
    ErrorPageName,
    NotAuthorizedPageName,
    SessionsPageName,
    NotFoundPageName,
    HelpPageName,
  ];

  if (BYPASS_PERMISSIONS_PAGES.includes(to.name) || !$session.isAuthenticated) return next();

  if (!$session.isCompany) return next({ name: NotAuthorizedPageName });

  if (!routesPermissions) return next();

  if (routesPermissions?.onlyBuser && !$session.isUserFromBuser) {
    return next({ name: NotAuthorizedPageName });
  }

  if (routesPermissions?.needNFEnabled && !$session.isCompanyNFEnabled) {
    return next({ name: NotAuthorizedPageName });
  }

  if (routesPermissions?.items) {
    const hasAllPermissions = routesPermissions?.items?.every($session.canDo);
    if (!hasAllPermissions) return next({ name: NotAuthorizedPageName });
  }

  next();
};

export { permissionGuard };
