import { CompanyAPI } from '@api/buser-http-client';

const automaticInvoice = (groupId, invoice) => {
  const translatedInvoice = {
    valor: invoice.value,
    aliquota_icms: invoice.icms,
    reducao_bc: invoice.bcReduction,
  };
  return CompanyAPI.automaticInvoice({
    groupId, invoice: translatedInvoice,
  })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response?.data || error.response));
};
export { automaticInvoice };
