import dayjs from 'dayjs';
import { defineStore } from 'pinia';

import { EVENTS_DICTIONARY } from '@assets/constants/events-dictionary';
import { TravelsPageName } from '@assets/constants/route-names';
import { useInterface, useGeneralSettings } from '@stores';
import { CompanyServices } from '@services/company-services';
import { createCopy } from '@utils/object/create-copy';
import { EventLogger } from '@utils/event-metrics';
import { needSecondDriverANTT } from '@utils/groups/need-second-driver-antt';
import { useTravelsPage } from './travels-page-store';

const events = EVENTS_DICTIONARY[TravelsPageName];

export const useTravelDriversModalNew = defineStore('travelDriversModal', {
  state: () => ({
    interfaceState: useInterface(),
    avaliableDrivers: [],
    duplicatedTravelDrivers: [],
    tiredForSoloTravel: [],
    tiredForTwoDriversTravel: [],
    confirmingNoDriver: false,
    driverOne: null,
    driverTwo: null,
    group: null,
    isModalOpen: false,
    isLoading: false,
    isLoadingDriversAvaliability: false,
    isSingleDriverTired: false,
    isFirstDriverTired: false,
    isSecondDriverTired: false,
    isFirstDriverTraveling: false,
    isSecondDriverTraveling: false,
    firstDriverTravelingGroups: null,
    secondDriverTravelingGroups: null,
    searchDriversText: null,
    searchedDriverName: false,
    isInvalidForm: null,
  }),
  getters: {
    cantChangeDriver(state) {
      if (!state.group?.datetime_ida) return undefined;
      const tenMinutesAgo = dayjs().subtract(10, 'minutes');
      const groupStartTime = dayjs(state.group?.datetime_ida);
      return groupStartTime.isBefore(tenMinutesAgo);
    },
    allDrivers(state) {
      // Garante que a ordenção vai ficar no formato que quero
      const allDrivers = [
        ...state.avaliableDrivers,
        ...state.duplicatedTravelDrivers,
        ...state.tiredForSoloTravel,
        ...state.tiredForTwoDriversTravel,
      ];

      if (!state.searchDriversText) {
        return allDrivers;
      }

      if (allDrivers.length === 0) {
        return allDrivers;
      }

      const lowerCaseText = state.searchDriversText.toLowerCase();
      return allDrivers.filter((driver) => driver.name.toLowerCase().includes(lowerCaseText));
    },
    hasTiredDriver(state) {
      return (
        state.isSingleDriverTired
        || state.isFirstDriverTired
        || state.isSecondDriverTired
      );
    },
    hasDriverTraveling(state) {
      return (
        state.isFirstDriverTraveling
        || state.isSecondDriverTraveling
      );
    },
    secondDriverNotSelectedAndRequired(state) {
      return (state.twoDriversRequired && state.driverOne && !state.driverTwo)
    },
    isAuthorizedToBypassSetTiredDriver(state) {
      const $general = useGeneralSettings();
      if (!state?.group?.rotina_id) return false;
      return $general.settings?.ids_rotinas_com_bypass_do_motorista_descanso?.includes(
        state?.group?.rotina_id
      );
    },
    isSetDriverButtonDisabled(state) {
      if (this.isAuthorizedToBypassSetTiredDriver) {
        return (
          this.hasDriverTraveling ||
          this.secondDriverNotSelectedAndRequired ||
          state.isInvalidForm ||
          this.cantChangeDriver
        );
      }
      return (
        this.hasTiredDriver ||
        this.hasDriverTraveling ||
        this.secondDriverNotSelectedAndRequired ||
        state.isInvalidForm ||
        this.cantChangeDriver
      );
    },
    oneDriverException(state) {
      return state.group?.one_driver_exception;
    },
    twoDriversRequired(state) {
      return needSecondDriverANTT(state.group) && !this.oneDriverException;
    },
    hasTwoDrivers(state) {
      return Boolean(state.driverOne && state.driverTwo);
    },
  },
  actions: {
    close() {
      // reset all state
      this.$reset();
    },
    async open(group) {
      this.group = createCopy(group);
      this.driverOne = group.driver_one;
      this.driverTwo = group.driver_two;
      this.isModalOpen = true;
      await this.checkDriversAvaliability();
    },
    async checkDriversAvaliability() {
      this.isLoadingDriversAvaliability = true;
      await CompanyServices.checkDriversAvaliabilityNew(this.group.id)
        .then((result) => {
          this.avaliableDrivers = result.avaliable_drivers;
          this.tiredForSoloTravel = result.tired_only_for_solo_travel;
          this.tiredForTwoDriversTravel = result.tired_for_any_travel;
          this.duplicatedTravelDrivers = result.duplicated_travel_drivers;
        })
        .catch(({ error }) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.isLoadingDriversAvaliability = false;
        });
    },
    selectDriver(driver) {
      if (this.cantChangeDriver) {
        return;
      }
      if (this.driverOne && this.driverTwo) {
        return;
      }
      if (this.driverOne?.id === driver.id) {
        return;
      }
      if (this.driverOne && !this.driverTwo) {
        this.driverTwo = driver;
        this.getSecondDriverTravels();
        this.checkDriversStatuses();
      }
      if (!this.driverOne) {
        this.driverOne = driver;
        this.getFirstDriverTravels();
        this.checkDriversStatuses();
      }
    },
    getFirstDriverTravels() {
      this.firstDriverTravelingGroups = null;
      if (this.driverOne.status === 'duplicated') {
        this.isFirstDriverTraveling = true;
        this.isLoading = true;

        CompanyServices.getDriverGroups(this.driverOne.id, this.group.id)
          .then(({ groups }) => {
            this.firstDriverTravelingGroups = groups;
          })
          .catch(({ error }) => {
            this.interfaceState.toast.add({
              label: error,
              color: 'danger',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getSecondDriverTravels() {
      this.secondDriverTravelingGroups = null;
      if (this.driverTwo.status === 'duplicated') {
        this.isSecondDriverTraveling = true;
        this.isLoading = true;

        CompanyServices.getDriverGroups(this.driverTwo.id, this.group.id)
          .then(({ groups }) => {
            this.secondDriverTravelingGroups = groups;
          })
          .catch(({ error }) => {
            this.interfaceState.toast.add({
              label: error,
              color: 'danger',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    removeDriverOne() {
      this.driverOne = this.driverTwo;
      this.isFirstDriverTraveling = this.isSecondDriverTraveling;
      this.firstDriverTravelingGroups = this.secondDriverTravelingGroups;
      this.driverTwo = null;
      this.isSecondDriverTraveling = null;
      this.secondDriverTravelingGroups = null;
      this.checkDriversStatuses();
    },
    removeDriverTwo() {
      this.driverTwo = null;
      this.isSecondDriverTraveling = null;
      this.secondDriverTravelingGroups = null;
      this.checkDriversStatuses();
    },
    checkDriversStatuses() {
      this.isFirstDriverTired = false;
      this.isSecondDriverTired = false;
      this.isSingleDriverTired = false;

      if (!this.driverOne && !this.driverTwo) {
        return;
      }
      if (this.driverOne && !this.driverTwo) {
        // O motorista só é considerado casado para viagem sozino
        // se a viagem tiver dois motoristas, pode viajar
        this.isSingleDriverTired = this.driverOne.status === 'tired_only_for_solo_travel'
          && !this.twoDriversRequired;
      }
      if (this.driverOne || this.driverTwo) {
        this.isFirstDriverTired = this.driverOne?.status === 'tired_for_any_travel';
        this.isSecondDriverTired = this.driverTwo?.status === 'tired_for_any_travel';
      }
    },
    returnToSelection() {
      this.confirmingNoDriver = false;
    },
    async requestSetGroupDrivers() {
      if (
        !this.driverOne?.id
        && !this.driverTwo?.id
        && !this.confirmingNoDriver
      ) {
        this.confirmingNoDriver = true;
        return;
      }

      this.isLoading = true;
      CompanyServices.setGroupDrivers(
        this.group.id,
        this.driverOne?.id,
        this.driverTwo?.id,
      )
        .then(() => {
          this.isModalOpen = false;

          EventLogger.logEvent(events.setGroupDriver, {
            groupId: this.group.id,
            driverOne: this.driverOne?.id,
            driverTwo: this.driverTwo?.id,
          });

          this.interfaceState.toast.add({
            label: 'Motorista(s) escalado(s) com sucesso!',
            color: 'success',
          });

          const TravelsPageStore = useTravelsPage();
          TravelsPageStore.requestGroups();
        })
        .catch(({ error }) => {
          this.interfaceState.toast.add({
            label: error,
            color: 'danger',
          });
        })
        .finally(() => {
          this.$reset();
        });
    },
    registerSearchedDrivers() {
      if (!this.searchedDriverName) {
        this.searchedDriverName = true;
        EventLogger.logEvent(events.searchedDriverByName, {
          groupId: this.group.id,
          driverOne: this.driverOne?.id,
          driverTwo: this.driverTwo?.id,
        });
      }
    },
  },
});
