export const SessionsPageName = 'SessionsPage';

export const HomePageName = 'HomePage';

export const TermsAndConditionsPageName = 'TermsAndConditionsPage';

export const CompanyProfileName = 'CompanyProfile';

export const HelpPageName = 'HelpPage';

export const NotificationsPageName = 'NotificationsPage';

export const ReportsPageName = 'ReportsPage';

export const HomeRedirect = '/viagens';

export const ReportsBusPageName = 'ReportsBusPage';

export const ReportsDriversPageName = 'ReportsDriversPage';

export const ReportsTravelsPageName = 'ReportsTravelsPage';

export const IncidentsPageName = 'IncidentsPage';

export const NotFoundPageName = 'NotFoundPage';

export const InvoicePageName = 'InvoicePage';

export const FinancialLoansPageName = 'FinancialLoansPage';

export const DriversListPageName = 'DriversListPage';

export const DriverPageName = 'DriverPage';

export const DriverCreatePageName = 'DriverCreatePage';

export const BusCreatePageName = 'BusCreatePage';

export const BusPageName = 'BusPage';

export const BusesListPageName = 'BusesListPage';

export const TravelsPageName = 'TravelsPage';

export const BPESPageName = 'BPESPage';

export const WalletPageName = 'WalletPage';

export const RefundPageName = 'RefundPage';

export const OpenApiPageName = 'OpenApiPage';

export const ErrorPageName = 'ErrorPage';

export const NotAuthorizedPageName = 'NotAuthorizedPage';

export const MultaListPageName = 'MultaListPage';

export const MultaPageName = 'MultaPageName';
