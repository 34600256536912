<template>
  <div
    class="b-progressive-bar"
    :class="{
      [`b-progressive-bar--color-${$props.color}`]: $props.color,
    }"
  >
    <div class="b-progressive-bar__wrapper">
      <div class="b-progressive-bar__bar" />
    </div>

    <b-text
      v-if="hasCounterLabel"
      class="b-progressive-bar__counter"
      weight="medium"
    >
      {{ $state.counterFixed }}
    </b-text>
  </div>
</template>

<script setup>
import { computed, reactive, toRef } from 'vue';
import { shouldBeOneOf } from 'vue-prop-validation-helper';

const $props = defineProps({
  color: {
    type: String,
    default: 'info',
    validator: shouldBeOneOf(['success', 'warn', 'danger', 'info']),
  },
  counter: {
    type: [String, Number],
    required: true,
  },
  hasCounterLabel: {
    type: Boolean,
    default: true,
  },
  max: {
    type: Number,
    default: 100,
  },
  min: {
    type: Number,
    default: 0,
  },
  numberOfDecimals: {
    type: Number,
    default: 1,
  },
});

const $state = reactive({
  barTotalSize: computed(() => $props.max - $props.min),
  barCurrentSize: computed(() => ($props.counter / $state.barTotalSize) * 100),
  barPixelSize: computed(() => `${$state.barCurrentSize}%`),
  counterFixed: computed(() => $props.counter?.toFixed($props.numberOfDecimals)),
});

const barSize = toRef($state, 'barPixelSize');
</script>

<style lang="scss">

:root {
  --b-progressive-base__wrapper--background-color: transparent;
  --b-progressive-base__bar--background-color: transparent;
  --b-progressive-base--color: inherit;
}

.b-progressive-bar {
  display: flex;
  align-items: center;
  color: var(--b-progressive-base--color);
  width: 100%;

  &--color {
    &-success {
      --b-progressive-base__wrapper--background-color: var(--color-green-scale-100);
      --b-progressive-base__bar--background-color: var(--color-green-scale-400);
      --b-progressive-base--color: var(--color-green-scale-400);
    }
    &-warn {
      --b-progressive-base__wrapper--background-color: var(--color-amber-scale-100);
      --b-progressive-base__bar--background-color: var(--color-amber-scale-800);
      --b-progressive-base--color: var(--color-amber-scale-800);
    }
    &-info {
      --b-progressive-base__wrapper--background-color: var(--color-blue-scale-100);
      --b-progressive-base__bar--background-color: var(--color-blue-scale-500);
      --b-progressive-base--color: var(--color-blue-scale-500);
    }
    &-danger {
      --b-progressive-base__wrapper--background-color: var(--color-red-scale-100);
      --b-progressive-base__bar--background-color: var(--color-red-scale-600);
      --b-progressive-base--color: var(--color-red-scale-600);
    }
  }
}

.b-progressive-bar__wrapper {
  border-radius: var(--size-scalable-small);
  background-color: var(--b-progressive-base__wrapper--background-color);
  width: 100%;
  height: var(--size-scalable-small);
  position: relative;
  overflow: hidden;
}

.b-progressive-bar__bar {
  background-color: var(--b-progressive-base__bar--background-color);
  width: v-bind(barSize);
  height: var(--size-scalable-small);
}

.b-progressive-bar__counter {
  margin-left: var(--size-scalable-small);
}
</style>
