import { isUndefined } from '@utils/validations/is-undefined';

function evaluateMiddlewares(middlewares, to, from, failure) {
  const middlewaresLeft = middlewares.slice(0); // clone the array so we do not accidentally modify it
  const currentMiddleware = middlewaresLeft.shift();

  if (isUndefined(currentMiddleware)) return;

  const next = () => evaluateMiddlewares(middlewaresLeft, to, from, failure);

  currentMiddleware(to, from, failure, next);
}

export const MultiMiddlewares = (middlewares) => {
  if (!Array.isArray(middlewares)) {
    throw new Error('You must specify an array of middlewares');
  }

  return (to, from, failure) => evaluateMiddlewares(middlewares, to, from, failure);
};
