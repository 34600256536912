<template>
  <Bar
    :chart-data="chartData"
    :chart-options="chartOptions"
  />
</template>

<script>
import { getNumberInCurrency } from '@utils/money/get-number-in-currency';
import { getDate } from '@utils/date/get-date';
import { Bar } from 'vue-chartjs';
import { useScreen } from '@stores';

import {
  Chart as ChartJS,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const PINK_COLOR = 'rgba(234, 39, 105, 1)';

export default {
  components: {
    Bar,
  },
  props: {
    saldoFuturo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: this.saldoFuturo.map((saldo) => getDate(saldo.data)),
        datasets: [
          {
            label: 'Valor total de passagens vendidas',
            borderWidth: 1,
            barThickness: useScreen().isSizeUp('desktop') ? 70 : 40,
            backgroundColor: PINK_COLOR,
            data: this.saldoFuturo.map((saldo) => saldo.repasse),
            fill: true,
          },
        ],
      },
      chartOptions: {
        scales: {
          y: {
            grace: '20%',
            alignToPixels: true,
            ticks: {
              beginAtZero: true,
              font: {
                weight: '500',
                size: useScreen().isSizeUp('desktop') ? '18' : '13',
              },
              callback(value) {
                if (value % 1 === 0) {
                  return `${getNumberInCurrency(value)}`;
                }
                return null;
              },
            },
            grid: {
              borderDash: [5],
              tickBorderDash: [5],
              display: true,
            },
          },
          x: {
            ticks: {
              font: {
                weight: '500',
                size: useScreen().isSizeUp('desktop') ? '18' : '13',
              },
            },
            grid: {
              display: false,
            },
            stacked: false,
          },
        },
        plugins: {
          legend: {
            labels: {
              font: {
                family: 'inter',
                size: '16',
              },
            },
          },
          tooltip: {
            boxPadding: 5,
            titleFont: {
              weight: 600,
              family: 'inter',
              size: 16,
            },
            bodyFont: {
              family: 'inter',
              size: 16,
            },
          },
        },
        tooltips: { enabled: false },
        hover: { mode: null },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
