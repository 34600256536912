export function busSerializePayload(originalOnibusPayload, bus) {
  let params = {
    infos: {
      foto_externa: {},
      seguro: {},
      cvs: {},
      dut: {},
      classes: {},
      laudo_inspecao: {},
      dedetizacao: {},
      aet: {},
    },
    jsondata: {
      features: undefined,
      licences: undefined,
    },
  };

  if (originalOnibusPayload) params = JSON.parse(JSON.stringify(originalOnibusPayload));

  params.placa = bus.licensePlate.toUpperCase();
  params.name = bus.name;
  params.uf = bus.state;
  params.marca = bus.chassi.value;
  params.tipo = bus.type.value;
  params.modelo_venda = bus.businessType?.value || 'buser';
  params.tem_banheiro = bus.hasBathRoom;
  params.motor = bus.engine;
  params.ano_modelo = bus.modelYear;
  params.ano_fabricacao = bus.yearOfManufacture;
  params.infos.foto_externa.foto_url = bus.image;
  params.modelo = bus.bodyWork.value;
  params.numero_eixos = bus.pivotNumber;
  params.available = bus.available;

  params.infos.seguro = {
    foto: bus.documents.insurance.image.name,
    data_vencimento: bus.documents.insurance.expirationDate,
  };

  params.infos.dut = {
    foto: bus?.documents?.crlv?.image?.name,
    data_vencimento: bus.documents.crlv.expirationDate,
    numeracao: bus.renavam,
  };

  if (bus?.documents?.cvs?.image?.name) {
    params.infos.cvs = {
      foto: bus.documents.cvs.image.name,
      data_vencimento: bus.documents.cvs.expirationDate,
    };
  }
  params.infos.laudo_inspecao = {
    foto: bus?.documents?.laudoInspecao?.image?.name,
    data_vencimento: bus.documents.laudoInspecao.expirationDate,
    numeracao: bus.documents.laudoInspecao.numeracao,
  };
  params.infos.dedetizacao = {
    foto: bus?.documents?.dedetizacao?.image?.name,
    data_vencimento: bus.documents.dedetizacao.expirationDate,
    numeracao: bus.documents.dedetizacao.numeracao,
  };
  params.infos.aet = {
    foto: bus?.documents?.aet?.image?.name,
    data_vencimento: bus.documents.aet.expirationDate,
    numeracao: bus.documents.aet.numeracao,
  };

  const classes = [];
  const allSeats = { ...bus.seatTypesIndividual, ...bus.seatTypesCommon };

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(allSeats)) {
    // eslint-disable-next-line no-continue
    if (!value.selected) continue;

    const seatDict = {
      tipo: key,
      capacidade: value.capacidade,
      capacidade_vendida: value.capacidade,
    };

    classes.push(seatDict);
  }

  params.classes = classes;

  params.jsondata.features = bus.features?.map((feature) => feature.value);
  params.jsondata.licences = bus.licenses?.map((licence) => licence.value);

  const documentsFileData = new FormData();

  if (bus.newImage instanceof File) {
    documentsFileData.append('foto_externa', bus.newImage, bus.newImage.name);
  }
  if (bus.documents.crlv.image instanceof File) {
    documentsFileData.append('foto_dut', bus.documents.crlv.image, bus.documents.crlv.image.name);
  }
  if (bus.documents.insurance.image instanceof File) {
    documentsFileData.append('foto_seguro', bus.documents.insurance.image, bus.documents.insurance.image.name);
  }
  if (bus.documents.cvs.image instanceof File) {
    documentsFileData.append('foto_cvs', bus.documents.cvs.image, bus.documents.cvs.image.name);
  }
  if (bus.documents.laudoInspecao.image instanceof File) {
    documentsFileData.append('foto_laudo_inspecao', bus.documents.laudoInspecao.image, bus.documents.laudoInspecao.image.name);
  }
  if (bus.documents.dedetizacao.image instanceof File) {
    documentsFileData.append('foto_dedetizacao', bus.documents.dedetizacao.image, bus.documents.dedetizacao.image.name);
  }
  if (bus.documents.aet.image instanceof File) {
    documentsFileData.append('foto_aet', bus.documents.aet.image, bus.documents.aet.image.name);
  }

  documentsFileData.append('params', JSON.stringify(params));

  return documentsFileData;
}
