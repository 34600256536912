const getDate = (date, options = {}) => {
  let parsedDate = date;
  if (!parsedDate) return undefined;
  if (options.split) {
    parsedDate = parsedDate?.split('-');
    // https://stackoverflow.com/a/61623775 - Workaround de um "problema" nas datas
  }

  return new Date(parsedDate)
    .toLocaleDateString('pt-br');
};

export { getDate };
