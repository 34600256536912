const busLicenses = [
  { value: 'ANTT', text: 'ANTT' },
  { value: 'DERACRE_AC', text: 'DERACRE AC' },
  { value: 'DER_AL', text: 'DER AL' },
  { value: 'SETRAP_AP', text: 'SETRAP AP' },
  { value: 'ARSAM_AM', text: 'ARSAM AM' },
  { value: 'AGERBA_BA', text: 'AGERBA BA' },
  { value: 'DER_CE', text: 'DER CE' },
  { value: 'DER_DF', text: 'DER DF' },
  { value: 'DER_ES', text: 'DER ES' },
  { value: 'AGETOP_GO', text: 'AGETOP GO' },
  { value: 'MOB_MA', text: 'MOB MA' },
  { value: 'AGER_MT', text: 'AGER MT' },
  { value: 'AGEPAN_MS', text: 'AGEPAN MS' },
  { value: 'DER_MG', text: 'DER MG' },
  { value: 'SETRAN_PA', text: 'SETRAN PA' },
  { value: 'DER_PB', text: 'DER PB' },
  { value: 'DER_PR', text: 'DER PR' },
  { value: 'DER_PE', text: 'DER PE' },
  { value: 'DER_PI', text: 'DER PI' },
  { value: 'DERRI_RJ', text: 'DERRI RJ' },
  { value: 'DER_RN', text: 'DER RN' },
  { value: 'DAER_RS', text: 'DEAR RS' },
  { value: 'DEOSP_RO', text: 'DEOSP RO' },
  { value: 'CRE_RR', text: 'CRE RR' },
  { value: 'DETER_SC', text: 'DETER SC' },
  { value: 'ARTESP_SP', text: 'ARTESP SP' },
  { value: 'AGRESE_SE', text: 'AGRESE SE' },
  { value: 'ATR_TO', text: 'ATR TO' },
];

export { busLicenses };
