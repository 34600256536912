/* eslint-disable camelcase */
import { CompanyAPI } from '@api';

const getSuggestedICMS = (originState, destinyState) => CompanyAPI.getSuggestedICMS(
  { originState, destinyState },
)
  .then(({ data }) => data)
  .catch((error) => Promise.reject(error.response?.data || error.response));

export { getSuggestedICMS };
